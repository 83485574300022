import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { CommutationType } from 'types'
import { CommutationTypeForm } from './CommutationTypeForm'

type ModalAddCommutationTypeProps = ButtonProps

export function ButtonAddCommutationType(props: ModalAddCommutationTypeProps) {
  const { t } = useTranslation()
  const [commutationType, setCommutationType] = useState<Partial<CommutationType> | null>(null)
  const [modalCommutationTypeIsOpen, setModalCommutationTypeIsOpen] = useState<boolean>(false)
  const [createCommutationType] = api.commutationTypes.create()

  async function addCommutationType() {
    if (commutationType) await createCommutationType({ data: commutationType })
    setModalCommutationTypeIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalCommutationTypeIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <FontAwesomeIcon icon="car" className="marginRight10" />{' '}
        {t('commutation.settings.commutations.buttonAdd')}
      </Button>
      <Modal
        open={modalCommutationTypeIsOpen}
        onClose={() => setModalCommutationTypeIsOpen(false)}
        onExited={() => setCommutationType(null)}
        onValidate={addCommutationType}
        onCancel={() => Promise.resolve(setModalCommutationTypeIsOpen(false))}
        title={t('commutation.settings.commutations.buttonAdd')}
      >
        <CommutationTypeForm
          commutationType={commutationType}
          setCommutationType={setCommutationType}
        />
      </Modal>
    </>
  )
}
