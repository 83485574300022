import { CustomEditor, TextEditor } from 'components/TextEditor/TextEditor'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'
import api from 'services/api'
import { ID } from 'types'
import { openModal } from 'components/Modal/openModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRole } from 'hooks/useRole'
import { useSelector } from 'react-redux'

export const WIKI_KEY = 'wiki'

export function Wiki() {
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'owner' })
  const { isAdmin } = useSelector((state: any) => ({
    isAdmin: state.authentication.user?.isAdmin,
  }))
  const isSuperUser = authorized && isAdmin

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const editorRef = useRef<CustomEditor | null>(null)

  const [createDocument] = api.documents.create()
  const [deleteDocument] = api.documents.delete()
  const [updateDocument] = api.documents.update()
  const {
    data: documents = [],
    isLoading: documentsIsLoading,
    isUninitialized,
  } = api.documents.readAll({
    filters: { key: WIKI_KEY },
  })

  const wikiData = useMemo(
    () => documents.find((document) => document.key === WIKI_KEY),
    [documents],
  )

  async function onSave() {
    let wikiId: ID | void = wikiData?.id
    const content = editorRef.current?.getData()

    if (!content && wikiId) {
      deleteDocument(wikiId)
      return
    }

    if (!content) return

    if (!wikiId && documents.length === 0 && !documentsIsLoading && !isUninitialized) {
      const document = await createDocument({
        data: { key: WIKI_KEY, title: 'Wiki', content },
      }).unwrap()
      wikiId = document.id
    }

    if (wikiId) {
      updateDocument({ data: { id: wikiId, content } })
    }
  }

  function switchEdit() {
    const content = editorRef.current?.getData()
    if (isEditing && content !== wikiData?.content) {
      openModal({
        content: t('wiki.saveChanges'),
        onValidate: () =>
          onSave().then(() => {
            setIsEditing((isEditing) => !isEditing)
          }),
      })
    } else {
      setIsEditing((isEditing) => !isEditing)
    }
  }

  return (
    <div>
      {authorized ? (
        <div className="fullWidth flex row end" style={{ height: 50, padding: '10px 30px' }}>
          {isEditing ? (
            <Button className="marginLeft10" onClick={onSave} appearance="subtle">
              {t('wiki.save')}
            </Button>
          ) : null}
          {isSuperUser ? (
            <Button
              className="marginLeft10"
              onClick={() => isSuperUser && switchEdit()}
              appearance="subtle"
            >
              {isEditing ? null : <FontAwesomeIcon icon="pen" className="marginRight5" />}
              {isEditing ? t('wiki.leaveEdit') : t('wiki.edit')}
            </Button>
          ) : null}
        </div>
      ) : null}
      <div style={{ padding: '0 30px' }}>
        <TextEditor
          style={isEditing ? { maxHeight: 'calc(100vh - 166px)' } : { padding: '40px 30px' }}
          isEditing={isEditing}
          data={wikiData?.content || ''}
          placeholder={t('wiki.placeholder')}
          getEditor={(editor) => {
            editorRef.current = editor
          }}
        />
      </div>
    </div>
  )
}
