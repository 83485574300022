import ReactDOM from 'react-dom/client'
import 'rsuite/styles/index.less'
import 'styles/rsuite-custom.scss'
import 'styles/patterns.scss'
import './index.css'
import './services/i18n/initialize'
import { App } from './App'
import 'styles/icons'
import 'styles/TextEditor.module.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
