import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup, SelectPicker } from 'rsuite'
import api from 'services/api'
import { scheduleTypeOptions } from 'services/constants/scheduleTypes'
import { DepartmentGroupData } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type DepartmentGroupDataFormProps = {
  departmentGroupData: Partial<DepartmentGroupData> | null
  departmentGroupType?: number
  setDepartmentGroupData: (faciltity: Partial<DepartmentGroupData>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function DepartmentGroupDataForm(props: DepartmentGroupDataFormProps) {
  const { t } = useTranslation()
  const {
    departmentGroupData,
    departmentGroupType,
    setDepartmentGroupData,
    getFormErrors,
    setHasBlurred,
  } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: computerRig = [] } = api.computerRig.readAll()
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  const onChange = (formData: Partial<DepartmentGroupData>) => {
    const resetForm = {
      ...formData,
      startDate: undefined,
      endDate: undefined,
      computerRig: null,
      personDays: 0,
      scheduleType: 0,
    }
    setDepartmentGroupData(formData.consumptionOverride ? resetForm : { ...formData })
  }

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={onChange}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={departmentGroupData || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t(`departmentGroupDatas.details.${departmentGroupType ? 'renderHours' : 'personDays'}`)}
          <FormTooltip tooltip>{t('departmentGroupDatas.details.personDays_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="personDays"
            type="number"
            accepter={InputCustom}
            tooltip-id="departmentGroupDatas.details.personDays_FieldInformation"
            disabled={!!departmentGroupData?.consumptionOverride}
          />
          <InputGroup.Addon>
            {t(`misc.units.${departmentGroupType ? 'renderHours' : 'personDays'}`)}
          </InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroupDatas.details.computerRig')}
          <FormTooltip tooltip>{t('departmentGroupDatas.details.computerRig_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="computerRig"
          accepter={SelectPicker}
          tooltip-id="departmentGroupDatas.details.computerRig_FieldInformation"
          data={computerRig.map((c) => ({ value: c.id, label: c.name }))}
          style={{ width: '100%' }}
          disabled={!!departmentGroupData?.consumptionOverride}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroupDatas.details.startDate')}
          <FormTooltip tooltip>{t('departmentGroupDatas.details.startDate_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="startDate"
            type="date"
            accepter={InputCustom}
            tooltip-id="departmentGroupDatas.details.startDate_FieldInformation"
            disabled={!!departmentGroupData?.consumptionOverride}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroupDatas.details.endDate')}
          <FormTooltip tooltip>{t('departmentGroupDatas.details.endDate_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="endDate"
            type="date"
            accepter={InputCustom}
            tooltip-id="departmentGroupDatas.details.endDate_FieldInformation"
            disabled={!!departmentGroupData?.consumptionOverride}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroupDatas.details.scheduleType')}
          <FormTooltip tooltip>
            {t('departmentGroupDatas.details.scheduleType_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="scheduleType"
          accepter={SelectPicker}
          tooltip-id="departmentGroupDatas.details.scheduleType_FieldInformation"
          data={scheduleTypeOptions}
          style={{ width: '100%' }}
          searchable={false}
          disabled={!!departmentGroupData?.consumptionOverride}
        />
      </Form.Group>
      <hr style={{ borderTop: '1px solid grey' }} />
      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroupDatas.details.consumptionOverride')}
          <FormTooltip tooltip>
            {t('departmentGroupDatas.details.consumptionOverride_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="consumptionOverride"
            type="number"
            min={0}
            accepter={InputCustom}
            tooltip-id="departmentGroupDatas.details.consumptionOverride_FieldInformation"
          />
        </InputGroup>
      </Form.Group>
    </Form>
  )
}
