import { Link } from 'react-router-dom'
import { Button, Form } from 'rsuite'
import { useEffect, useMemo, useState } from 'react'
import { ButtonMenu, Modal } from 'components'
import api from 'services/api'
import { Diet as DietProp, DietType, DietValue, FacilityData, MetaDatas } from 'types'
import { capitalize, forEach, reduce } from 'lodash'
import { Description } from 'components/Description/Descrption'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { DietValueForm } from './DietValueForm'
import { ModalAddDietList } from './ModalAddDietList'

type DietProps = {
  facilityDataId: FacilityData['id']
  refecthComputedValue: () => void
  dietTypes: DietType[] | undefined
  noDietTypes: boolean
  dietsCarbonValue: JSX.Element | null
  style?: Record<string, any>
}

export function Diet(props: DietProps) {
  const { facilityDataId, refecthComputedValue, dietTypes, noDietTypes, dietsCarbonValue, style } =
    props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { t } = useTranslation()

  const [createDiet] = api.diets.create()
  const { data: diets = [], isLoading: isLoadingDiets } = api.diets.readAll({
    filters: { facilityDatas: facilityDataId },
  })

  const diet: DietProp | null = useMemo(
    () => diets.find((d) => d.facilityDatas === facilityDataId) || null,
    [diets],
  )

  const [createDietValues] = api.diets.addDietValues()
  const [deleteAllDiets] = api.diets.deleteAllDiets()

  const [dietValuesForm, setDietValuesForm] = useState<{ [key: string]: number } | null>(null)

  const [modalDietValueIsOpen, setModalDietValueIsOpen] = useState<boolean>(false)
  const [modalDietListIsOpen, setModalDietListIsOpen] = useState<boolean>(false)
  const [isAverage, setIsAverage] = useState<boolean>(false)
  const [modalDietValueTitle, setModalDietValueTitle] = useState<string>('')

  function formToDietValue(
    values: { [key: string]: number } | null,
    dietId: string,
  ): Omit<DietValue, keyof MetaDatas>[] {
    const dietValues: Omit<DietValue, keyof MetaDatas>[] = []

    forEach(values, (value, name) => {
      const dietType = dietTypes?.find((c: any) => c.name === name)
      if (!dietType || !diet) return
      dietValues.push({
        diet: dietId,
        number: value,
        dietType: dietType.id,
      })
    })

    return dietValues
  }

  const dietTypesById: Record<string, DietType | undefined> = useMemo(
    () => dietTypes?.reduce((acc: any, ct: DietType) => ({ ...acc, [ct.id]: ct }), {}) || {},
    [dietTypes],
  )

  const averageValues = useMemo<Record<DietType['name'], DietValue['number']>>(() => {
    if (!diet || Object.keys(dietTypesById).length === 0) return {}

    const sums =
      diet.values.reduce<Record<DietType['name'], DietValue['number']>>((acc, dietValue) => {
        const dietType = dietTypesById[dietValue.dietType]
        if (!dietType) return acc
        if (!acc[dietType.name]) acc[dietType.name] = 0
        acc[dietType.name] += dietValue.number
        return acc
      }, {}) || {}

    return reduce(
      sums,
      (acc, sum, name) => {
        const dietType = dietTypes?.find((ct: DietType) => ct.name === name)
        const total = diet.values.filter((value) => value.dietType === dietType?.id)
        return { ...acc, [name]: sum / total.length }
      },
      {},
    )
  }, [diet, dietTypesById])

  async function addDietValue() {
    if (!diet) return

    if (isAverage) {
      await deleteAllDiets({ data: { facilityDataId } }).unwrap()
      setModalDietValueIsOpen(false)

      const response: any = await createDiet({ data: { facilityDatas: facilityDataId } })
      await createDietValues({
        data: {
          dietId: response?.data.id,
          dietValues: formToDietValue(dietValuesForm, response?.data.id),
          facilityDataId,
        },
      }).unwrap()
    } else {
      await createDietValues({
        data: {
          dietId: diet.id,
          dietValues: formToDietValue(dietValuesForm, diet.id),
          facilityDataId,
        },
      }).unwrap()
      setModalDietValueIsOpen(false)
    }

    refecthComputedValue()
    setIsAverage(false)
  }

  useEffect(() => {
    if (!diet && !isLoadingDiets && !isAverage) {
      createDiet({ data: { facilityDatas: facilityDataId } })
    }
  }, [diet, isLoadingDiets])

  if (!diet) return null

  return (
    <>
      <div
        className="fullWidth flex end"
        style={{
          ...style,
          marginTop: noDietTypes || !authorized ? -20 : dietsCarbonValue ? '-69px' : '-57px',
        }}
      >
        {noDietTypes || !authorized ? null : (
          <>
            <Button
              appearance="primary"
              color="blue"
              onClick={() => {
                setModalDietValueTitle(t('diet.addDietValue'))
                setModalDietValueIsOpen(true)
              }}
            >
              <span>
                <FontAwesomeIcon icon="plus" className="marginRight10" />
                {t('diet.menu.addDietValue')}
              </span>
            </Button>
            <ButtonMenu
              appearance="primary"
              color="blue"
              onClick={() => {
                setModalDietValueTitle(t('diet.setDietAverage'))
                setIsAverage(true)
                setModalDietValueIsOpen(true)
              }}
              label={
                <span>
                  <FontAwesomeIcon icon="chart-column" className="marginRight10" />
                  {t('diet.menu.setDietAverage')}
                </span>
              }
              actions={[
                {
                  label: t('diet.menu.addDietList'),
                  icon: 'list',
                  disabled: !dietTypes?.length,
                  onClick: () => setModalDietListIsOpen(true),
                  key: 'addList',
                },
              ]}
            />
          </>
        )}
      </div>
      <Description style={{ marginTop: !noDietTypes || authorized ? 0 : 25, fontSize: 'inherit', padding: '30px 0' }}>
      <Trans
        i18nKey="diet.description"
        components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            link1: <a
              aria-label="link"
              href="https://docs.google.com/forms/d/1ZmZqnhzE02KqeX7npkXysy68uEx6UsaLj80Bbst3tgI/edit"
              target="_blank"
              title="My link1"
              rel="noreferrer"
            />,
          }}
      />
      </Description>
      {noDietTypes ? (
        <div
          style={{ width: '100%', height: 300 }}
          className="flex center alignCenter textAlignCenter lightgrey"
        >
          {t('diet.noDiet')}&nbsp;
          {authorized ? <Link to="/settings/diets">{t('diet.createDietLink')}</Link> : null}
        </div>
      ) : (
        <Form fluid plaintext formValue={{}}>
          {dietTypes?.map(({ name }: DietType) => (
            <Form.Group key={name}>
              <Form.ControlLabel>
                {capitalize(name)}
                <FormTooltip tooltip>
                  {t('diet.form.average_Tooltip', { name: name.toLocaleLowerCase() })}
                </FormTooltip>
              </Form.ControlLabel>
              {t('misc.units.valueMeals', {
                value: Math.round((averageValues[name] || 0) * 100) / 100,
              })}
            </Form.Group>
          ))}
        </Form>
      )}
      {!diet ? null : (
        <>
          <Modal
            open={modalDietValueIsOpen}
            onClose={() => setModalDietValueIsOpen(false)}
            onExited={() => setDietValuesForm(null)}
            onValidate={addDietValue}
            onCancel={() => Promise.resolve(setModalDietValueIsOpen(false))}
            title={modalDietValueTitle}
          >
            <DietValueForm
              value={dietValuesForm}
              setValue={setDietValuesForm}
              isAverage={isAverage}
            />
          </Modal>

          <ModalAddDietList
            open={modalDietListIsOpen}
            onClose={() => {
              setModalDietListIsOpen(false)
            }}
            onCancel={() => Promise.resolve(setModalDietListIsOpen(false))}
            dietId={diet?.id}
            facilityDataId={facilityDataId}
          />
        </>
      )}
    </>
  )
}
