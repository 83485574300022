import { ReactNode } from 'react'
import classes from './Container.module.css'

type Props = {
  style?: Object
  className?: string
  children: ReactNode | ReactNode[]
  bordered?: boolean
}

export function Container(props: Props) {
  const { style, className, children, bordered } = props

  return (
    <div
      style={style}
      className={`${classes.container} ${className || ''} ${bordered ? classes.bordered : ''}`}
    >
      {children}
    </div>
  )
}
