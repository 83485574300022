import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup } from 'rsuite'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type AnnualTravelsFormProps<Model> = {
  model: Partial<Model> | null
  setModel: (faciltity: Partial<Model>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function AnnualTravelsForm<Model>(props: AnnualTravelsFormProps<Model>) {
  const { model, setModel, getFormErrors, setHasBlurred } = props

  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <>
      <div>
        <span className="bold">{t('productions.travels.travelsByPlane')}</span>
      </div>
      <Form
        layout="inline"
        fluid
        plaintext={!authorized}
        onChange={(value) => {
          setModel(value as Model)
        }}
        onCheck={setFormError}
        onBlur={() => setHasBlurred(true)}
        formValue={model || {}}
        style={{ display: 'flex' }}
      >
        <Form.Group>
          <Form.ControlLabel style={{ marginBottom: 4 }}>
            {t('constants.dataTypes.Previz')}
            <FormTooltip tooltip>{t('productions.travels.travelsByPlane_Tooltip')}</FormTooltip>
          </Form.ControlLabel>
          <InputGroup style={{ width: '100%' }}>
            <Form.Control
              name="annualKmTraveldByPlanePreviz"
              accepter={InputCustom}
              type="number"
            />
            <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ marginLeft: 25 }}>
          <Form.ControlLabel style={{ marginBottom: 4 }}>
            {t('constants.dataTypes.Reel')}
            <FormTooltip tooltip>
              {t('facilities.annualTravels.annualTravelsByPlane_Tooltip')}
            </FormTooltip>
          </Form.ControlLabel>
          <InputGroup style={{ width: '100%' }}>
            <Form.Control name="annualKmTraveldByPlaneReal" accepter={InputCustom} type="number" />
            <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
      </Form>
      <div>
        <span className="bold">{t('productions.travels.travelsByTrain')}</span>
      </div>
      <Form
        layout="inline"
        fluid
        plaintext={!authorized}
        onChange={(value) => {
          setModel(value as Model)
        }}
        onCheck={setFormError}
        onBlur={() => setHasBlurred(true)}
        formValue={model || {}}
        style={{ display: 'flex' }}
      >
        <Form.Group>
          <Form.ControlLabel style={{ marginBottom: 4 }}>
            {t('constants.dataTypes.Previz')}
            <FormTooltip tooltip>{t('productions.travels.travelsByTrain_Tooltip')}</FormTooltip>
          </Form.ControlLabel>
          <InputGroup style={{ width: '100%' }}>
            <Form.Control
              name="annualKmTraveldByTrainPreviz"
              accepter={InputCustom}
              type="number"
            />
            <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ marginLeft: 25 }}>
          <Form.ControlLabel style={{ marginBottom: 4 }}>
            {t('constants.dataTypes.Reel')}
            <FormTooltip tooltip>{t('productions.travels.travelsByTrain_Tooltip')}</FormTooltip>
          </Form.ControlLabel>
          <InputGroup style={{ width: '100%' }}>
            <Form.Control name="annualKmTraveldByTrainReal" accepter={InputCustom} type="number" />
            <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
      </Form>
    </>
  )
}
