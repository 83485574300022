import { InputCustom } from 'components'
import { CountryPicker } from 'components/CountryPicker/CountryPicker'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import { Facility } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type FacilityDetailsFormProps = {
  facility: Partial<Facility>
  setFacility: (faciltity: Partial<Facility>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function FacilityDetailsForm(props: FacilityDetailsFormProps) {
  const { facility, setFacility, getFormErrors, setHasBlurred } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { t } = useTranslation()

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setFacility}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={facility}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilities.details.form.name')}
          <FormTooltip tooltip>{t('facilities.details.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="facilities.details.form.name_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilities.details.form.country')}
          <FormTooltip tooltip>{t('facilities.details.form.country_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="country"
          accepter={CountryPicker}
          tooltip-id="facilities.details.form.country_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
