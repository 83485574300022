import { CKEditor } from '@ckeditor/ckeditor5-react'
import { CSSProperties, useRef } from 'react'
import i18next from 'i18next'
import ClassicEditor from 'ckeditor'
import 'ckeditor/build/translations/de'
import 'ckeditor/build/translations/en'
import 'ckeditor/build/translations/es'
import 'ckeditor/build/translations/it'
import classes from './TextEditor.module.scss'

export type CustomEditor = any

type TextEditorProps = {
  onChange?: (editor: CustomEditor) => void
  onBlur?: (event: any, editor: CustomEditor) => void
  onFocus?: (event: any, editor: CustomEditor) => void
  isEditing?: boolean
  data?: string
  getEditor?: (editor: CustomEditor | null) => void
  placeholder?: string
  style?: CSSProperties
  readerStyle?: CSSProperties
}

export function TextEditor(props: TextEditorProps) {
  const {
    onChange,
    onBlur,
    onFocus,
    isEditing,
    data = '',
    getEditor,
    placeholder,
    style,
    readerStyle,
  } = props

  const editorRef = useRef<CustomEditor | null>(null)
  const valueRef = useRef<string>(data)

  ClassicEditor.defaultConfig.language = i18next.language

  return (
    <div className={classes.container} style={style}>
      {isEditing ? (
        <CKEditor
          key={i18next.language}
          editor={ClassicEditor}
          placeholder={placeholder}
          data={valueRef.current}
          onReady={(editorObject) => {
            editorRef.current = editorObject
            getEditor?.(editorObject)
          }}
          onChange={(e, editor) => {
            onChange?.(editor)
            valueRef.current = editor.getData()
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          config={{
            placeholder,
          }}
          {...props}
        />
      ) : (
        <div
          className="ck-content"
          style={readerStyle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data || placeholder || '' }}
        />
      )}
    </div>
  )
}
