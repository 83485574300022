import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useTranslation } from 'react-i18next'
import { Button, SelectPicker } from 'rsuite'
import api from 'services/api'
import { Studio, StudioUser } from 'types'
import { userRoles } from 'services/constants/userRoles'
import { useRole } from 'hooks/useRole'
import { ButtonAddStudioUser } from './ButtonAddStudioUser'

type StudioUsersProps = {
  studioId: Studio['id']
}

export function StudioUsers(props: StudioUsersProps) {
  const { studioId } = props
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'owner' })

  const [deleteStudioUser] = api.studioUsers.delete()
  const [updateStudioUser] = api.studioUsers.update()
  const { data: studioUsers = [] } = api.studioUsers.readAll()

  function removeEntry(studioUser: StudioUser) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteStudioUser(studioUser.id),
    })
  }

  const removeButton = (studioUser: StudioUser) => (
    <Button appearance="subtle" onClick={() => removeEntry(studioUser)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth" style={{ marginTop: authorized ? '-40px' : undefined }}>
      {authorized ? (
        <div className="fullWidth flex end marginBottom10">
          <ButtonAddStudioUser studioId={studioId} />
        </div>
      ) : null}
      <div>
        <Table<StudioUser>
          height={500}
          data={studioUsers}
          headerHeight={60}
          onUpdateRow={(data) => updateStudioUser({ data }).unwrap()}
          tableRole="owner"
          columns={[
            {
              name: t('studioUser.form.username'),
              tooltip: t('studioUser.form.username_Tooltip'),
              cellContent: (rowData) => rowData.userInst.username,
              dataKey: 'userInst.username',
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('studioUser.form.email'),
              tooltip: t('studioUser.form.email_Tooltip'),
              cellContent: (rowData) => rowData.userInst.email,
              dataKey: 'userInst.email',
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('studioUser.form.userRole'),
              tooltip: t('studioUser.form.userRole_Tooltip'),
              dataKey: 'role',
              cellContent: (rowData) => t(`constants.userRoles.${rowData.role}`),
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: setValue,
                data: Object.keys(userRoles).map((value) => ({
                  label: t(`constants.userRoles.${value as keyof typeof userRoles}`),
                  value,
                })),
                defaultOpen: true,
                searchable: false,
              }),
            },

            {
              name: t('components.table.remove'),
              role: 'owner',
              cellContent: removeButton,
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>
    </div>
  )
}
