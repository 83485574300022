import ReactDOM from 'react-dom/client'
import { Modal, type ModalProps } from './Modal'

type openModalProps = Partial<ModalProps> & {
  content: JSX.Element | JSX.Element[] | string
  mountElement?: HTMLElement | null
}

export function openModal(props: openModalProps) {
  const { content, onValidate, onCancel, mountElement, ...modalProps } = props

  const modal = ReactDOM.createRoot(
    mountElement || (document.getElementById('modals') as HTMLElement),
  )

  function onClose() {
    modal.unmount()
  }

  function handleValidate() {
    if (onValidate) return onValidate().then(onClose)
    onClose()
    return Promise.resolve()
  }

  function handleCancel() {
    if (onCancel) return onCancel().then(onClose)
    onClose()
    return Promise.resolve()
  }

  modal.render(
    <Modal
      open
      onValidate={handleValidate}
      onCancel={handleCancel}
      onClose={onClose}
      {...modalProps}
    >
      {content}
    </Modal>,
  )
}
