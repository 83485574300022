import React, { useState } from 'react'
import { Panel } from 'rsuite'
import ResponsiveNav from '@rsuite/responsive-nav'
import type { Option } from 'types'
import { useRole } from 'hooks/useRole'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './TabbedContainer.module.scss'

type Props = {
  title?: React.ReactNode
  options: Option[]
  onTabChange: (option?: Option) => void
  onRemove?: (option: Option) => Promise<any>
  children: React.ReactNode
  sideButtons?: React.ReactNode[]
  active?: string | null
  setActive?: (active: string) => void
  style?: Record<string, any>
  navTitleStyle?: Record<string, any>
  className?: string
  isDepartmentGroup?: boolean
}

export function TabbedContainer(props: Props) {
  const {
    title,
    onTabChange,
    onRemove,
    options = [],
    children,
    sideButtons,
    active,
    setActive,
    style,
    navTitleStyle,
    className,
    isDepartmentGroup,
  } = props
  const [_active, _setActive] = useState<string>(options[0]?.value)

  const { authorized } = useRole({ requiredRole: 'editor' })

  function onSelect(optionValue: string | number | undefined) {
    if (!optionValue) return

    onTabChange(options.find((option: Option) => option.value === optionValue))

    if (setActive) setActive(String(optionValue))
    else _setActive(String(optionValue))
  }

  function handleRemove(eventKey: string | number | undefined) {
    if (onRemove) {
      const optionToRemove = options.find((opt) => opt.value === eventKey)
      if (optionToRemove) {
        onRemove(optionToRemove)
      }
    }
  }

  return (
    <div className={`${classes.container} ${className || ''}`} style={style}>
      <div className="flex column fullWidth">
        {title ? <h3 className="marginBottom20">{title}</h3> : null}
        <div className="flex row fullWidth">
          <div className="flex column relative" style={{ width: '95%' }}>
            <ResponsiveNav
              removable={!!onRemove}
              appearance="tabs"
              activeKey={active || _active}
              onSelect={onSelect}
              onItemRemove={authorized ? handleRemove : undefined}
              style={navTitleStyle}
            >
              {options.map(({ label, value, Icon, groupType }) => (
                <ResponsiveNav.Item icon={Icon ? <Icon /> : undefined} key={value} eventKey={value}>
                  <span className="bold">{label}</span>
                  {isDepartmentGroup ? (
                    <FontAwesomeIcon
                      icon={groupType === 0 ? 'user' : 'computer'}
                      style={{ paddingLeft: 5 }}
                    />
                  ) : null}
                </ResponsiveNav.Item>
              ))}
            </ResponsiveNav>

            <div className={classes.scrollShadow} />
            <Panel
              className={classes.panel}
              bordered
              style={{
                borderTop: 'none',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
              }}
            >
              {children}
            </Panel>
          </div>
          {sideButtons && authorized ? (
            <div
              style={{
                height: 'fit-content',
                width: 'fit-content',
                padding: '0px 10px',
                position: 'sticky',
                top: 50 + (navTitleStyle?.top || 0),
                marginTop: -10,
              }}
            >
              {sideButtons.map((btn, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="marginBottom10">
                  {btn}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
