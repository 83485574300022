import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker } from 'rsuite'
import api from 'services/api'
import type { CommutationProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type CommutationProfileFormProps = {
  commutationProfile: Partial<CommutationProfile> | null
  setCommutationProfile: (commutationProfile: Partial<CommutationProfile>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function CommutationProfileForm(props: CommutationProfileFormProps) {
  const { t } = useTranslation()
  const { commutationProfile, setCommutationProfile, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: commutationTypes = [] } = api.commutationTypes.readAll()
  const { data: countryProfiles = [] } = api.countryProfiles.readAll()
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setCommutationProfile}
      onCheck={setFormError}
      formValue={commutationProfile || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('commutation.settings.profiles.form.commutationType')}
          <FormTooltip tooltip>
            {t('commutation.settings.profiles.form.commutationType_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="commutationType"
          accepter={SelectPicker}
          tooltip-id="commutation.settings.profiles.form.commutationType_FieldInformation"
          data={commutationTypes.map((ct) => ({ value: ct.id, label: ct.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('commutation.settings.profiles.form.country')}
          <FormTooltip tooltip>
            {t('commutation.settings.profiles.form.country_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="country"
          accepter={SelectPicker}
          tooltip-id="commutation.settings.profiles.form.country_FieldInformation"
          data={countryProfiles.map((ct) => ({ value: ct.id, label: ct.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('commutation.settings.profiles.form.carbonEquivalent')}
          <FormTooltip tooltip>
            {t('commutation.settings.profiles.form.carbonEquivalent_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="carbonEquivalent"
          accepter={InputCustom}
          tooltip-id="commutation.settings.profiles.form.carbonEquivalent_FieldInformation"
          type="number"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('commutation.settings.profiles.form.mixAverage')}
          <FormTooltip tooltip>
            {t('commutation.settings.profiles.form.mixAverage_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="mixAverage"
          accepter={InputCustom}
          tooltip-id="commutation.settings.profiles.form.mixAverage_FieldInformation"
          type="number"
        />
      </Form.Group>
    </Form>
  )
}
