import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Plus } from '@rsuite/icons'
import { Modal, TabbedContainer } from 'components'
import { openModal } from 'components/Modal/openModal'
import { useAutoSave } from 'hooks/useAutoSave'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'
import api from 'services/api'
import { DepartmentGroup, ID, Option } from 'types'
import { DepartmentGroupData } from 'views/DepartmentGroupData/DepartmentGroupData'
import { useRole } from 'hooks/useRole'
import { DepartmentGroupForm } from './DepartmentGroupForm'

type DepartmentGroupsProps = {
  productionId: ID
  departmentGroups: DepartmentGroup[]
}

export function DepartmentGroups(props: DepartmentGroupsProps) {
  const { productionId, departmentGroups } = props
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })

  const [createDepartmentGroup] = api.departmentGroups.create()
  const [updateDepartmentGroup] = api.departmentGroups.update()
  const [deleteDepartmentGroup] = api.departmentGroups.delete()

  const [departmentGroup, setDepartmentGroup] = useState<Partial<DepartmentGroup> | null>(null)
  const [editDepartmentGroup, setEditDepartmentGroup] = useState<Partial<DepartmentGroup> | null>(
    null,
  )
  const [selectedDepartmentGroup, setSelectedDepartmentGroup] = useState<null | ID>(null)
  const [modalDepartmentGroupIsOpen, setModalDepartmentGroupIsOpen] = useState<boolean>(false)

  const originalDepartmentGroup = useMemo(() => {
    if (!selectedDepartmentGroup) return null
    const production = departmentGroups.find((p) => p.id === selectedDepartmentGroup) || null
    return production
  }, [departmentGroups, selectedDepartmentGroup])

  const { dataHasChange, setHasBlurred } = useAutoSave({
    originalValue: originalDepartmentGroup,
    diffValue: departmentGroup,
    // eslint-disable-next-line no-use-before-define
    save: () => {
      if (departmentGroup?.id) updateDepartmentGroup({ data: departmentGroup })
    },
    timeout: 15,
  })

  function openDepartmentGroupModal(departmentGroup?: Partial<DepartmentGroup>) {
    setEditDepartmentGroup(departmentGroup || null)
    setModalDepartmentGroupIsOpen(true)
  }

  function closeDepartmentGroupModal() {
    setModalDepartmentGroupIsOpen(false)
  }

  async function saveDepartmentGroup() {
    const toSaveDepartmentGroup = dataHasChange ? departmentGroup : editDepartmentGroup

    if (!toSaveDepartmentGroup) return Promise.reject(new Error('toSaveDepartmentGroup is null'))

    if (toSaveDepartmentGroup.id) {
      return updateDepartmentGroup({ data: toSaveDepartmentGroup })
        .unwrap()
        .then(closeDepartmentGroupModal)
    }
    return createDepartmentGroup({
      data: { ...toSaveDepartmentGroup, production: productionId },
    })
      .unwrap()
      .then((newDepartmentGroup) => {
        setSelectedDepartmentGroup(newDepartmentGroup.id)
        closeDepartmentGroupModal()
      })
  }

  function deleteDepartementGroup() {
    if (!departmentGroup) return
    openModal({
      content: (
        <span>{t('components.modals.removeItemValidation', { name: departmentGroup?.name })}</span>
      ),
      onValidate: () =>
        deleteDepartmentGroup(departmentGroup.id as ID)
          .unwrap()
          .then(() => {
            closeDepartmentGroupModal()
            const previousDepartmentGroup = departmentGroups[departmentGroups.length - 2]
            setSelectedDepartmentGroup(previousDepartmentGroup?.id || null)
          }),
    })
  }

  function onDepartmentGroupTabChange(option?: Option) {
    if (!option) return
    setSelectedDepartmentGroup(option.value)
  }

  const departmentGroupsOptions = useMemo<Option[]>(
    () =>
      departmentGroups
        .map((d) => ({
          label: d.name,
          value: d.id,
          groupType: d.groupType,
        }))
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true })),
    [departmentGroups, selectedDepartmentGroup],
  )

  useEffect(() => {
    if (departmentGroups?.length && selectedDepartmentGroup) {
      const departmentGroup = departmentGroups.find((p) => p.id === selectedDepartmentGroup) || null
      setDepartmentGroup(departmentGroup)
    } else if (departmentGroups?.length && !selectedDepartmentGroup) {
      const departmentGroup = departmentGroups[0]
      setSelectedDepartmentGroup(departmentGroup.id)
      setDepartmentGroup(departmentGroup)
    } else {
      setDepartmentGroup(null)
    }
  }, [selectedDepartmentGroup, departmentGroups])

  return (
    <>
      {!originalDepartmentGroup || !authorized ? null : (
        <div className="fullWidth flex end relative" style={{ marginBottom: '-35px' }}>
          <Button appearance="subtle" onClick={() => openDepartmentGroupModal()}>
            <Plus />
          </Button>
          <Button
            onClick={deleteDepartementGroup}
            disabled={!selectedDepartmentGroup}
            appearance="subtle"
          >
            <FontAwesomeIcon icon="trash" />
          </Button>
          {!selectedDepartmentGroup || !dataHasChange ? null : (
            <Button appearance="subtle" color="green" onClick={saveDepartmentGroup}>
              <FontAwesomeIcon icon="floppy-disk" />
            </Button>
          )}
        </div>
      )}
      <TabbedContainer
        style={{ width: 'auto', margin: 0, padding: 0 }}
        onTabChange={onDepartmentGroupTabChange}
        options={departmentGroupsOptions}
        active={selectedDepartmentGroup}
        isDepartmentGroup
      >
        {!originalDepartmentGroup ? (
          <div
            style={{ width: '100%', height: 100 }}
            className="flex center alignCenter textAlignCenter lightgrey"
          >
            {t('departmentGroups.view.noDepartmentGroup')}&nbsp;
            <a tabIndex={0} onClick={() => openDepartmentGroupModal()} role="button">
              {t('departmentGroups.view.createDepartmentGroup')}
            </a>
          </div>
        ) : (
          <>
            <DepartmentGroupForm
              departmentGroup={departmentGroup}
              setDepartmentGroup={setDepartmentGroup}
              setHasBlurred={setHasBlurred}
            />
            <DepartmentGroupData
              key={originalDepartmentGroup.id}
              departmentGroupId={originalDepartmentGroup.id}
              departmentGroupType={departmentGroup?.groupType}
            />
          </>
        )}
      </TabbedContainer>
      <Modal
        open={modalDepartmentGroupIsOpen}
        onClose={closeDepartmentGroupModal}
        onExited={() => setEditDepartmentGroup(null)}
        onValidate={saveDepartmentGroup}
        onCancel={() => Promise.resolve(closeDepartmentGroupModal())}
        title={t('departmentGroups.view.createModalTitle')}
      >
        <DepartmentGroupForm
          departmentGroup={editDepartmentGroup}
          setDepartmentGroup={setEditDepartmentGroup}
          setHasBlurred={setHasBlurred}
        />
      </Modal>
    </>
  )
}
