import { Modal } from 'components/Modal/Modal'
import { useSelector } from 'hooks/store'
import { t } from 'i18next'
import { useState } from 'react'
import { Button, Message, Loader } from 'rsuite'

type Props = {
  errors: string[]
}

const Banner = ({ errors }: Props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { isUpdating } = useSelector((state) => state.computedValuesErrorsUpdater)
  return (
    <>
      <Message showIcon type="warning">
        {t('errors.banner.computedValues')}
        <Button disabled={isUpdating} style={{ marginLeft: 10 }} onClick={handleOpen}>
          {t('misc.buttons.see')}
        </Button>
        {isUpdating ? <Loader size="xs" style={{ marginLeft: 10 }} /> : null}
      </Message>
      <Modal
        title={t('misc.errors')}
        hideValidate
        cancelLabel={t('components.modals.close')}
        open={open}
        onCancel={() => {
          handleClose()
          return Promise.resolve()
        }}
      >
        {errors.map((error: string) => (
          <li>{error}</li>
        ))}
      </Modal>
    </>
  )
}

export default Banner
