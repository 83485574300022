import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { HardwareProfile } from 'types'
import { HardwareProfileForm } from './HardwareProfilesForm'

type ModalAddHardwareProfileProps = ButtonProps

export function ButtonAddHardwareProfile(props: ModalAddHardwareProfileProps) {
  const { t } = useTranslation()
  const [hardwareProfile, setHardwareProfile] = useState<Partial<HardwareProfile> | null>(null)
  const [modalHardwareProfileIsOpen, setModalHardwareProfileIsOpen] = useState<boolean>(false)
  const [createHardwareProfile] = api.hardwareProfiles.create()

  async function addHardwareProfile() {
    if (hardwareProfile) {
      const changeTypeToInteger: any = { ...hardwareProfile }
      if (hardwareProfile?.idleConsumption) {
        changeTypeToInteger.idleConsumption = parseFloat(hardwareProfile?.idleConsumption)
      }
      if (hardwareProfile?.standbyConsumption) {
        changeTypeToInteger.standbyConsumption = parseFloat(hardwareProfile?.standbyConsumption)
      }
      if (hardwareProfile?.renderConsumption) {
        changeTypeToInteger.renderConsumption = parseFloat(hardwareProfile?.renderConsumption)
      }
      await createHardwareProfile({ data: changeTypeToInteger })
    }
    setModalHardwareProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalHardwareProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <FontAwesomeIcon icon="server" className="marginRight10" /> {t('hardwareProfile.addButton')}
      </Button>
      <Modal
        open={modalHardwareProfileIsOpen}
        onClose={() => setModalHardwareProfileIsOpen(false)}
        onExited={() => setHardwareProfile(null)}
        onValidate={addHardwareProfile}
        onCancel={() => Promise.resolve(setModalHardwareProfileIsOpen(false))}
        title={t('hardwareProfile.addButton')}
      >
        {(formValidator) => (
          <HardwareProfileForm
            formValidator={formValidator}
            hardwareProfile={hardwareProfile}
            setHardwareProfile={setHardwareProfile}
          />
        )}
      </Modal>
    </>
  )
}
