import { useMemo } from 'react'
import api from 'services/api'
import { ComputedValues } from 'types'

type UseComputedValueProps<T> = {
  type?: keyof ComputedValues & T
  skip?: boolean
}

type Output<T extends keyof ComputedValues> = {
  computedValue: ComputedValues[T] | null
  errors: string[] | null
  refetch: () => void
}

export function useComputedValue<T extends keyof ComputedValues>(
  params?: UseComputedValueProps<T>,
): Output<T> {
  const { type, skip } = params || {}

  const { data: computedValues, refetch } = api.compute.calculate(undefined, { skip })

  const computedValue = useMemo(() => {
    if (!computedValues || !type) return null
    return computedValues[type] as ComputedValues[T]
  }, [computedValues])

  const errors = useMemo(() => {
    if (!computedValues?.errors) return null
    return computedValues?.errors as string[]
  }, [computedValues])

  return {
    computedValue,
    errors,
    refetch,
  }
}
