import { useEffect, useRef, useState } from 'react'
import { Button, Form, FormInstance, Panel } from 'rsuite'
import { useNavigate, useParams } from 'react-router-dom'
import { InputCustom } from 'components'
import api from 'services/api'
import { Description } from 'components/Description/Descrption'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import classes from './NewPassword.module.css'
import { newPasswordValidationForm } from './newPasswordValidationForm'

type NewPasswordType = {
  password: string
  confirmPassword: string
}

export function NewPassword() {
  const { uid, token } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [resetPasswordConfirm, { isLoading }] = api.me.resetPasswordConfirm()

  const [newPassword, setNewPassword] = useState<Partial<NewPasswordType>>({
    password: '',
    confirmPassword: '',
  })
  const [passwordIsChanged, setPasswordIsChanged] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[] | void>()

  const formRef = useRef<FormInstance>()
  const redirectTimer = useRef<NodeJS.Timeout | null>(null)

  function redirectToLoginPage() {
    redirectTimer.current = setTimeout(() => navigate('/login'), 1000 * 7)
  }

  function handleNewPassword() {
    const { password, confirmPassword } = newPassword
    if (formRef.current?.check() && uid && token && password && confirmPassword) {
      resetPasswordConfirm({
        uid,
        token,
        new_password: password,
        re_new_password: confirmPassword,
      })
        .unwrap()
        .then(() => {
          setPasswordIsChanged(true)
          redirectToLoginPage()
        })
        .catch((error) => {
          if (error.data) {
            setErrors(map(error.data, (value) => value))
          }
        })
    }
  }

  function description() {
    return (
      <Description color="grey" style={{ paddingTop: 10 }}>
        {passwordIsChanged ? (
          <span>
            <p>{t('newPassword.helpers.passwordChanged')}</p>
          </span>
        ) : (
          <span>
            <p>{t('newPassword.helpers.requirement')}</p>
          </span>
        )}
      </Description>
    )
  }

  useEffect(
    () => () => {
      if (redirectTimer.current !== null) clearTimeout(redirectTimer.current)
    },
    [],
  )

  return (
    <div className="fullWidth fullHeight flex center alignCenter">
      <div className={classes.container}>
        <Panel header={t('newPassword.title')} className="rs-panel-large-header" shaded>
          {description()}
          {!passwordIsChanged ? (
            <Form
              fluid
              ref={(ref: FormInstance) => {
                formRef.current = ref
              }}
              onChange={setNewPassword}
              onSubmit={handleNewPassword}
              formValue={newPassword}
              model={newPasswordValidationForm}
            >
              <Form.Group>
                <Form.ControlLabel>{t('newPassword.password')}</Form.ControlLabel>
                <Form.Control name="password" type="password" accepter={InputCustom} />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>{t('newPassword.confirmPassword')}</Form.ControlLabel>
                <Form.Control name="confirmPassword" type="password" accepter={InputCustom} />
              </Form.Group>

              {errors ? errors.map((error) => <div className={classes.error}>{error}</div>) : null}

              <div className="flex end marginTop30">
                <Button appearance="primary" type="submit" disabled={isLoading}>
                  {t('newPassword.resetButton')}
                </Button>
              </div>
              <div className="flex fullWidth end paddingTop10">
                <a href="/login">{t('newPassword.backLoginPage')}</a>
              </div>
            </Form>
          ) : null}
        </Panel>
      </div>
    </div>
  )
}
