import { map } from 'lodash'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Form, FormInstance, SelectPicker } from 'rsuite'
import { InputCustom } from 'components'
import { hardwareTypes, hardwareTypesIcons } from 'services/constants/hadwareTypes'
import type { HardwareProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalFormValidator } from 'components/Modal/Modal'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { SystemUnitIcon } from 'components/Icons/SystemUnit'
import model from './HardwareProfilesForm.model'

type HardwareProfileFormProps = {
  formValidator?: (validationFunction: ModalFormValidator) => void
  hardwareProfile: Partial<HardwareProfile> | null
  setHardwareProfile: (hardwareProfile: Partial<HardwareProfile>) => void
}

type FormRef = FormInstance<Record<string, any>, string, { [x: string]: string | undefined }> | null

export function HardwareProfileForm(props: HardwareProfileFormProps) {
  const { hardwareProfile, setHardwareProfile, formValidator } = props

  const { t } = useTranslation()
  const formRef = useRef<FormRef | null>(null)

  const { authorized } = useRole({ requiredRole: 'editor' })

  useEffect(() => {
    formValidator?.(formRef.current?.check)
  }, [formValidator, formRef.current])

  return (
    <Form
      ref={formRef}
      fluid
      plaintext={!authorized}
      onChange={setHardwareProfile}
      formValue={hardwareProfile || {}}
      model={model}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.name')}
          <FormTooltip tooltip>{t('hardwareProfile.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.name_FieldInformation"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.hardwareType')}
          <FormTooltip tooltip>{t('hardwareProfile.form.hardwareType_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="hardwareType"
          accepter={SelectPicker}
          tooltip-id="hardwareProfile.form.hardwareType_FieldInformation"
          data={map(hardwareTypes, (label, value) => ({
            label: (
              <span>
                {(value as keyof typeof hardwareTypes) === 'tw' ? (
                  <SystemUnitIcon className="marginRight10" />
                ) : (
                  <FontAwesomeIcon
                    icon={hardwareTypesIcons[value as keyof typeof hardwareTypes]}
                    className="marginRight10"
                  />
                )}
                {t(`constants.hardwareTypes.${value as keyof typeof hardwareTypes}`)}
              </span>
            ),
            value,
          }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.isCloud')}
          <FormTooltip tooltip>{t('hardwareProfile.form.isCloud_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control<typeof Checkbox>
          name="isCloud"
          accepter={Checkbox}
          checked={!!hardwareProfile?.isCloud}
          value={!hardwareProfile?.isCloud as any}
          tooltip-id="hardwareProfile.form.isCloud_FieldInformation"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.manufacturingCarbonEquivalent')}
          <FormTooltip tooltip>
            {t('hardwareProfile.form.manufacturingCarbonEquivalent_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="manufacturingCarbonEquivalent"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.manufacturingCarbonEquivalent_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.idleEnergyConsumptionKwh')}
          <FormTooltip tooltip>
            {t('hardwareProfile.form.idleEnergyConsumptionKwh_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="idleConsumption"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.idleEnergyConsumptionKwh_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.standbyEnergyConsumptionKwh')}
          <FormTooltip tooltip>
            {t('hardwareProfile.form.standbyEnergyConsumptionKwh_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="standbyConsumption"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.standbyEnergyConsumptionKwh_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.renderEnergyConsumptionKwh')}
          <FormTooltip tooltip>
            {t('hardwareProfile.form.renderEnergyConsumptionKwh_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="renderConsumption"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.renderEnergyConsumptionKwh_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('hardwareProfile.form.year')}
          <FormTooltip tooltip>{t('hardwareProfile.form.year_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="year"
          accepter={InputCustom}
          tooltip-id="hardwareProfile.form.year_FieldInformation"
          type="number"
        />
      </Form.Group>
    </Form>
  )
}
