import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, SelectPicker } from 'rsuite'
import api from 'services/api'
import { hardwareTypes, hardwareTypesIcons } from 'services/constants/hadwareTypes'
import { HardwareProfile } from 'types'
import { SystemUnitIcon } from 'components/Icons/SystemUnit'
import { ButtonAddHardwareProfile } from 'views/HardwareProfiles/ButtonAddHardwareProfiles'

export function HardwareProfiles() {
  const { t } = useTranslation()

  const [deleteHardwareProfiles] = api.hardwareProfiles.delete()
  const [updateHardwareProfiles] = api.hardwareProfiles.update()
  const { data: hardwareProfiles } = api.hardwareProfiles.readAll()

  function removeEntry(hardwareProfile: HardwareProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: hardwareProfile.name,
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteHardwareProfiles(hardwareProfile.id),
    })
  }

  const removeButton = (hardwareProfile: HardwareProfile) => (
    <Button appearance="subtle" onClick={() => removeEntry(hardwareProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('hardwareProfile.title')}</h3>
      <Description>{t('hardwareProfile.description')}</Description>

      <div className="fullWidth flex end marginBottom10">
        <ButtonAddHardwareProfile />
      </div>

      <div>
        <Table
          height={500}
          wordWrap="break-word"
          headerHeight={80}
          data={hardwareProfiles}
          onUpdateRow={(data) => updateHardwareProfiles({ data }).unwrap()}
          columns={[
            {
              name: t('hardwareProfile.form.name'),
              tooltip: t('hardwareProfile.form.name_Tooltip'),
              dataKey: 'name',
              columnProps: {
                width: 180,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue, _, getCellInformations) => ({
                value,
                onChange: setValue,
                as: 'textarea',
                style: { height: getCellInformations().height },
              }),
            },
            {
              name: t('hardwareProfile.form.hardwareType'),
              tooltip: t('hardwareProfile.form.hardwareType_Tooltip'),
              dataKey: 'hardwareType',
              columnProps: {
                flexGrow: 1,
              },
              cellContent: (rowData: HardwareProfile) => (
                <span>
                  {rowData.hardwareType === 'tw' ? (
                    <SystemUnitIcon className="marginRight10" />
                  ) : (
                    <FontAwesomeIcon
                      icon={hardwareTypesIcons[rowData.hardwareType]}
                      className="marginRight10"
                    />
                  )}
                  {t(`constants.hardwareTypes.${rowData.hardwareType}`)}
                </span>
              ),
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: setValue,
                data: map(hardwareTypes, (label, value) => ({
                  label: (
                    <span>
                      {(value as keyof typeof hardwareTypes) === 'tw' ? (
                        <SystemUnitIcon className="marginRight10" />
                      ) : (
                        <FontAwesomeIcon
                          icon={hardwareTypesIcons[value as keyof typeof hardwareTypes]}
                          className="marginRight10"
                        />
                      )}
                      {t(`constants.hardwareTypes.${value as keyof typeof hardwareTypes}`)}
                    </span>
                  ),
                  value,
                })),
                defaultOpen: true,
              }),
            },
            {
              name: t('hardwareProfile.form.manufacturingCarbonEquivalent'),
              tooltip: t('hardwareProfile.form.manufacturingCarbonEquivalent_Tooltip'),
              dataKey: 'manufacturingCarbonEquivalent',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
            },
            {
              name: t('hardwareProfile.form.idleEnergyConsumptionKwh'),
              tooltip: t('hardwareProfile.form.idleEnergyConsumptionKwh_Tooltip'),
              dataKey: 'idleConsumption',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
            },
            {
              name: t('hardwareProfile.form.standbyEnergyConsumptionKwh'),
              tooltip: t('hardwareProfile.form.standbyEnergyConsumptionKwh_Tooltip'),
              dataKey: 'standbyConsumption',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
            },
            {
              name: t('hardwareProfile.form.renderEnergyConsumptionKwh'),
              tooltip: t('hardwareProfile.form.renderEnergyConsumptionKwh_Tooltip'),
              dataKey: 'renderConsumption',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
            },
            {
              name: t('hardwareProfile.form.isCloud'),
              tooltip: t('hardwareProfile.form.isCloud_Tooltip'),
              dataKey: 'isCloud',
              EditComponent: Checkbox,
              editComponentProps: (checked, setValue, endEdit) => ({
                checked,
                value: !checked,
                onChange: (() => {
                  setValue(!checked)
                  endEdit()
                })(),
                className: 'fullWidth flex center alignCenter',
              }),
              cellContent: (rowData: any) => (
                <div className="flex center">
                  <Checkbox checked={rowData.isCloud} readOnly />
                </div>
              ),
              columnProps: {
                width: 80,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
            {
              name: t('hardwareProfile.form.year'),
              tooltip: t('hardwareProfile.form.year_Tooltip'),
              dataKey: 'year',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'number',
              }),
            },
            {
              name: t('cloudSupplierProfile.form.source'),
              tooltip: t('cloudSupplierProfile.form.source_Tooltip'),
              dataKey: 'source',
              columnProps: {
                flexGrow: 2,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue, _, getCellInformations) => ({
                value,
                onChange: setValue,
                as: 'textarea',
                style: { resize: 'none', height: getCellInformations().height },
              }),
            },
            {
              name: t('components.table.remove'),
              role: 'editor',
              cellContent: removeButton,
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>
    </div>
  )
}
