import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom, Modal } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'
import api from 'services/api'
import { CloudSupplierProfile } from 'types'
import { ButtonAddCloudSupplierProfile } from 'views/CloudSupplierProfile/ButtonAddCloudSupplierProfile'
import { CloudSupplierProfileForm } from 'views/CloudSupplierProfile/CloudSupplierProfileForm'

export function CloudSupplierProfiles() {
  const { t } = useTranslation()

  const [deleteCloudSupplierProfiles] = api.cloudSupplierProfile.delete()
  const [updateCloudSupplierProfiles] = api.cloudSupplierProfile.update()
  const { data: cloudSupplierProfiles } = api.cloudSupplierProfile.readAll()

  const [modalCloudSupplierProfilesIsOpen, setModalCloudSupplierProfilesIsOpen] =
    useState<boolean>(false)
  const [cloudSupplierProfileToEdit, setCloudSupplierProfileToEdit] =
    useState<Partial<CloudSupplierProfile> | null>(null)

  function removeEntry(cloudSupplierProfile: CloudSupplierProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteCloudSupplierProfiles(cloudSupplierProfile.id),
    })
  }

  const removeButton = (cloudSupplierProfile: CloudSupplierProfile) => (
    <Button appearance="subtle" onClick={() => removeEntry(cloudSupplierProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('cloudSupplierProfile.title')}</h3>
      <Description>{t('cloudSupplierProfile.description')}</Description>

      <div className="fullWidth flex end marginBottom10">
        <ButtonAddCloudSupplierProfile />
      </div>

      <div>
        <Table
          height={500}
          wordWrap="break-word"
          headerHeight={70}
          data={cloudSupplierProfiles}
          onUpdateRow={(data) => updateCloudSupplierProfiles({ data }).unwrap()}
          columns={[
            {
              name: t('cloudSupplierProfile.form.name'),
              tooltip: t('cloudSupplierProfile.form.name_Tooltip'),
              dataKey: 'name',
              columnProps: {
                flexGrow: 1,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
              }),
            },
            {
              name: t('cloudSupplierProfile.form.depreciationTime'),
              tooltip: t('cloudSupplierProfile.form.depreciationTime_Tooltip'),
              dataKey: 'year',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'number',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('cloudSupplierProfile.form.carbonEquivalent'),
              tooltip: t('cloudSupplierProfile.form.carbonEquivalent_Tooltip'),
              dataKey: 'carbonEquivalent',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('cloudSupplierProfile.form.powerUsageEffectiveness'),
              tooltip: t('cloudSupplierProfile.form.powerUsageEffectiveness_Tooltip'),
              dataKey: 'powerUsageEffectiveness',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('cloudSupplierProfile.form.source'),
              tooltip: t('cloudSupplierProfile.form.source_Tooltip'),
              dataKey: 'source',
              columnProps: {
                flexGrow: 2,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue, _, getCellInformations) => ({
                value,
                onChange: setValue,
                as: 'textarea',
                style: { resize: 'none', height: getCellInformations().height },
              }),
            },
            {
              name: t('components.table.remove'),
              cellContent: removeButton,
              role: 'editor',
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>

      <Modal
        open={modalCloudSupplierProfilesIsOpen}
        onClose={() => setModalCloudSupplierProfilesIsOpen(false)}
        onExited={() => setCloudSupplierProfileToEdit(null)}
        onValidate={() => {
          if (cloudSupplierProfileToEdit) {
            return updateCloudSupplierProfiles({ data: cloudSupplierProfileToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalCloudSupplierProfilesIsOpen(false))}
        title={t('cloudSupplierProfile.modalTitle')}
      >
        <CloudSupplierProfileForm
          cloudSupplierProfile={cloudSupplierProfileToEdit}
          setCloudSupplierProfile={setCloudSupplierProfileToEdit}
        />
      </Modal>
    </div>
  )
}
