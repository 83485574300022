import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom, Modal } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, FlexboxGrid, SelectPicker } from 'rsuite'
import api from 'services/api'
import { DietProfile, DietType } from 'types'
import { ButtonAddDietProfile } from 'views/DietProfile/ButtonAddDietProfile'
import { DietProfileForm } from 'views/DietProfile/DietProfileForm'
import { ButtonAddDietType } from 'views/DietType/ButtonAddDietType'
import { DietTypeForm } from 'views/DietType/DietTypeForm'

export function Diets() {
  const { t } = useTranslation()
  const [deleteDietType] = api.dietTypes.delete()
  const [updateDietType] = api.dietTypes.update()
  const { data: dietTypes = [] } = api.dietTypes.readAll()
  const [deleteDietProfile] = api.dietProfiles.delete()
  const [updateDietProfile] = api.dietProfiles.update()
  const { data: dietProfiles = [] } = api.dietProfiles.readAll()
  const { data: countryProfiles = [] } = api.countryProfiles.readAll()

  const [modalDietTypeIsOpen, setModalDietTypeIsOpen] = useState<boolean>(false)
  const [dietTypeToEdit, setDietTypeToEdit] = useState<Partial<DietType> | null>(null)
  const [modalDietProfileIsOpen, setModalDietProfileIsOpen] = useState<boolean>(false)
  const [dietProfileToEdit, setDietProfileToEdit] = useState<Partial<DietProfile> | null>(null)

  function removeEntry(dietType: DietType) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', { name: `${dietType?.name}` })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteDietType(dietType.id),
    })
  }

  function removeEntryDietProfile(dietProfile: DietProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteDietProfile(dietProfile.id),
    })
  }

  const removeButtonDietProfile = (dietProfile: DietProfile) => (
    <Button appearance="subtle" onClick={() => removeEntryDietProfile(dietProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  const removeButton = (dietType: DietType) => (
    <Button appearance="subtle" onClick={() => removeEntry(dietType)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('diet.settings.title')}</h3>
      <Description>
        {t('diet.settings.description1')}
        <br />
        {t('diet.settings.description2')}&nbsp;
        <span className="bold">{t('diet.settings.description3')}</span>
        {t('diet.settings.description4')}
      </Description>

      <FlexboxGrid justify="space-around">
        <FlexboxGrid.Item as={Col} colspan={8} md={8} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('diet.settings.dietTypes.title')}</h4>
            <ButtonAddDietType />
          </div>
          <Table
            height={500}
            data={dietTypes}
            headerHeight={60}
            onUpdateRow={(data) => updateDietType({ data }).unwrap()}
            columns={[
              {
                name: t('diet.settings.dietTypes.form.name'),
                tooltip: t('diet.settings.dietTypes.form.name_Tooltip'),
                dataKey: 'name',
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                }),
                columnProps: {
                  flexGrow: 1,
                },
              },
              {
                name: t('components.table.remove'),
                role: 'editor',
                cellContent: removeButton,
                columnProps: {
                  fixed: 'right',
                  width: 60,
                },
                cellProps: {
                  style: {
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              },
            ]}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={14} md={14} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('diet.settings.dietProfiles.title')}</h4>
            <ButtonAddDietProfile />
          </div>

          <Table
            height={500}
            wordWrap="break-word"
            data={dietProfiles}
            headerHeight={60}
            onUpdateRow={(data) => updateDietProfile({ data }).unwrap()}
            columns={[
              {
                name: t('diet.settings.dietProfiles.form.dietType'),
                tooltip: t('diet.settings.dietProfiles.form.dietType_Tooltip'),
                dataKey: 'dietType',
                columnProps: {
                  flexGrow: 1,
                },
                cellContent: (rowData) => dietTypes.find((ct) => ct.id === rowData.dietType)?.name,
                EditComponent: SelectPicker,
                editComponentProps: (value, setValue, endEdit) => ({
                  value,
                  onExiting: endEdit,
                  onChange: setValue,
                  data: dietTypes.map(({ name, id }) => ({ label: name, value: id })),
                  defaultOpen: true,
                }),
              },
              {
                name: t('diet.settings.dietProfiles.form.country'),
                tooltip: t('diet.settings.dietProfiles.form.country_Tooltip'),
                dataKey: 'country',
                columnProps: {
                  flexGrow: 1,
                },
                cellContent: (rowData) =>
                  countryProfiles?.find((cp) => cp.id === rowData.country)?.name,
                EditComponent: SelectPicker,
                editComponentProps: (value, setValue, endEdit) => ({
                  value,
                  onExiting: endEdit,
                  onChange: setValue,
                  data: countryProfiles.map(({ name, id }) => ({ label: name, value: id })),
                  defaultOpen: true,
                }),
              },
              {
                name: t('diet.settings.dietProfiles.form.carbonEquivalent'),
                tooltip: t('diet.settings.dietProfiles.form.carbonEquivalent_Tooltip'),
                dataKey: 'carbonEquivalent',
                columnProps: {
                  flexGrow: 1,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                  type: 'float',
                }),
              },
              {
                name: t('diet.settings.dietProfiles.form.mixAverage'),
                tooltip: t('diet.settings.dietProfiles.form.mixAverage_Tooltip'),
                dataKey: 'mixAverage',
                columnProps: {
                  flexGrow: 1,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                  type: 'float',
                }),
              },
              {
                name: t('diet.settings.dietProfiles.form.source'),
                tooltip: t('diet.settings.dietProfiles.form.source_Tooltip'),
                dataKey: 'source',
                columnProps: {
                  flexGrow: 2,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue, _, getCellInformations) => ({
                  value,
                  onChange: setValue,
                  as: 'textarea',
                  style: { resize: 'none', height: getCellInformations().height },
                }),
              },
              {
                name: t('components.table.remove'),
                role: 'editor',
                cellContent: removeButtonDietProfile,
                columnProps: {
                  fixed: 'right',
                  width: 60,
                },
                cellProps: {
                  style: {
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              },
            ]}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Modal
        open={modalDietTypeIsOpen}
        onClose={() => setModalDietTypeIsOpen(false)}
        onExited={() => setDietTypeToEdit(null)}
        onValidate={() => {
          if (dietTypeToEdit) {
            return updateDietType({ data: dietTypeToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalDietTypeIsOpen(false))}
        title={t('diet.settings.dietTypes.modalTitle')}
      >
        <DietTypeForm dietType={dietTypeToEdit} setDietType={setDietTypeToEdit} />
      </Modal>
      <Modal
        open={modalDietProfileIsOpen}
        onClose={() => setModalDietProfileIsOpen(false)}
        onExited={() => setDietProfileToEdit(null)}
        onValidate={() => {
          if (dietProfileToEdit) {
            return updateDietProfile({ data: dietProfileToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalDietProfileIsOpen(false))}
        title={t('diet.settings.dietProfiles.modalTitle')}
      >
        <DietProfileForm dietProfile={dietProfileToEdit} setDietProfile={setDietProfileToEdit} />
      </Modal>
    </div>
  )
}
