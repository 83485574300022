import { InputCustom } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup, SelectPicker } from 'rsuite'
import api from 'services/api'
import { productionFormats } from 'services/constants/productionFormats'
import type { DepartmentGroupData, Production } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type ProductionDetailsProps = {
  production: Partial<Production>
  setProduction: (production: Partial<Production>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function ProductionDetails(props: ProductionDetailsProps) {
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { production, setProduction, getFormErrors, setHasBlurred } = props

  const [formError, setFormError] = useState({})
  const [peopleGroupDatas, setPeopleGroupDatas] = useState<DepartmentGroupData[]>([])
  const [machineGroupDatas, setMachineGroupDatas] = useState<DepartmentGroupData[]>([])

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  const { data: departmentGroupDatas = [] } = api.departmentGroupDatas.readAll()
  const { data: departmentGroups = [] } = api.departmentGroups.readAll({
    filters: { production: production.id },
  })

  useEffect(() => {
    if (departmentGroupDatas.length && departmentGroups.length) {
      const peopleGroup = departmentGroups.filter((dg) => dg.groupType === 0)
      const machineGroup = departmentGroups.filter((dg) => dg.groupType === 1)

      setPeopleGroupDatas(
        peopleGroup.flatMap((people) =>
          departmentGroupDatas.filter((department) => department.departmentGroup === people.id),
        ),
      )
      setMachineGroupDatas(
        machineGroup.flatMap((machine) =>
          departmentGroupDatas.filter((department) => department.departmentGroup === machine.id),
        ),
      )
    }
  }, [departmentGroupDatas, departmentGroups])

  const totalpersonDays = useMemo(
    () =>
      peopleGroupDatas.reduce(
        (acc, peopleGroupData) => {
          if (peopleGroupData.dataType === 0) {
            acc.previz += peopleGroupData.personDays
          } else if (peopleGroupData.dataType === 1) {
            acc.real += peopleGroupData.personDays
          }
          return acc
        },
        { previz: 0, real: 0 },
      ),
    [peopleGroupDatas],
  )

  const totalMachineDays = useMemo(
    () =>
      machineGroupDatas.reduce(
        (acc, machineGroupData) => {
          if (machineGroupData.dataType === 0) {
            acc.previz += machineGroupData.personDays
          } else if (machineGroupData.dataType === 1) {
            acc.real += machineGroupData.personDays
          }
          return acc
        },
        { previz: 0, real: 0 },
      ),
    [machineGroupDatas],
  )

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setProduction}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={production}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.name')}
          <FormTooltip tooltip>{t('productions.details.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="productions.details.name_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.totalpersonDays')}
          <FormTooltip tooltip>{t('productions.details.totalpersonDays_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <div className="flex spaceBetween">
          <span className="bold capitalizeFirstLetter lightgrey">
            {t('constants.dataTypes.Previz')}
          </span>
          <span>{t('misc.units.valuePersonDays', { value: totalpersonDays.previz })}</span>
        </div>
        <div className="flex spaceBetween">
          <span className="bold capitalizeFirstLetter lightgrey">
            {t('constants.dataTypes.Reel')}
          </span>
          <span>{t('misc.units.valuePersonDays', { value: totalpersonDays.real })}</span>
        </div>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.totalrenderHours')}
          <FormTooltip tooltip>{t('productions.details.totalrenderHours_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <div className="flex spaceBetween">
          <span className="bold capitalizeFirstLetter lightgrey">
            {t('constants.dataTypes.Previz')}
          </span>
          <span>{t('misc.units.valueRenderHours', { value: totalMachineDays.previz })}</span>
        </div>
        <div className="flex spaceBetween">
          <span className="bold capitalizeFirstLetter lightgrey">
            {t('constants.dataTypes.Reel')}
          </span>
          <span>{t('misc.units.valueRenderHours', { value: totalMachineDays.real })}</span>
        </div>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.productionFormat')}
          <FormTooltip tooltip>{t('productions.details.productionFormat_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="productionFormat"
          accepter={SelectPicker}
          data={Object.keys(productionFormats).map((value) => ({
            value,
            label: t(`constants.productionFormats.${value as keyof typeof productionFormats}`),
          }))}
          style={{ width: '100%' }}
          tooltip-id="productions.details.productionFormat_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.nbEpisodes')}
          <FormTooltip tooltip>{t('productions.details.nbEpisodes_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="nbEpisodes"
            type="number"
            accepter={InputCustom}
            tooltip-id="productions.details.nbEpisodes_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.ep')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.episodeLength')}
          <FormTooltip tooltip>{t('productions.details.episodeLength_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="episodeLength"
            type="number"
            accepter={InputCustom}
            tooltip-id="productions.details.episodeLength_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.min')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.startDate')}
          <FormTooltip tooltip>{t('productions.details.startDate_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="startDate"
            type="date"
            accepter={InputCustom}
            tooltip-id="productions.details.startDate_FieldInformation"
          />
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('productions.details.endDate')}
          <FormTooltip tooltip>{t('productions.details.endDate_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="endDate"
            type="date"
            accepter={InputCustom}
            tooltip-id="productions.details.endDate_FieldInformation"
          />
        </InputGroup>
      </Form.Group>
    </Form>
  )
}
