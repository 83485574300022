import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom, Modal } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, FlexboxGrid, SelectPicker } from 'rsuite'
import api from 'services/api'
import { CommutationProfile, CommutationType } from 'types'
import { ButtonAddCommutationProfile } from 'views/CommutationProfile/ButtonAddCommutationProfile'
import { CommutationProfileForm } from 'views/CommutationProfile/CommutationProfileForm'
import { ButtonAddCommutationType } from 'views/CommutationType/ButtonAddComutationType'
import { CommutationTypeForm } from 'views/CommutationType/CommutationTypeForm'

export function Commutations() {
  const { t } = useTranslation()

  const [deleteCommutationType] = api.commutationTypes.delete()
  const [updateCommutationType] = api.commutationTypes.update()
  const { data: commutationTypes = [] } = api.commutationTypes.readAll()
  const [deleteCommutationProfile] = api.commutationProfiles.delete()
  const [updateCommutationProfile] = api.commutationProfiles.update()
  const { data: commutationProfiles = [] } = api.commutationProfiles.readAll()
  const { data: countryProfiles = [] } = api.countryProfiles.readAll()

  const [modalCommutationTypeIsOpen, setModalCommutationTypeIsOpen] = useState<boolean>(false)
  const [commutationTypeToEdit, setCommutationTypeToEdit] =
    useState<Partial<CommutationType> | null>(null)
  const [modalCommutationProfileIsOpen, setModalCommutationProfileIsOpen] = useState<boolean>(false)
  const [commutationProfileToEdit, setCommutationProfileToEdit] =
    useState<Partial<CommutationProfile> | null>(null)

  function removeEntry(commutationType: CommutationType) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', { name: commutationType?.name })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteCommutationType(commutationType.id),
    })
  }

  function removeEntryCommutationProfile(commutationProfile: CommutationProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove') || undefined,
      validateColor: 'red',
      onValidate: () => deleteCommutationProfile(commutationProfile.id),
    })
  }

  const removeButtonCommutationProfile = (commutationProfile: CommutationProfile) => (
    <Button appearance="subtle" onClick={() => removeEntryCommutationProfile(commutationProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  const removeButton = (commutationType: CommutationType) => (
    <Button appearance="subtle" onClick={() => removeEntry(commutationType)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('commutation.settings.title')}</h3>
      <Description>
        {t('commutation.settings.description1')}
        <br />
        {t('commutation.settings.description2')}&nbsp;
        <span className="bold">{t('commutation.settings.description3')}</span>
        {t('commutation.settings.description4')}
      </Description>

      <FlexboxGrid justify="space-around">
        <FlexboxGrid.Item as={Col} colspan={8} md={8} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('commutation.settings.commutations.title')}</h4>
            <ButtonAddCommutationType />
          </div>

          <Table
            height={500}
            data={commutationTypes}
            headerHeight={70}
            onUpdateRow={(data) => updateCommutationType({ data }).unwrap()}
            columns={[
              {
                name: t('commutation.settings.commutations.form.name'),
                tooltip: t('commutation.settings.commutations.form.name_Tooltip'),
                dataKey: 'name',
                columnProps: {
                  flexGrow: 1,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                }),
              },
              {
                name: t('components.table.remove') || '',
                role: 'editor',
                cellContent: removeButton,
                columnProps: {
                  fixed: 'right',
                  width: 60,
                },
                cellProps: {
                  style: {
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              },
            ]}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={14} md={14} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('commutation.settings.profiles.title')}</h4>
            <ButtonAddCommutationProfile />
          </div>

          <Table
            height={500}
            wordWrap="break-word"
            data={commutationProfiles}
            headerHeight={70}
            onUpdateRow={(data) => updateCommutationProfile({ data }).unwrap()}
            columns={[
              {
                name: t('commutation.settings.profiles.form.commutationType'),
                tooltip: t('commutation.settings.profiles.form.commutationType_Tooltip'),
                dataKey: 'commutationType',
                columnProps: {
                  flexGrow: 1,
                },
                cellContent: (rowData: CommutationProfile) =>
                  commutationTypes.find((ct) => ct.id === rowData.commutationType)?.name,
                EditComponent: SelectPicker,
                editComponentProps: (value, setValue, endEdit) => ({
                  value,
                  onExiting: endEdit,
                  onChange: setValue,
                  data: commutationTypes.map(({ name, id }) => ({ label: name, value: id })),
                  defaultOpen: true,
                }),
              },
              {
                name: t('commutation.settings.profiles.form.country'),
                tooltip: t('commutation.settings.profiles.form.country_Tooltip'),
                dataKey: 'country',
                columnProps: {
                  flexGrow: 1,
                },
                cellContent: (rowData: CommutationProfile) =>
                  countryProfiles.find((cp) => cp.id === rowData.country)?.name,
                EditComponent: SelectPicker,
                editComponentProps: (value, setValue, endEdit) => ({
                  value,
                  onExiting: endEdit,
                  onChange: setValue,
                  data: countryProfiles.map(({ name, id }) => ({ label: name, value: id })),
                  defaultOpen: true,
                }),
              },
              {
                name: t('commutation.settings.profiles.form.carbonEquivalent'),
                tooltip: t('commutation.settings.profiles.form.carbonEquivalent_Tooltip'),
                dataKey: 'carbonEquivalent',
                columnProps: {
                  flexGrow: 1,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                  type: 'float',
                }),
              },
              {
                name: t('commutation.settings.profiles.form.mixAverage'),
                tooltip: t('commutation.settings.profiles.form.mixAverage_Tooltip'),
                dataKey: 'mixAverage',
                columnProps: {
                  flexGrow: 1,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue) => ({
                  value,
                  onChange: setValue,
                  type: 'float',
                }),
              },
              {
                name: t('commutation.settings.profiles.form.source'),
                tooltip: t('commutation.settings.profiles.form.source_Tooltip'),
                dataKey: 'source',
                columnProps: {
                  flexGrow: 2,
                },
                EditComponent: InputCustom,
                editComponentProps: (value, setValue, _, getCellInformations) => ({
                  value,
                  onChange: setValue,
                  as: 'textarea',
                  style: { resize: 'none', height: getCellInformations().height },
                }),
              },
              {
                name: t('components.table.remove'),
                role: 'editor',
                cellContent: removeButtonCommutationProfile,
                columnProps: {
                  fixed: 'right',
                  width: 60,
                },
                cellProps: {
                  style: {
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              },
            ]}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Modal
        open={modalCommutationTypeIsOpen}
        onClose={() => setModalCommutationTypeIsOpen(false)}
        onExited={() => setCommutationTypeToEdit(null)}
        onValidate={() => {
          if (commutationTypeToEdit) {
            return updateCommutationType({ data: commutationTypeToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalCommutationTypeIsOpen(false))}
        title={t('commutation.settings.commutations.form.modalTitle')}
      >
        <CommutationTypeForm
          commutationType={commutationTypeToEdit}
          setCommutationType={setCommutationTypeToEdit}
        />
      </Modal>
      <Modal
        open={modalCommutationProfileIsOpen}
        onClose={() => setModalCommutationProfileIsOpen(false)}
        onExited={() => setCommutationProfileToEdit(null)}
        onValidate={() => {
          if (commutationProfileToEdit) {
            return updateCommutationProfile({ data: commutationProfileToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalCommutationProfileIsOpen(false))}
        title={t('commutation.settings.profiles.form.modalTitle')}
      >
        <CommutationProfileForm
          commutationProfile={commutationProfileToEdit}
          setCommutationProfile={setCommutationProfileToEdit}
        />
      </Modal>
    </div>
  )
}
