import { createApi } from '@reduxjs/toolkit/query/react'
import type { ComputedValues } from 'types'
import { baseQuery } from '../baseQuery'
import { transformResponse } from '../utils'

const computeApi = createApi({
  reducerPath: 'ComputedValues',
  baseQuery,
  tagTypes: ['ComputedValues'],
  endpoints: (build) => ({
    calculate: build.query<ComputedValues, void>({
      query: () => ({
        url: '/compute/',
        method: 'GET',
      }),
      transformResponse: (response: ComputedValues) => transformResponse<ComputedValues>(response),
      providesTags: () => [{ type: 'ComputedValues', id: 'COMPUTED_VALUES' }],
    }),
  }),
})

export { computeApi }
