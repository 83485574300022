import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import type { DietType } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type DietTypeFormProps = {
  dietType: Partial<DietType> | null
  setDietType: (dietType: Partial<DietType>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function DietTypeForm(props: DietTypeFormProps) {
  const { t } = useTranslation()
  const { dietType, setDietType, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setDietType}
      onCheck={setFormError}
      formValue={dietType || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('diet.settings.dietTypes.form.name')}
          <FormTooltip tooltip>{t('diet.settings.dietTypes.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="diet.settings.dietTypes.form.name_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
