import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Nav } from 'rsuite'
import { CloudSupplierProfiles } from 'views/CloudSupplierProfile/CloudSupplierProfilesSettings'
import { Commutations } from 'views/Commutation/CommutationsSettings'
import { CountryProfiles } from 'views/CountryProfiles/CountryProfilesSettings'
import { Diets } from 'views/Diet/DietSettings'
import { EnergySuppliers } from 'views/EnergySupplierProfile/EnergySupplierSettings'
import { HardwareProfiles } from 'views/HardwareProfiles/HardwareProfileSettings'
import classes from './Settings.module.css'

export function Settings() {
  const { t } = useTranslation()
  const { page } = useParams()
  const navigate = useNavigate()

  function setPage(page: string) {
    navigate(`/settings/${page}`)
  }

  function getPageComponent() {
    switch (page) {
      case 'commutations':
        return <Commutations />
      case 'countryProfiles':
        return <CountryProfiles />
      case 'diets':
        return <Diets />
      case 'energySuppliers':
        return <EnergySuppliers />
      case 'cloudSupplierProfile':
        return <CloudSupplierProfiles />
      case 'hardwareProfiles':
        return <HardwareProfiles />

      default:
        return (
          <div className="fullWidth fullHeight flex canter alignCenter textCenter lightgrey">
            {t('settings.defaultTab')}
          </div>
        )
    }
  }

  return (
    <div className={classes.container}>
      <Nav
        appearance="tabs"
        vertical
        activeKey={page}
        onSelect={setPage}
        style={{ margin: '10px 0px', marginRight: '-1px' }}
      >
        <Nav.Item eventKey="commutations">
          <FontAwesomeIcon icon="car" className="marginRight8" />{' '}
          {t('commutation.settings.commutations.title')}
        </Nav.Item>

        <Nav.Item eventKey="countryProfiles">
          <FontAwesomeIcon icon="earth-americas" className="marginRight8" />{' '}
          {t('countryProfile.title')}
        </Nav.Item>

        <Nav.Item eventKey="diets">
          <FontAwesomeIcon icon="burger" className="marginRight8" /> {t('diet.settings.title')}
        </Nav.Item>

        <Nav.Item eventKey="energySuppliers">
          <FontAwesomeIcon icon="lightbulb" className="marginRight8" /> {t('energySuppliers.title')}
        </Nav.Item>

        <Nav.Item eventKey="cloudSupplierProfile">
          <FontAwesomeIcon icon="cloud" className="marginRight8" />{' '}
          {t('cloudSupplierProfile.title')}
        </Nav.Item>

        <Nav.Item eventKey="hardwareProfiles">
          <FontAwesomeIcon icon="server" className="marginRight8" /> {t('hardwareProfile.title')}
        </Nav.Item>
      </Nav>
      <div className={classes.pageContainer}>{getPageComponent()}</div>
    </div>
  )
}
