import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker } from 'rsuite'
import api from 'services/api'
import { DepartmentGroup } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { GROUP_TYPES } from 'utils/constants'

type DepartmentGroupFormProps = {
  departmentGroup: Partial<DepartmentGroup> | null
  setDepartmentGroup: (faciltity: Partial<DepartmentGroup>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function DepartmentGroupForm(props: DepartmentGroupFormProps) {
  const { t } = useTranslation()
  const { departmentGroup, setDepartmentGroup, getFormErrors, setHasBlurred } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: facilities = [] } = api.facilities.readAll()
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setDepartmentGroup}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={departmentGroup || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroups.details.name')}
          <FormTooltip tooltip>{t('departmentGroups.details.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="departmentGroups.details.name_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroups.details.facility')}
          <FormTooltip tooltip>{t('departmentGroups.details.facility_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="facility"
          accepter={SelectPicker}
          tooltip-id="departmentGroups.details.facility_FieldInformation"
          data={facilities.map((f) => ({ value: f.id, label: f.name }))}
          style={{ width: '100%' }}
          searchable={false}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('departmentGroups.details.groupType')}
          <FormTooltip tooltip>{t('departmentGroups.details.groupType_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="groupType"
          accepter={SelectPicker}
          tooltip-id="departmentGroups.details.groupType_FieldInformation"
          data={GROUP_TYPES.map((group) => ({
            value: group.id,
            label: t<any>(`departmentGroups.details.${group.name}`),
          }))}
          style={{ width: '100%' }}
          searchable={false}
        />
      </Form.Group>
    </Form>
  )
}
