import { Exit as ExitIcon, Gear as GearIcon } from '@rsuite/icons'
import { useSelector } from 'hooks/store'
import { useNavigate } from 'react-router-dom'
import { Divider, Nav, Popover, Radio, RadioGroup } from 'rsuite'
import { auth } from 'services/authentication/localStorageToken'
import { AppTheme } from 'types'
import { t } from 'i18next'

type AppSettingsMenuParams = {
  theme: AppTheme
  setTheme: (theme: AppTheme) => void
}

type AppSettingsMenuElementParams = {
  onClose: () => void
  left?: number
  top?: number
  className?: string
}

export function appSettingsMenu(params: AppSettingsMenuParams) {
  return function whisperFunction(elementsParams: AppSettingsMenuElementParams, ref: any) {
    const navigate = useNavigate()

    const { theme, setTheme } = params
    const { left, top, className } = elementsParams

    const { authenticated, role, isAdmin } = useSelector((state) => ({
      authenticated: state.authentication.auth.authenticated,
      role: state.authentication.user?.role,
      isAdmin: state.authentication.user?.isAdmin,
    }))

    const navStyle = {
      padding: '18px 14px',
      height: 30,
      color: '#4a4a4a',
      fontSize: '14px',
    }

    function logout() {
      auth.logout()
      navigate('/')
      elementsParams.onClose()
    }

    function goToSettings() {
      navigate('/settings')
    }

    return (
      <Popover
        ref={ref}
        className={className}
        style={{ left, top, minWidth: 200, maxWidth: 250 }}
        full
      >
        <RadioGroup
          name="radioList"
          value={theme || ''}
          onChange={(value) => setTheme(value as AppTheme)}
        >
          <div style={{ margin: '8px 10px' }}>{t('navbar.settings.theme.title')}</div>
          <Nav.Item as="span" style={navStyle}>
            <Radio value="light">{t('navbar.settings.theme.light')}</Radio>
          </Nav.Item>
          <Nav.Item as="span" style={navStyle}>
            <Radio value="dark">{t('navbar.settings.theme.dark')}</Radio>
          </Nav.Item>
          <Nav.Item as="span" style={navStyle}>
            <Radio value="high-contrast">{t('navbar.settings.theme.highContrast')}</Radio>
          </Nav.Item>
        </RadioGroup>
        {authenticated ? (
          <>
            {role === 'owner' && isAdmin ? (
              <>
                <Nav.Item style={{ height: 1, width: '100%', padding: 0 }}>
                  <Divider style={{ margin: 0, width: '100%' }} />
                </Nav.Item>
                <Nav.Item className="fullWidth" onClick={goToSettings} style={navStyle}>
                  <GearIcon className="marginRight10" /> {t('navbar.settings.backoffice')}
                </Nav.Item>
              </>
            ) : null}
            <Nav.Item style={{ height: 1, width: '100%', padding: 0 }}>
              <Divider style={{ margin: 0, width: '100%' }} />
            </Nav.Item>

            <Nav.Item className="fullWidth" onClick={logout} style={navStyle}>
              <ExitIcon className="marginRight10" /> {t('navbar.settings.signOut')}
            </Nav.Item>
          </>
        ) : null}
      </Popover>
    )
  }
}
