export const algo = `
allCommutationType = CommutationType.objects.all()
allDietType = DietType.objects.all()
allEnergySuppliersProfiles = {
    f"{esp.country_id}_{esp.energyType_id}_{esp.category}": esp for esp in EnergySupplierProfile.objects.all()
}
allCommutationProfiles = {
    f"{cp.country_id}_{cp.commutationType_id}": cp for cp in CommutationProfile.objects.all()
}
allDietProfiles = {f"{diet.country_id}_{diet.dietType_id}": diet for diet in DietProfile.objects.all()}
allMediumProfile = {
    f"{medium.location}_{medium.medium}_{medium.supplier_id if medium.supplier_id is not None else ''}": medium
    for medium in MediumProfile.objects.all()
}
studio = Studio.objects.get(owner=request.user)

facilitiesRes = {}
facilitiesDetailsRes = {}
productionPeopleDays = {}
productionRes = {}
productionDetailsRes = {}
groupRes = {}

for facility in Facility.objects.filter(studio=studio):
    try:
        personDaysInFacility = facility.overallFte * 230

        allElectricityCarbonEquivalent = facility.annualElectricityConsumption * facility.country.energyMix
        allHeatingCarbonEquivalent = (
            facility.annualHeatingConsumption
            * allEnergySuppliersProfiles[
                f"{facility.country_id}_{facility.heatingSupplier_id}_heat"
            ].carbonEquivalent
        )
        allCoolingCarbonEquivalent = (
            facility.annualCoolingConsumption
            * allEnergySuppliersProfiles[
                f"{facility.country_id}_{facility.coolingSupplier_id}_cold"
            ].carbonEquivalent
        )
        allDatacenterCoolingCarbonEquivalent = (
            facility.annualDatacenterCoolingConsumption
            * allEnergySuppliersProfiles[
                f"{facility.country_id}_{facility.datacenterCoolingSupplier_id}_cold"
            ].carbonEquivalent
        )

        paperCarbonEquivalent = 0.629 * facility.annualPaperReamConsumption
        videoStreamingCarbonEquivalent = 0.055 * facility.videoStreamingHoursPerPerson * personDaysInFacility
        audioStreamingCarbonEquivalent = (
            0.055 * (192 / 5000) * facility.audioStreamingHoursPerPerson * personDaysInFacility
        )

        tripsCarbonEquivalent = (facility.annualKmTraveldByPlane * 0.25) + (
            facility.annualKmTraveldByTrain
            * CommutationProfile.objects.get(
                commutationType__name="train", country=facility.country
            ).carbonEquivalent
        )

        meanCommutationCarbonEquivalent = 0
        for commutation in facility.commutations.all()[:20]:
            total = defaultdict(int)
            for value in commutation.values.all():
                total[value.commutationType.name] += value.distance
            for commutationType in allCommutationType:
                if commutationType.name in total:
                    meanCommutationCarbonEquivalent += allCommutationProfiles[
                        f"{facility.country_id}_{commutationType.uuid}"
                    ].carbonEquivalent * (total[commutationType.name] / 20)
        commutationCarbonEquivalent = personDaysInFacility * (meanCommutationCarbonEquivalent / 5)

        meanDietCarbonEquivalent = 0
        for diet in facility.diets.all()[:20]:
            total = defaultdict(int)
            for value in diet.values.all():
                total[value.dietType.name] += value.number
            for dietType in allDietType:
                if dietType.name in total:
                    meanDietCarbonEquivalent += allDietProfiles[
                        f"{facility.country_id}_{dietType.uuid}"
                    ].carbonEquivalent * (total[dietType.name] / 20)
        dietCarbonEquivalent = personDaysInFacility * (meanDietCarbonEquivalent / 5)

        facilityCarbonEquivalent = (
            paperCarbonEquivalent
            + videoStreamingCarbonEquivalent
            + audioStreamingCarbonEquivalent
            + tripsCarbonEquivalent
            + commutationCarbonEquivalent
            + dietCarbonEquivalent
            + allElectricityCarbonEquivalent
            + allHeatingCarbonEquivalent
            + allCoolingCarbonEquivalent
            + allDatacenterCoolingCarbonEquivalent
        )

        facilitiesDetailsRes[str(facility.uuid)] = {
            "paper": paperCarbonEquivalent,
            "videoStreaming": videoStreamingCarbonEquivalent,
            "audioStreaming": audioStreamingCarbonEquivalent,
            "trips": tripsCarbonEquivalent,
            "commutations": commutationCarbonEquivalent,
            "diets": dietCarbonEquivalent,
            "electricity": allElectricityCarbonEquivalent,
            "heating": allHeatingCarbonEquivalent,
            "cooling": allCoolingCarbonEquivalent,
            "datacenterCooling": allDatacenterCoolingCarbonEquivalent,
        }

        facilityCarbonEquivalentexceptPower = facilityCarbonEquivalent - allElectricityCarbonEquivalent
        productionPeopleDayInFacilityExceptPower = facilityCarbonEquivalentexceptPower / (
            (facility.overallFte - facility.numberOfTransverseFte) * 230
        )
        facilitiesRes[str(facility.uuid)] = facilityCarbonEquivalent
        productionPeopleDays[str(facility.uuid)] = productionPeopleDayInFacilityExceptPower
    except:
        facilitiesRes[str(facility.uuid)] = 0
        productionPeopleDays[str(facility.uuid)] = 0

firstFacility = Facility.objects.filter(studio=studio).first()
for production in Production.objects.filter(studio=studio):
    try:
        sumProduction = 0

        for group in production.groups.all():
            try:
                groupCost = group.personDays * (
                    productionPeopleDays[str(group.facility_id)]
                    + (
                        (
                            group.computerProfile.manufacturingCarbonEquivalent / 1700
                            + 0.75
                            * group.computerProfile.idleCarbonEquivalent
                            * group.facility.country.energyMix
                            + 0.25
                            * group.computerProfile.renderCarbonEquivalent
                            * group.facility.country.energyMix
                        )
                        * 8
                    )
                )
                groupRes[str(group.uuid)] = groupCost
                sumProduction += groupCost
            except:
                groupRes[str(group.uuid)] = 0

        serverDetails = {}
        for server in production.servers.all():
            serverCost = ((production.endDate - production.startDate).days + 1) * server.size
            if server.location == "local":
                serverCost *= allMediumProfile[f"{server.location}_{server.medium}_"].carbonEquivalent * (
                    firstFacility.country.energyMix
                    + allEnergySuppliersProfiles[
                        f"{firstFacility.country_id}_{firstFacility.datacenterCoolingSupplier_id}_cold"
                    ].carbonEquivalent
                )
            else:
                serverCost *= allMediumProfile[
                    f"{server.location}_{server.medium}_{server.supplier_id}"
                ].carbonEquivalent
            # print(server.name, serverCost)
            serverDetails[str(server.uuid)] = serverCost
            sumProduction += serverCost
        if production.renderTimeByFrame > 0 and production.nbTimeFrameRendered > 0:
            renderCarbonEquivalent = (
                production.nbEpisodes
                * production.episodeLength
                * 60
                * 24
                * production.renderTimeByFrame
                / 60
                * production.nbTimeFrameRendered
            )
        else:
            renderCarbonEquivalent = production.nbRenderDays * production.nbRenderServer

        renderCarbonEquivalent *= (
            (
                production.renderProfile.manufacturingCarbonEquivalent / 8760
                + production.renderProfile.renderCarbonEquivalent * group.facility.country.energyMix
            )
            * 24
            * (
                firstFacility.country.energyMix
                + allEnergySuppliersProfiles[
                    f"{firstFacility.country_id}_{firstFacility.datacenterCoolingSupplier_id}_cold"
                ].carbonEquivalent
            )
        )
        sumProduction += renderCarbonEquivalent
        productionRes[str(production.uuid)] = sumProduction
        productionDetailsRes[str(production.uuid)] = {
            "render": renderCarbonEquivalent,
            "servers": serverDetails,
        }
    except:
        productionRes[str(production.uuid)] = 0

return Response(
    {
        "facilities": facilitiesRes,
        "facilitiesDetails": facilitiesDetailsRes,
        "productions": productionRes,
        "productionDetails": productionDetailsRes,
        "groups": groupRes,
    }
)

`
