import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import type { ComputerRig } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type ComputerRigsFormProps = {
  computerRig: Partial<ComputerRig> | null
  setComputerRig: (computerRig: Partial<ComputerRig>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function ComputerRigsForm(props: ComputerRigsFormProps) {
  const { computerRig, setComputerRig, getFormErrors } = props
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setComputerRig}
      onCheck={setFormError}
      formValue={computerRig || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('computerRig.form.name')}
          <FormTooltip tooltip>{t('computerRig.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="computerRig.form.name_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
