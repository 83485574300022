import { useEffect, useMemo, useState } from 'react'
import { FlexboxGrid, Panel, Col, Loader } from 'rsuite'
import { TabbedContainer } from 'components'
import { Option, Facility, Studio, Year } from 'types'
import api from 'services/api'
import { FacilityDetailsForm } from 'views/FacilityDetails/FacilityDetailsForm'
import { openModal } from 'components/Modal/openModal'
import { sideButtons } from 'components/TabbedContainer/SideButtonsEdition'
import { useComputedValue } from 'hooks/useComputedValue'
import { useAutoSave } from 'hooks/useAutoSave'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FacilityData } from 'views/FacilityData/FacilityData'
import { FacilityRecap } from 'components/Charts/FacilityRecap/FacilityRecap'
import { CreateFacility } from './CreateFacility'

export function Facilities() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { facilityId } = useParams<Record<string, string>>()

  const { data: facilities = [], isLoading: facilitiesIsLoading } = api.facilities.readAll()
  const { data: studios = [], isLoading: studiosIsLoading } = api.studios.readAll()

  const [updateFacility] = api.facilities.update()
  const [createFacility] = api.facilities.create()
  const [deleteFacility] = api.facilities.delete()

  const [addFacilityModalOpen, setAddFacilityModalOpen] = useState<boolean>(false)
  const [facility, setFacility] = useState<Partial<Facility> | null>(null)
  const [studio, setStudio] = useState<Studio | void>()
  const [selectedYear, setSelectedYear] = useState<Year>(new Date().getFullYear())

  const { computedValue, refetch } = useComputedValue<'facilities'>({
    type: 'facilities',
    skip: !studio,
  })

  useEffect(() => {
    if (!facilitiesIsLoading && !facilityId) {
      navigate(`/facilities/${facilities[0]?.id || ''}`)
    }
  }, [facilities, facilitiesIsLoading, facilityId])

  useEffect(() => {
    if (!studiosIsLoading) setStudio(studios[0])
  }, [studiosIsLoading, studios])

  const originalFacility = useMemo(() => {
    if (!facilityId) return null
    const foundFacility = facilities.find((p) => p.id === facilityId) || null
    if (foundFacility?.updatedAt !== facility?.updatedAt) setFacility(foundFacility)
    return foundFacility
  }, [facilities, facilityId])

  const { dataHasChange, setHasBlurred } = useAutoSave({
    originalValue: originalFacility,
    diffValue: facility,
    updateComputedData: () => {
      refetch()
    },
    save: () => {
      if (facility?.id) updateFacility({ data: facility })
    },
    timeout: 15,
  })

  async function updateData() {
    const promises = []

    if (facility && dataHasChange) {
      if (facility.id) {
        promises.push(updateFacility({ data: facility }))
      } else {
        promises.push(createFacility({ data: facility }))
      }
    }

    return Promise.all(promises).then(() => {
      refetch()
    })
  }

  function addFacility() {
    setAddFacilityModalOpen(true)
  }

  function removeFacility() {
    if (!facilityId) return
    openModal({
      content: <span>{t('components.modals.removeItemValidation', { name: facility?.name })}</span>,
      onValidate: () =>
        deleteFacility(facilityId)
          .unwrap()
          .then(() => {
            navigate(`/facilities/${facilities[0]?.id || ''}`)
            refetch()
          }),
    })
  }

  function cancelChanges() {
    setFacility(originalFacility)
  }

  function onTabChange(option?: Option) {
    if (!option) return
    navigate(`/facilities/${option.value}`)
  }

  function getFacilityYearConsumption(value: any) {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(value))
  }

  if (studiosIsLoading || facilitiesIsLoading) {
    return <Loader />
  }

  return (
    <>
      <TabbedContainer
        onTabChange={onTabChange}
        navTitleStyle={{
          position: 'sticky',
          top: 56,
          paddingTop: 20,
          marginTop: -56,
          backgroundColor: 'var(--rs-body)',
          zIndex: 6,
        }}
        options={
          facilities?.map((facitity) => ({
            label: (
              <span>
                {facitity.name}&nbsp;
                {computedValue?.[facitity.id]?.[selectedYear] ? (
                  <span style={{ fontSize: 12 }} className="salmon">
                    {`${t('misc.units.valueKgEqCo2PerYear', {
                      value: getFacilityYearConsumption(
                        computedValue?.[facitity.id]?.[selectedYear],
                      ),
                    })} (${selectedYear})`}
                  </span>
                ) : null}
              </span>
            ),
            value: facitity.id,
          })) || []
        }
        sideButtons={sideButtons({
          add: () => addFacility(),
          remove: removeFacility,
          save: updateData,
          cancelChanges,
          disableSave: true,
          disableDelete: !facilityId,
          exponent: t('facilities.view.exponent') || '',
          t,
        })}
        active={facilityId}
        setActive={(facilityId) => navigate(`/facilities/${facilityId}`)}
      >
        <div className="relative fullWidth">
          {facilities.length > 0 ? (
            <div>
              {originalFacility ? (
                <>
                  <FlexboxGrid justify="space-around">
                    <FlexboxGrid.Item as={Col} colspan={8} lg={8} md={24} sm={24} xs={24}>
                      <Panel
                        header={<h5>{t('facilities.view.informationsPanelTitle')}</h5>}
                        style={{ marginBottom: 20 }}
                      >
                        <FacilityDetailsForm
                          facility={facility as Facility}
                          setFacility={setFacility}
                          setHasBlurred={setHasBlurred}
                        />
                      </Panel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item as={Col} colspan={16} lg={16} md={24} sm={24} xs={24}>
                      <Panel style={{ marginBottom: 20 }}>
                        <h5 style={{ paddingBottom: 10 }}>
                          {`${t('facilities.graph.title')}: ${facility?.name}`}
                        </h5>
                        <div className="fullWidth" style={{ height: 500 }}>
                          <FacilityRecap facility={facility} />
                        </div>
                      </Panel>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <FacilityData
                    facilityId={originalFacility.id}
                    setSelectedYear={setSelectedYear}
                    selectedYear={selectedYear}
                  />
                </>
              ) : null}
            </div>
          ) : (
            <div
              style={{ width: '100%', height: 100 }}
              className="flex center alignCenter textAlignCenter lightgrey"
            >
              {t('facilities.view.noFacility')}&nbsp;
              <a tabIndex={0} onClick={() => addFacility()} role="button">
                {t('facilities.view.createFacility')}
              </a>
            </div>
          )}
        </div>
      </TabbedContainer>

      {addFacilityModalOpen && studio ? (
        <CreateFacility
          studioId={studio?.id}
          open={addFacilityModalOpen}
          onCreate={(facility) => {
            navigate(`/facilities/${facility.id}`)
          }}
          onClose={() => {
            setAddFacilityModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}
