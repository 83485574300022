import { t } from 'i18next'
import { Schema } from 'rsuite'

const { StringType } = Schema.Types

export const signUpValidationForm = Schema.Model({
  username: StringType().isRequired(t('misc.fieldValidation.requiredField') || ''),
  password: StringType()
    .isRequired(t('misc.fieldValidation.requiredField') || '')
    .rangeLength(8, 64, t('misc.fieldValidation.passwordRangeLength') || ''),
  email: StringType()
    .isRequired(t('misc.fieldValidation.requiredField') || '')
    .isEmail(t('misc.fieldValidation.emailValidation') || ''),
})
