import { Button, Nav, NavItemProps, Popover, PopoverProps, Whisper, WhisperProps } from 'rsuite'
import { OverlayTriggerHandle } from 'rsuite/esm/Picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconType, MenuAction } from 'types'
import { Fragment, useRef } from 'react'

type MenuProps = Partial<WhisperProps> & {
  actions: (Omit<NavItemProps, 'icon'> & MenuAction)[]
  children?: JSX.Element
  icon?: IconType
  label?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

type NavItemsMenuParams = {
  menuActions: (Omit<NavItemProps, 'icon'> & MenuAction)[]
  handleClose: ((delay?: number | undefined) => void) | undefined
  menuProps?: Partial<PopoverProps> & { style?: Record<string, any> }
}

type NavItemsMenuElementParams = {
  onClose: () => void
  left?: number
  top?: number
  className?: string
}

export function navItemsMenu(params: NavItemsMenuParams) {
  return function whisperFunction(elementsParams: NavItemsMenuElementParams, ref: any) {
    const { menuActions, handleClose, menuProps } = params
    const { left, top, className } = elementsParams

    const navStyle = (style?: React.CSSProperties) => ({
      padding: '18px 14px',
      height: 30,
      cursor: 'pointer',
      ...style,
    })

    const Menu = menuProps ? (props: any) => <div {...props} /> : Fragment

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Menu {...menuProps}>
          {menuActions.map(({ key, label, onClick, icon, style, ...navItemProps }) => (
            <Nav
              key={key}
              as="span"
              className="flex row noWrap alignCenter"
              style={navStyle(style)}
              onClick={(e) => {
                handleClose?.()
                onClick?.(e)
              }}
              {...navItemProps}
            >
              {icon ? <FontAwesomeIcon icon={icon} className="marginRight10" /> : null} {label}
            </Nav>
          ))}
        </Menu>
      </Popover>
    )
  }
}

export function Menu(props: MenuProps) {
  const { children, actions, icon, label, style, className, ...whisperProps } = props

  const whisperRef = useRef<OverlayTriggerHandle | null>(null)

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="click"
      speaker={navItemsMenu({
        menuActions: actions,
        handleClose: whisperRef.current?.close,
      })}
      {...whisperProps}
      ref={(ref) => {
        whisperRef.current = ref
      }}
    >
      {children || (
        <Button appearance="subtle" style={style} className={className}>
          {icon || !label ? (
            <FontAwesomeIcon
              icon={icon || 'ellipsis'}
              className={label ? 'marginRight5' : undefined}
            />
          ) : null}
          {label}
        </Button>
      )}
    </Whisper>
  )
}
