/* eslint-disable react/no-unstable-nested-components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components'
import { sortBy } from 'lodash'
import { ComponentProps, JSXElementConstructor, ReactNode, useMemo, useState } from 'react'
import {
  Table as RSTable,
  TableProps as RSTableProps,
  ColumnProps as RSColumnProps,
  CellProps as RSCellProps,
} from 'rsuite'
import { useRole } from 'hooks/useRole'
import { userRoles } from 'services/constants/userRoles'
import { Cell } from './Cell'

interface RowDataType {
  dataKey?: string
  children?: RowDataType[]
  [key: string]: any
}

export type EditableComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>

export type EditableCell = {
  EditComponent?: EditableComponentType
  disableCellEdit?: (rowData: any) => boolean
  editComponentProps?: (
    value: any,
    setValue: (value: any) => void,
    endEdit: () => void,
    getCellInformations: () => { height: number },
  ) => ComponentProps<EditableComponentType>
}

export type Column<RowType> = {
  name: string
  dataKey?: RSCellProps['dataKey']
  tooltip?: string
  columnProps?: Partial<RSColumnProps>
  cellProps?: Partial<RSCellProps>
  cellContent?: (rowData: RowType, rowIndex: number | undefined) => ReactNode
  role?: keyof typeof userRoles
  treeAccepter?: (rowData: RowDataType) => boolean
} & EditableCell

type TableProps<RowType> = RSTableProps<any, any> & {
  columns: Column<RowType>[]
  onUpdateRow?: (rowData: RowType) => Promise<any>
  tableRole?: keyof typeof userRoles
}

export function Table<RowType>(props: TableProps<RowType>) {
  const { data = [], columns, onUpdateRow, tableRole = 'editor', ...tableProps } = props

  const [[sortKey, sortType], setSorting] = useState<any[]>([])
  const [currentEditingCell, setCurrentEditingCell] = useState<string | void>()

  const { authorized, testRole } = useRole({ requiredRole: tableRole })

  const sortedData = useMemo<readonly any[]>(() => {
    if (!sortKey) return data
    const sortedData = sortBy(data, [sortKey]) as any[]
    if (sortType === 'asc') return sortedData.reverse()
    return sortedData
  }, [data, sortKey, sortType])

  function onSortColumn(tableDataKey: string, tableSortType?: string) {
    setSorting([tableDataKey, tableSortType])
  }

  function getEditableCell(column: Column<RowType>) {
    return (rowData: RowDataType, rowIndex: number | undefined) => {
      if (column.treeAccepter && !column.treeAccepter(rowData)) return null

      if (typeof rowIndex === 'number' && authorized) {
        return (
          <Cell<RowType>
            disableCellEdit={column.disableCellEdit}
            column={column}
            rowData={rowData}
            rowIndex={rowIndex}
            setCurrentEditingCell={setCurrentEditingCell}
            currentEditingCell={currentEditingCell}
            onValidate={onUpdateRow ? (rowData) => onUpdateRow(rowData) : undefined}
          />
        )
      }
      return column?.cellContent?.(rowData as RowType, rowIndex)
    }
  }

  return (
    <RSTable
      data={sortedData}
      onSortColumn={onSortColumn}
      sortColumn={sortKey}
      sortType={sortType}
      bordered
      cellBordered
      {...tableProps}
    >
      {columns.map((column: Column<RowType>) => {
        const { name, dataKey, columnProps, cellProps, tooltip, role: columnRole } = column

        if (!testRole(columnRole)) return null

        return (
          <RSTable.Column key={name} sortable verticalAlign="middle" {...columnProps}>
            <RSTable.HeaderCell>
              <span>{name}</span>
              <Tooltip title={tooltip}>
                <FontAwesomeIcon className="marginLeft5 fontSize10" icon="circle-info" />
              </Tooltip>
            </RSTable.HeaderCell>
            <RSTable.Cell dataKey={dataKey as any} {...cellProps}>
              {getEditableCell(column)}
            </RSTable.Cell>
          </RSTable.Column>
        )
      })}
    </RSTable>
  )
}
