type IconProps = {
  height?: string
  width?: string
  color?: string
  className?: string
  style?: {}
}

export const SystemUnitIcon = ({ height, width, color, className, style }: IconProps) => (
  <svg
    className={className}
    style={style}
    fill={color ?? 'currentColor'}
    height={height ?? '16px'}
    width={width ?? '12px'}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="40 0 225 255"
  >
    <path
      d="M227.5,20c0-11.046-8.954-20-20-20h-130c-11.046,0-20,8.954-20,20v245c0,11.046,8.954,20,20,20h130c11.046,0,20-8.954,20-20
        V20z M201.5,249h-118v-25h118V249z M134.348,129.52c0-7.048,5.714-12.762,12.762-12.762c7.048,0,12.762,5.714,12.762,12.762
        c0,7.048-5.714,12.762-12.762,12.762C140.062,142.282,134.348,136.568,134.348,129.52z M173.336,129.52
        c0-7.048,5.714-12.762,12.762-12.762c7.048,0,12.762,5.714,12.762,12.762c0,7.048-5.714,12.762-12.762,12.762
        C179.049,142.282,173.336,136.568,173.336,129.52z M201.5,94h-118V68h118V94z M201.5,48h-118V22h118V48z"
    />
  </svg>
)
