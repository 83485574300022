import { createApi } from '@reduxjs/toolkit/query/react'
import { Commutation, CommutationValue, FacilityData } from 'types'
import { createCRUDEndpoints } from './createCRUDEndpoints'
import { baseQueryWithReauth } from '../baseQuery'
import { transformRequest, transformResponse } from '../utils'

type CommutationValueParams = {
  commutationId: Commutation['id']
  facilityDataId: FacilityData['id']
  commutationValues: Partial<CommutationValue>[]
}

const commutationsApi = createApi({
  reducerPath: 'commutations',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Commutations'],
  endpoints: (build) => ({
    ...(() => {
      const endpoints = createCRUDEndpoints<typeof build, Commutation>(
        build,
        'commutations',
        'Commutations',
      )
      return {
        createCommutation: endpoints.create,
        readCommutation: endpoints.read,
        readAllCommutation: endpoints.readAll,
        updateCommutation: endpoints.update,
        deleteCommutation: endpoints.delete,
      }
    })(),
    addCommutationValues: build.mutation<CommutationValue[], { data: CommutationValueParams }>({
      async queryFn(args, localApi, extraOptions, baseQuery): Promise<any> {
        const { commutationValues } = args.data

        const newCommutationValues = await Promise.all(
          commutationValues?.map((dv) =>
            Promise.resolve(
              baseQuery({
                url: '/commutationValues/',
                method: 'POST',
                body: transformRequest(dv),
              }),
            ).then(({ data }) => transformResponse<CommutationValue>(data as CommutationValue)),
          ) || [],
        )

        return {
          data: newCommutationValues,
        }
      },
      invalidatesTags: [{ type: 'Commutations', id: 'LIST' }],
    }),
    deleteAllCommutations: build.mutation({
      async queryFn(args, localApi, extraOptions, baseQuery): Promise<any> {
        const { facilityDataId } = args.data
        await baseQuery({
          url: `/facilityDatas/${facilityDataId}/deleteAllCommutation/`,
          method: 'GET',
        })
        return {
          data: [],
        }
      },
      invalidatesTags: [{ type: 'Commutations', id: 'LIST' }],
    }),
  }),
})

export { commutationsApi }
