import enFlag from 'assets/flags/en.svg'
import frFlag from 'assets/flags/fr.svg'
import esFlag from 'assets/flags/es.svg'
import itFlag from 'assets/flags/it.svg'
import deFlag from 'assets/flags/de.svg'
import { map } from 'lodash'
import { Button, Nav } from 'rsuite'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import classes from './LangagePicker.module.scss'

const langagesOptions = {
  en: {
    label: 'English',
    flag: enFlag,
    value: 'en',
  },
  fr: {
    label: 'Français',
    flag: frFlag,
    value: 'fr',
  },
  es: {
    label: 'Español',
    flag: esFlag,
    value: 'es',
  },
  it: {
    label: 'Italiano',
    flag: itFlag,
    value: 'it',
  },
  de: {
    label: 'Deutsch',
    flag: deFlag,
    value: 'de',
  },
}

export function LangagePicker() {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState<string>(i18next.language || window.localStorage.i18nextLng)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const menus: { label: JSX.Element; value: string }[] = useMemo(
    () =>
      map(langagesOptions, (option) => ({
        label: (
          <div className={classes.menuFlag}>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={option.flag} alt={option.value} />
            </div>
          </div>
        ),
        value: option.value,
      })),
    [],
  )

  return (
    <Nav.Menu
      icon={
        <Button style={{ padding: 0 }} appearance="primary">
          {menus.find((e) => e.value === lang)?.label}
        </Button>
      }
    >
      {menus.map((item) => (
        <Nav.Item
          key={item.value}
          eventKey={item.value}
          onSelect={(lang) => {
            if (lang) setLang(lang)
          }}
        >
          {item.label}
        </Nav.Item>
      ))}
    </Nav.Menu>
  )
}
