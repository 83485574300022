import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalProps as RSModalProps, Modal as RSModal, Button, ButtonProps, Loader } from 'rsuite'
import { ComputerRigData } from 'views/ComputerRigs/ComputerRigs'

export type ModalFormValidator = (() => boolean | Promise<boolean>) | undefined | null

export type ModalProps = Omit<RSModalProps, 'children'> & {
  children:
    | React.ReactNode
    | ((assignForm: (formValidator: ModalFormValidator) => void) => React.ReactNode)
  title?: React.ReactNode
  onValidate?: () => Promise<any>
  onValidateAndContinue?: () => Promise<any>
  onCancel?: () => Promise<any>
  onClose?: () => void
  hideCancel?: boolean
  hideValidate?: boolean
  disabledCancel?: boolean
  disabledValidate?: boolean
  cancelLabel?: React.ReactNode
  validateLabel?: React.ReactNode
  validateAndContinueLabel?: React.ReactNode
  cancelColor?: ButtonProps['color']
  validateColor?: ButtonProps['color']
  buttons?: React.ReactNode
  rigData?: ComputerRigData
}

export function Modal(props: ModalProps) {
  const {
    children,
    title,
    onValidate,
    onValidateAndContinue,
    onCancel,
    hideCancel,
    hideValidate,
    disabledCancel,
    disabledValidate,
    cancelLabel,
    validateLabel,
    validateAndContinueLabel,
    cancelColor,
    validateColor,
    buttons,
    rigData,
    ...modalProps
  } = props
  const { t } = useTranslation()
  const formRef = useRef<ModalFormValidator>(null)

  const [isLoading, setIsLoading] = useState(false)

  function handleValidation(): Promise<any> {
    const formCheck = formRef.current
    if (formCheck) {
      if (!formCheck()) return Promise.resolve()
      if (onValidate) return onValidate()
      if (onValidateAndContinue) return onValidateAndContinue()
      return Promise.resolve()
    }
    if (onValidate) return onValidate()
    return Promise.resolve()
  }

  function onLoading(func?: () => Promise<any>) {
    return function resolveFunction() {
      if (func) {
        setIsLoading(true)
        func().finally(() => setIsLoading(false))
      }
    }
  }

  return (
    <RSModal backdrop="static" autoFocus enforceFocus {...modalProps}>
      {title ? (
        <RSModal.Header>
          <RSModal.Title>
            <div className="bold">{title}</div>
          </RSModal.Title>
        </RSModal.Header>
      ) : null}
      <RSModal.Body>
        {typeof children === 'function'
          ? children((formValidator) => {
              formRef.current = formValidator
            })
          : children}
        <div className="marginTop20 marginBottom10">{rigData?.__ItemsNumber}</div>
      </RSModal.Body>
      <RSModal.Footer>
        {buttons}
        {!hideValidate ? (
          <Button
            onClick={onLoading(handleValidation)}
            appearance="primary"
            disabled={isLoading || disabledValidate}
            color={validateColor}
          >
            <div className="flex row center alignCenter">
              {isLoading ? <Loader speed="slow" className="marginRight5" /> : null}
              {validateLabel || t('components.modals.ok')}
            </div>
          </Button>
        ) : null}
        {onValidateAndContinue ? (
          <Button
            onClick={onLoading(onValidateAndContinue)}
            appearance="primary"
            disabled={isLoading || disabledValidate}
            color={validateColor}
          >
            <div className="flex row center alignCenter">
              {isLoading ? <Loader speed="slow" className="marginRight5" /> : null}
              {validateAndContinueLabel || t('components.modals.ok')}
            </div>
          </Button>
        ) : null}
        {!hideCancel ? (
          <Button
            onClick={onLoading(onCancel)}
            appearance="subtle"
            disabled={isLoading || disabledCancel}
            color={cancelColor}
          >
            {cancelLabel || t('components.modals.cancel')}
          </Button>
        ) : null}
      </RSModal.Footer>
    </RSModal>
  )
}
