import { Modal } from 'components'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { DietProfile } from 'types'
import { DietProfileForm } from './DietProfileForm'

type ModalAddDietProfileProps = ButtonProps

export function ButtonAddDietProfile(props: ModalAddDietProfileProps) {
  const { t } = useTranslation()
  const [dietProfile, setDietProfile] = useState<Partial<DietProfile> | null>(null)
  const [modalDietProfileIsOpen, setModalDietProfileIsOpen] = useState<boolean>(false)
  const [createDietProfile] = api.dietProfiles.create()

  async function addDietProfile() {
    if (dietProfile) await createDietProfile({ data: dietProfile })
    setModalDietProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalDietProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <DoubleIcon icons={['burger', 'circle-info']} className="marginRight10" />{' '}
        {t('diet.settings.dietProfiles.addButton')}
      </Button>
      <Modal
        open={modalDietProfileIsOpen}
        onClose={() => setModalDietProfileIsOpen(false)}
        onExited={() => setDietProfile(null)}
        onValidate={addDietProfile}
        onCancel={() => Promise.resolve(setModalDietProfileIsOpen(false))}
        title={t('diet.settings.dietProfiles.addButton')}
      >
        <DietProfileForm dietProfile={dietProfile} setDietProfile={setDietProfile} />
      </Modal>
    </>
  )
}
