import { Home } from 'pages/Home/Home'
import { Route, Routes } from 'react-router-dom'

export function MainRoutes() {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
    </Routes>
  )
}
