import { Gear } from '@rsuite/icons'
import { LangagePicker } from 'components/LangagePicker/LangagePicker'
import { useSelector } from 'hooks/store'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Nav, Navbar as RSNavbar, Button, Whisper } from 'rsuite'
import { getToken } from 'services/authentication/authReducer'
import { AppTheme } from 'types'
import { appSettingsMenu } from '../AppSettingsMenu'
import { HeaderMenu } from './HeaderMenu'
import { HeaderStudio } from './HeaderStudio'

type Props = {
  setTheme: (theme: AppTheme) => void
  theme: AppTheme
}

export function Navbar(props: Props) {
  const { setTheme: _setTheme, theme } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  function setTheme(theme: AppTheme) {
    localStorage.setItem('appTheme', theme)
    _setTheme(theme)
  }

  function goToLogin() {
    navigate('/login')
  }

  function goToSignUp() {
    navigate('/signup')
  }

  function goToHome() {
    navigate('/')
  }

  const auth = useSelector(getToken)

  return (
    <RSNavbar appearance="inverse" style={{ maxHeight: 56 }}>
      <div>
        <RSNavbar.Brand
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '28px',
          }}
          onClick={goToHome}
        >
          <div
            style={{
              backgroundColor: 'var(--rs-body)',
              width: 30,
              height: 30,
              borderRadius: '50%',
              marginRight: 10,
              display: 'flex',
            }}
          >
            <img src="/favicon.ico" alt="icon" style={{ maxHeight: '100%' }} />
          </div>
          &nbsp;
          {t('appName')}
        </RSNavbar.Brand>
      </div>
      <div style={{ height: '56px', display: 'flex', alignItems: 'center' }}>
        {auth.authenticated ? <HeaderMenu /> : null}

        <Nav style={{ marginLeft: 'auto', height: '56px', display: 'flex', alignItems: 'center' }}>
          {auth.authenticated ? (
            <div style={{ height: '56px', display: 'flex' }}>
              <HeaderStudio />
            </div>
          ) : (
            <>
              <Button appearance="primary" className="marginRight10 bold" onClick={goToLogin}>
                {t('navbar.buttons.login')}
              </Button>
              <Button
                appearance="primary"
                color="green"
                className="marginRight10 bold"
                onClick={goToSignUp}
              >
                {t('navbar.buttons.creatAcc')}
              </Button>
            </>
          )}

          <Whisper
            placement="autoVerticalEnd"
            trigger="click"
            speaker={appSettingsMenu({ theme, setTheme })}
          >
            <Button appearance="primary" style={{ margin: '0px 10px' }}>
              <Gear />
            </Button>
          </Whisper>
          <LangagePicker />
        </Nav>
      </div>
    </RSNavbar>
  )
}
