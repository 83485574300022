import { Modal, TabbedContainer } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'services/api'
import { dataTypes } from 'services/constants/dataTypes'
import {
  DataType,
  DepartmentGroup,
  DepartmentGroupData as DepartmentGroupDataType,
  ID,
  Option,
} from 'types'
import { useAutoSave } from 'hooks/useAutoSave'
import { Button } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openModal } from 'components/Modal/openModal'
import { useComputedValue } from 'hooks/useComputedValue'
import { useRole } from 'hooks/useRole'
import { DepartmentGroupDataForm } from './DepartmentGroupDataForm'

type DepartmentGroupDataProps = {
  departmentGroupId: DepartmentGroup['id']
  departmentGroupType: DepartmentGroup['groupType']
}

export function DepartmentGroupData(props: DepartmentGroupDataProps) {
  const { t } = useTranslation()
  const { departmentGroupId, departmentGroupType } = props
  const { authorized } = useRole({ requiredRole: 'editor' })

  const { data: departmentGroupDatas = [] } = api.departmentGroupDatas.readAll({
    filters: { departmentGroup: departmentGroupId },
  })

  const [createDepartmentGroupData] = api.departmentGroupDatas.create()
  const [updateDepartmentGroupData] = api.departmentGroupDatas.update()
  const [deleteDepartmentGroupData] = api.departmentGroupDatas.delete()

  const [departmentGroupData, setDepartmentGroupData] =
    useState<Partial<DepartmentGroupDataType> | null>(null)
  const [selectedDataType, setSelectedDataType] = useState<DataType>(0)
  const [modalDepartmentGroupDataIsOpen, setModalDepartmentGroupDataIsOpen] =
    useState<boolean>(false)

  const { previz, real } = useMemo(
    () => ({
      previz: departmentGroupDatas.find((dgd) => dgd.dataType === 0),
      real: departmentGroupDatas.find((dgd) => dgd.dataType === 1),
    }),
    [departmentGroupDatas],
  )

  const { computedValue, refetch } = useComputedValue<'departmentGroups'>({
    type: 'departmentGroups',
    skip: !departmentGroupData?.departmentGroup,
  })

  function formatDetailsUnitValue(status: any) {
    if (!departmentGroupData?.departmentGroup) return null
    let value =
      status === 'Previz'
        ? computedValue?.[departmentGroupData?.departmentGroup]?.previz
        : computedValue?.[departmentGroupData?.departmentGroup]?.reel
    if (typeof value !== 'number') return null

    if (value > 10) value = Math.round(value)
    return (
      <span className="salmon normal" style={{ fontSize: '14px' }}>
        {t('misc.units.valueKgEqCo2', {
          value: new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value),
        })}
      </span>
    )
  }

  const DepartmentGroupDataTabs = useMemo<Option[]>(
    () =>
      dataTypes.map((dgd, index) => ({
        label: (
          <div className="fontSize12">
            <span style={{ marginRight: !computedValue ? 0 : 10 }}>
              {t(`departmentGroupDatas.view.title${dgd}`)}
            </span>
            {formatDetailsUnitValue(dgd)}
          </div>
        ),
        value: index.toString(),
      })),
    [previz, real, computedValue, selectedDataType],
  )

  const originalDepartmentGroupData = useMemo(() => {
    const departmentGroupData =
      departmentGroupDatas.find((p) => p.dataType === selectedDataType) || null
    return departmentGroupData
  }, [departmentGroupDatas, selectedDataType])

  const { dataHasChange, setHasBlurred } = useAutoSave({
    originalValue: originalDepartmentGroupData,
    diffValue: departmentGroupData,
    updateComputedData: () => {
      refetch()
    },
    save: () => {
      if (departmentGroupData?.id) updateDepartmentGroupData({ data: departmentGroupData })
    },
    timeout: 15,
  })

  function onDepartmentGroupDataTabChange(option?: Option) {
    if (!option) return

    setSelectedDataType(Number(option.value) as DataType)
  }

  function openDepartmentGroupDataModal(departmentGroup?: Partial<DepartmentGroup>) {
    setDepartmentGroupData(departmentGroup || null)
    setModalDepartmentGroupDataIsOpen(true)
  }

  function closeDepartmentGroupDataModal() {
    setModalDepartmentGroupDataIsOpen(false)
  }

  async function saveDepartmentGroupData() {
    if (!departmentGroupData) return Promise.reject()

    if (departmentGroupData.id) {
      return updateDepartmentGroupData({ data: departmentGroupData })
        .unwrap()
        .then(closeDepartmentGroupDataModal)
        .catch((error) => {
          console.error(error)
        })
    }

    return createDepartmentGroupData({
      data: {
        ...departmentGroupData,
        dataType: selectedDataType,
        departmentGroup: departmentGroupId,
      },
    })
      .unwrap()
      .then(closeDepartmentGroupDataModal)
      .catch((error) => {
        console.error(error)
      })
  }

  function deleteDepartementGroupData() {
    if (!departmentGroupData) return
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.theseValues'),
          })}
        </span>
      ),
      onValidate: () =>
        deleteDepartmentGroupData(departmentGroupData.id as ID)
          .unwrap()
          .then(closeDepartmentGroupDataModal),
    })
  }

  async function copyData() {
    const { computerRig, departmentGroup, endDate, scheduleType, startDate, personDays } =
      (selectedDataType === 0 ? real : previz) || {}
    const formData = {
      computerRig,
      departmentGroup,
      endDate,
      scheduleType,
      startDate,
      personDays,
    }
    return createDepartmentGroupData({
      data: {
        ...formData,
        dataType: selectedDataType,
      },
    })
      .unwrap()
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (selectedDataType === 0) setDepartmentGroupData(previz || null)
    else setDepartmentGroupData(real || null)
  }, [previz, real, selectedDataType, departmentGroupDatas])

  return (
    <div className="flex column fullWidth relative">
      {!originalDepartmentGroupData || !authorized ? null : (
        <div style={{ position: 'absolute', top: 40, right: 0, zIndex: 1 }}>
          <Button
            onClick={() => deleteDepartementGroupData()}
            disabled={!originalDepartmentGroupData}
            appearance="subtle"
          >
            <FontAwesomeIcon icon="trash" />
          </Button>
          {!originalDepartmentGroupData || !dataHasChange ? null : (
            <Button appearance="subtle" color="green" onClick={() => saveDepartmentGroupData()}>
              <FontAwesomeIcon icon="floppy-disk" />
            </Button>
          )}
        </div>
      )}
      <TabbedContainer
        style={{ width: 'auto', margin: 0, marginTop: 40, padding: 0 }}
        onTabChange={onDepartmentGroupDataTabChange}
        options={DepartmentGroupDataTabs}
        active={selectedDataType.toString()}
      >
        {!originalDepartmentGroupData ? (
          <div
            style={{ width: '100%', height: 100 }}
            className="flex center alignCenter textAlignCenter lightgrey"
          >
            {t(`departmentGroupDatas.view.${selectedDataType === 0 ? 'noPreviz' : 'noReal'}`)}
            &nbsp;
            <a tabIndex={0} onClick={() => openDepartmentGroupDataModal()} role="button">
              {t('departmentGroupDatas.view.createDepartmentGroup')}
            </a>
            {(selectedDataType === 0 && !real) || (selectedDataType === 1 && !previz) ? null : (
              <>
                &nbsp;
                <span>{t('misc.conjunctions.or')}</span>
                &nbsp;
                <a tabIndex={0} onClick={() => copyData()} role="button">
                  {t(
                    `departmentGroupDatas.view.${
                      selectedDataType === 0 ? 'copyRealData' : 'copyPrevizData'
                    }`,
                  )}
                </a>
              </>
            )}
          </div>
        ) : (
          <DepartmentGroupDataForm
            departmentGroupData={departmentGroupData}
            setDepartmentGroupData={setDepartmentGroupData}
            departmentGroupType={departmentGroupType}
            setHasBlurred={setHasBlurred}
          />
        )}
      </TabbedContainer>
      <Modal
        open={modalDepartmentGroupDataIsOpen}
        onClose={closeDepartmentGroupDataModal}
        onValidate={saveDepartmentGroupData}
        onCancel={() => Promise.resolve(closeDepartmentGroupDataModal())}
        title={t('departmentGroupDatas.view.createModalTitle')}
      >
        <DepartmentGroupDataForm
          departmentGroupData={departmentGroupData}
          setDepartmentGroupData={setDepartmentGroupData}
          departmentGroupType={departmentGroupType}
          setHasBlurred={setHasBlurred}
        />
      </Modal>
    </div>
  )
}
