import { useState } from 'react'
import { Container, Content, Header, Footer, CustomProvider as RSuiteProvider } from 'rsuite'
import { AppTheme } from 'types'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getToken } from 'services/authentication/authReducer'
import { useFormFieldInformations } from 'hooks/useFormFieldInformations'
import de from 'rsuite/locales/de_DE'
import en from 'rsuite/locales/en_US'
import es from 'rsuite/locales/es_ES'
import it from 'rsuite/locales/it_IT'
import Banner from 'components/Banner/Banner'
import fr from 'rsuite/locales/fr_FR'
import i18next from 'i18next'

import { useComputedValue } from 'hooks/useComputedValue'
import { Navbar } from './Navbar/Navbar'
import { Authenticated } from './Authenticated'
import classes from './Home.module.css'

const rsuiteLocales: Record<string, any> = { de, en, es, it, fr }

export function Home() {
  const [theme, setTheme] = useState<AppTheme>('light')
  const { t } = useTranslation()
  const auth = useSelector(getToken)
  const { errors } = useComputedValue()

  useFormFieldInformations()

  return (
    <RSuiteProvider theme={theme} locale={rsuiteLocales[i18next.language]}>
      <Container style={{ minHeight: '100vh' }}>
        <Header className={classes.header}>
          {errors?.length ? <Banner errors={errors} /> : null}
          <Navbar setTheme={setTheme} theme={theme} />
        </Header>
        <Content style={{ marginTop: errors?.length ? '132px' : '56px' }} className={classes.root}>
          <div className={classes.content}>
            <Authenticated />
          </div>
        </Content>
        <Footer className={classes.footer}>
          <div />
          <span>{t('footer.info')}</span>
          {auth.authenticated ? (
            <Link style={{ padding: '0px 20px' }} to="/algo">
              {t('footer.algo')}
            </Link>
          ) : (
            <div />
          )}
        </Footer>
      </Container>
    </RSuiteProvider>
  )
}
