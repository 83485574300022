import { Container } from 'components'
import { notifications } from 'components/Notifications/Notifications'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, FlexboxGrid, Loader, Panel } from 'rsuite'
import api from 'services/api'
import { Studio } from 'types'
import { useTranslation } from 'react-i18next'
import { StudioUsers } from 'views/Users/UsersTable/StudioUsersTable'
import { ComputerRigs } from 'views/ComputerRigs/ComputerRigs'
import { StudioCarbonCost } from 'components/Charts/StudioCarbonCost/StudioCarbonCost'
import { ProductionsReport } from 'components/Charts/ProductionsReport/ProductionsReport'
import { useComputedValue } from 'hooks/useComputedValue'
import { FacilitiesReport } from 'components/Charts/FacilitiesReport/FacilitiesReport'
import { FacilityHardwares } from 'views/FacilityHardwares/FacilityHardwares'
import { setUser } from 'services/authentication/authReducer'
import classes from './Studios.module.scss'
import { StudioForm } from './StudioForm'

export function Studios() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: user } = api.me.readMe()

  const { data: studios = [], isLoading } = api.studios.readAll()
  const { data: facilities = [], isLoading: facilitiesIsLoading } = api.facilities.readAll()
  const { data: facilitiesDatas = [], isLoading: facilityDatasIsLoading } =
    api.facilityDatas.readAll()
  const [createStudio, { isLoading: studioCreationLoading }] = api.studios.create()
  const { computedValue } = useComputedValue<'facilities'>({
    type: 'facilities',
    skip: false,
  })
  const { data: productions = [] } = api.productions.readAll()

  const originalStudio = useMemo<Studio | null>(() => studios[0] || null, [studios])

  const [studio, setStudio] = useState<Partial<Studio> | void>()

  async function addStudio() {
    try {
      const s = await createStudio({ data: studio as Partial<Studio> }).unwrap()
      if (s && user) {
        await setUser(user)
        navigate('/studio')
      } else notifications.error(t('studio.errors.studioCreation') || '')
    } catch (error) {
      notifications.error(t('studio.errors.studioCreation') || '')
      console.error(error)
    }
  }

  function isLoadingView() {
    return (
      <div className="fullWidth flex center alignCenter" style={{ minHeight: '90vh' }}>
        <span className="flex center alignCenter grey">
          <Loader className="marginRight8" />
          {t('studio.view.loadingStudio')}
        </span>
      </div>
    )
  }

  function displayAddStudioButton() {
    return (
      <div className="fullWidth fullHeight flex center alignCenter">
        <div className={classes.panelContainer}>
          <Panel header={t('studio.view.formTitle')} shaded>
            <StudioForm studio={studio} setStudio={setStudio} />
            <div className="flex end marginTop20">
              <Button appearance="primary" disabled={studioCreationLoading} onClick={addStudio}>
                {t('studio.view.formValidation')}
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    )
  }

  return !originalStudio ? (
    displayAddStudioButton()
  ) : (
    <Container style={{ width: 'calc(100% - 120px)' }}>
      {isLoading || facilitiesIsLoading || facilityDatasIsLoading ? (
        isLoadingView()
      ) : (
        <FlexboxGrid justify="space-around">
          <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
            <Panel shaded className={classes.panel}>
              <h5 className={classes.panelTitle}>{t('studio.view.globalReportTitle')}</h5>
              <div style={{ height: 250, width: '100%' }}>
                <StudioCarbonCost />
              </div>
            </Panel>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
            <Panel shaded className={classes.panel}>
              <h5 className={classes.panelTitle}>{t('studio.view.facilityReportTitle')}</h5>
              <div style={{ height: 250, width: '100%' }}>
                <FacilitiesReport
                  studioFacilities={facilities}
                  facilitiesDatas={facilitiesDatas}
                  computedValue={computedValue}
                />
              </div>
            </Panel>
          </FlexboxGrid.Item>
          {productions && (
            <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
              <Panel shaded className={classes.panel}>
                <h5 className={classes.panelTitle}>{t('studio.view.productionReportTitle')}</h5>
                <div style={{ height: 250, width: '100%' }}>
                  <ProductionsReport productions={productions} />
                </div>
              </Panel>
            </FlexboxGrid.Item>
          )}

          <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
            <Panel shaded className={classes.panel}>
              <h5 className={classes.panelTitle}>{t('studio.view.hardwareInventoryTitle')}</h5>
              <FacilityHardwares />
            </Panel>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
            <Panel shaded className={classes.panel}>
              <h5 className={classes.panelTitle}>{t('studio.view.computersRigTable')}</h5>
              <span className={classes.textInfo}>{t('studio.view.computersRigTableInfoText')}</span>
              <ComputerRigs />
            </Panel>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={10} lg={24} md={24} sm={24} xs={24}>
            <Panel shaded className={classes.panel}>
              <h5 className={classes.panelTitle}>{t('studio.view.studioUsersTable')}</h5>
              <span className={classes.textInfo}>{t('studio.view.studioUsersTableInfoText')}</span>
              <StudioUsers studioId={originalStudio.id} />
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </Container>
  )
}
