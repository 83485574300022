import { useEffect, useRef, useState } from 'react'
import { Button, Form, FormInstance, Panel } from 'rsuite'
import { InputCustom } from 'components'
import api from 'services/api'
import { Description } from 'components/Description/Descrption'
import { useTranslation } from 'react-i18next'
import classes from './ForgotPassword.module.css'
import { forgotPasswordValidationForm } from './forgotPasswordValidationForm'

type ForgotPasswordType = {
  email: string
}

export function ForgotPassword() {
  const { t } = useTranslation()
  const [resetPassword, { isLoading }] = api.me.resetPassword()

  const [forgotPassword, setForgotPassword] = useState<Partial<ForgotPasswordType>>({
    email: '',
  })
  const [resetStepPassed, setResetStepPassed] = useState<boolean>(false)
  const [canResend, setCanResend] = useState<boolean>(true)
  const formRef = useRef<FormInstance>()
  const resendTimer = useRef<NodeJS.Timeout | null>(null)

  function handleForgotPassword() {
    if (formRef.current?.check()) {
      resetPassword(forgotPassword as ForgotPasswordType).then(() => {
        setResetStepPassed(true)
      })
    }
  }

  function resendEmail() {
    resetPassword(forgotPassword as ForgotPasswordType)
    setCanResend(false)
    resendTimer.current = setTimeout(() => setCanResend(true), 1000 * 30)
  }

  useEffect(
    () => () => {
      if (resendTimer.current !== null) clearTimeout(resendTimer.current)
    },
    [],
  )

  return (
    <div className="fullWidth fullHeight flex center alignCenter">
      <div className={classes.container}>
        <Panel header={t('forgotPassword.title')} className="rs-panel-large-header" shaded>
          <Description color={!resetStepPassed ? 'grey' : undefined}>
            {!resetStepPassed ? (
              t('forgotPassword.helpers.requirement')
            ) : (
              <span>
                <p>
                  <span>
                    {t('forgotPassword.helpers.passwordAsked')}&nbsp;
                    <span className="bold">{forgotPassword.email}</span>.
                    {t('forgotPassword.helpers.passwordAsked2')}
                  </span>
                </p>
                <br />
                <p>
                  {t('forgotPassword.helpers.cantFindMail')}&nbsp;
                  <a href="/signup">{t('forgotPassword.helpers.createAccLink')}</a>.
                </p>
                <div className="fullWidth flex column alignEnd marginTop30">
                  <Button
                    appearance="link"
                    onClick={resendEmail}
                    disabled={isLoading || !canResend}
                    style={{ padding: 0 }}
                  >
                    {t('forgotPassword.helpers.sendMailAgainLink')}
                  </Button>
                  <a href="/login">{t('forgotPassword.backLoginPage')}</a>
                </div>
              </span>
            )}
          </Description>
          {!resetStepPassed ? (
            <Form
              fluid
              ref={(ref: FormInstance) => {
                formRef.current = ref
              }}
              onChange={setForgotPassword}
              onSubmit={handleForgotPassword}
              formValue={forgotPassword}
              model={forgotPasswordValidationForm}
            >
              <Form.Group>
                <Form.ControlLabel>{t('forgotPassword.email')}</Form.ControlLabel>
                <Form.Control name="email" type="email" accepter={InputCustom} />
              </Form.Group>

              <div className="flex end marginTop20">
                <Button appearance="primary" type="submit" disabled={isLoading}>
                  {t('forgotPassword.sendButton')}
                </Button>
              </div>
              <div className="flex fullWidth end paddingTop20">
                <a href="/login">{t('forgotPassword.backLoginPage')}</a>
              </div>
            </Form>
          ) : null}
        </Panel>
      </div>
    </div>
  )
}
