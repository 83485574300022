import { useSelector } from 'hooks/store'
import { getToken } from 'services/authentication/authReducer'
import { AuthenticatedRoutes } from 'routes/AuthenticatedRoutes'
import { UnauthenticatedRoutes } from 'routes/UnauthenticatedRoutes'

export function Authenticated() {
  const auth = useSelector(getToken)

  if (auth.authenticated) return <AuthenticatedRoutes />

  return <UnauthenticatedRoutes />
}
