import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import classes from './HeaderMenu.module.scss'

export function HeaderMenu() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const { data: studios = [], isLoading } = api.studios.readAll()

  function getRouteProps(
    route: 'studio' | 'facilities' | 'productions' | 'wiki',
  ): Partial<ButtonProps> {
    const active = location.pathname.startsWith(`/${route}`)
    const onClick = () => navigate(`/${route}`)
    return { active, onClick }
  }
  if (isLoading || !studios.length) return null
  return (
    <div className="flex spaceAround alignCenter marginLeft50">
      <div>
        <Button appearance="primary" className={classes.button} {...getRouteProps('studio')}>
          {t('navbar.menus.studio')}
        </Button>
        <Button appearance="primary" className={classes.button} {...getRouteProps('facilities')}>
          {t('navbar.menus.facilities')}
        </Button>
        <Button appearance="primary" className={classes.button} {...getRouteProps('productions')}>
          {t('navbar.menus.productions')}
        </Button>
        <Button appearance="primary" className={classes.button} {...getRouteProps('wiki')}>
          {t('navbar.menus.wiki')}
        </Button>
      </div>
    </div>
  )
}
