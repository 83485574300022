import { t } from 'i18next'
import { Schema } from 'rsuite'

const { StringType } = Schema.Types

export const newPasswordValidationForm = Schema.Model({
  password: StringType()
    .isRequired(t('misc.fieldValidation.requiredField') || '')
    .rangeLength(8, 64, t('misc.fieldValidation.passwordRangeLength') || ''),
  confirmPassword: StringType().addRule(
    (value, data) => value === data.password,
    t('misc.fieldValidation.identicalPasswords') || '',
  ),
})
