import { useState } from 'react'
import { Button, SelectPicker } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom, Modal } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import api from 'services/api'
import { CountryProfile } from 'types'
import { ButtonAddCountryProfile } from 'views/CountryProfiles/ButtonAddCountryProfile'
import { CountryProfileForm } from 'views/CountryProfiles/CountryProfileForm'
import { useTranslation } from 'react-i18next'
import { countries } from 'constants/countries'

export function CountryProfiles() {
  const { t } = useTranslation()

  const [deleteCountryProfile] = api.countryProfiles.delete()
  const [updateCountryProfile] = api.countryProfiles.update()
  const { data: countryProfiles } = api.countryProfiles.readAll()

  const [modalCountryProfileIsOpen, setModalCountryProfileIsOpen] = useState<boolean>(false)
  const [countryProfileToEdit, setCountryProfileToEdit] = useState<Partial<CountryProfile> | null>(
    null,
  )

  function removeEntry(countryProfile: CountryProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteCountryProfile(countryProfile.id),
    })
  }

  const removeButton = (countryProfile: CountryProfile) => (
    <Button appearance="subtle" onClick={() => removeEntry(countryProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('countryProfile.title')}</h3>
      <Description>{t('countryProfile.description')}</Description>

      <div className="fullWidth flex end marginBottom10">
        <ButtonAddCountryProfile />
      </div>

      <div>
        <Table
          height={500}
          wordWrap="break-word"
          data={countryProfiles}
          onUpdateRow={(data) => updateCountryProfile({ data }).unwrap()}
          virtualized
          columns={[
            {
              name: t('countryProfile.form.name'),
              tooltip: t('countryProfile.form.name_Tooltip'),
              dataKey: 'name',
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: (value: any) => {
                  setValue(value)
                },
                data: countries,
                defaultOpen: true,
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('countryProfile.form.energyMix'),
              tooltip: t('countryProfile.form.energyMix_Tooltip'),
              dataKey: 'energyMix',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('countryProfile.form.greenEnergyMix'),
              tooltip: t('countryProfile.form.greenEnergyMix_Tooltip'),
              dataKey: 'greenEnergyMix',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'float',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('countryProfile.form.source'),
              tooltip: t('countryProfile.form.source_Tooltip'),
              dataKey: 'source',
              columnProps: {
                flexGrow: 2,
              },
              EditComponent: InputCustom,
              editComponentProps: (value, setValue, _, getCellInformations) => ({
                value,
                onChange: setValue,
                as: 'textarea',
                style: { resize: 'none', height: getCellInformations().height },
              }),
            },
            {
              name: t('components.table.remove'),
              role: 'editor',
              cellContent: removeButton,
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>

      <Modal
        open={modalCountryProfileIsOpen}
        onClose={() => setModalCountryProfileIsOpen(false)}
        onExited={() => setCountryProfileToEdit(null)}
        onValidate={() => {
          setModalCountryProfileIsOpen(false)
          if (countryProfileToEdit) {
            return updateCountryProfile({ data: countryProfileToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => {
          setModalCountryProfileIsOpen(false)
          return Promise.resolve()
        }}
        title={t('countryProfile.modalTitle')}
      >
        <CountryProfileForm
          countryProfile={countryProfileToEdit}
          setCountryProfile={setCountryProfileToEdit}
        />
      </Modal>
    </div>
  )
}
