import { createApi as reduxCreateApi } from '@reduxjs/toolkit/query/react'
import api from 'services/api'
import { ItemWithId, Token, User } from 'types'
import { setToken, setUser } from '../../authentication/authReducer'
import { auth } from '../../authentication/localStorageToken'
import { baseQuery } from '../baseQuery'

const authApi = reduxCreateApi({
  baseQuery,
  endpoints: (build) => ({
    /* ********
     * Tokens *
     ******** */

    getToken: build.mutation<Token, { username: string; password: string }>({
      query: (data) => ({
        url: '/token/',
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(patch, { queryFulfilled, dispatch }) {
        try {
          const { data: token } = await queryFulfilled
          setToken(token)
          dispatch(api.me.endpoints.readMe.initiate())
        } catch (err) {
          console.error(err)
        }
      },
    }),
    refreshToken: build.mutation<{ access: string }, { refresh: string }>({
      query: (data) => ({
        url: '/token/refresh/',
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(refreshToken, { queryFulfilled }) {
        try {
          const { data: accessToken } = await queryFulfilled
          setToken({ ...refreshToken, ...accessToken })
        } catch (err) {
          auth.logout()
          console.error(err)
        }
      },
    }),
    verifyToken: build.mutation<void, { access: string }>({
      query: (data) => ({
        url: '/token/verify/',
        method: 'POST',
        body: data,
      }),
    }),

    /* **************
     *  Activation  *
     ************** */

    activation: build.mutation<void, { uid: string; token: string }>({
      query: (data) => ({
        url: '/auth/users/activation/',
        method: 'POST',
        body: data,
      }),
    }),
    resendActivation: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: '/auth/users/resend_activation/',
        method: 'POST',
        body: data,
      }),
    }),

    /* ******************
     *  Reset password  *
     ****************** */

    setPassword: build.mutation<
      void,
      { new_password: string; re_new_password: string; current_password: string }
    >({
      query: (data) => ({
        url: '/auth/users/set_password/',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: '/auth/users/reset_password/',
        method: 'POST',
        body: data,
      }),
    }),
    resetPasswordConfirm: build.mutation<
      void,
      { uid: string; token: string; new_password: string; re_new_password: string }
    >({
      query: (data) => ({
        url: '/auth/users/reset_password_confirm/',
        method: 'POST',
        body: data,
      }),
    }),

    /* ******************
     *  Reset username  *
     ****************** */

    setUsername: build.mutation<
      void,
      { new_username: string; re_new_username: string; current_password: string }
    >({
      query: (data) => ({
        url: '/auth/users/set_username/',
        method: 'POST',
        body: data,
      }),
    }),
    resetUsername: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: '/auth/users/reset_username/',
        method: 'POST',
        body: data,
      }),
    }),
    resetUsernameConfirm: build.mutation<
      void,
      { uid: string; token: string; new_username: string; re_new_username: string }
    >({
      query: (data) => ({
        url: '/auth/users/reset_username_confirm/',
        method: 'POST',
        body: data,
      }),
    }),

    /* ********
     *  User  *
     ******** */

    readMe: build.query<User, void>({
      query: (data) => ({
        url: '/auth/users/me/',
        method: 'GET',
        body: data,
      }),
      async onQueryStarted(patch, { queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled
          await setUser(user)
        } catch (err) {
          console.error(err)
        }
      },
    }),
    updateMe: build.mutation<User, ItemWithId<User>>({
      query: (data) => ({
        url: '/auth/users/me/',
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(patch, { queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled
          await setUser(user)
        } catch (err) {
          console.error(err)
        }
      },
    }),

    deleteMe: build.mutation<void, { password: string }>({
      query: ({ password }) => ({
        url: '/auth/users/me/',
        method: 'DELETE',
        body: { current_password: password },
      }),
    }),
  }),
})

export { authApi }
