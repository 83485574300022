import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from '../translations/en.json'
import fr from '../translations/fr.json'
import es from '../translations/es.json'
import it from '../translations/it.json'
import de from '../translations/de.json'

export const translationsKeys = ['en', 'fr', 'es', 'it', 'de']

export const defaultNS = 'default'

export const resources = {
  en: { default: en },
  fr: { default: fr },
  es: { default: es },
  it: { default: it },
  de: { default: de },
} as const

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  })

i18next.services.formatter?.add('lowercase', (value: string): string => value.toLowerCase())
i18next.services.formatter?.add(
  'capitalizeFirstLetter',
  (value: string): string => `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
)
