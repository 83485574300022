import { Modal } from 'components'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { CommutationProfile } from 'types'
import { CommutationProfileForm } from './CommutationProfileForm'

type ModalAddCommutationProfileProps = ButtonProps

export function ButtonAddCommutationProfile(props: ModalAddCommutationProfileProps) {
  const { t } = useTranslation()
  const [commutationProfile, setCommutationProfile] = useState<Partial<CommutationProfile> | null>(
    null,
  )
  const [modalCommutationProfileIsOpen, setModalCommutationProfileIsOpen] = useState<boolean>(false)
  const [createCommutationProfile] = api.commutationProfiles.create()

  async function addCommutationProfile() {
    if (commutationProfile) await createCommutationProfile({ data: commutationProfile })
    setModalCommutationProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalCommutationProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <DoubleIcon icons={['car', 'circle-info']} className="marginRight10" />{' '}
        {t('commutation.settings.profiles.buttonAdd')}
      </Button>
      <Modal
        open={modalCommutationProfileIsOpen}
        onClose={() => setModalCommutationProfileIsOpen(false)}
        onExited={() => setCommutationProfile(null)}
        onValidate={addCommutationProfile}
        onCancel={() => Promise.resolve(setModalCommutationProfileIsOpen(false))}
        title={t('commutation.settings.profiles.buttonAdd')}
      >
        <CommutationProfileForm
          commutationProfile={commutationProfile}
          setCommutationProfile={setCommutationProfile}
        />
      </Modal>
    </>
  )
}
