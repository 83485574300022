import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { InputCustom, Modal, Tooltip } from 'components'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { map } from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, SelectPicker } from 'rsuite'
import api from 'services/api'
import { hardwareTypesIcons } from 'services/constants/hadwareTypes'
import { ComputerRig, ComputerRigHardware, HardwareProfile } from 'types'
import { ButtonAddComputerRigs } from 'views/ComputerRigs/ButtonAddComputerRigs'
import { hardwareLocations } from 'services/constants/hardwareLocations'
import { SystemUnitIcon } from 'components/Icons/SystemUnit'
import { useRole } from 'hooks/useRole'
import { AddHardwareProfileModal } from './AddHardwareProfileModal'

type HardwareProfileData = HardwareProfile & {
  computerRigHardwareId: ComputerRigHardware['id']
  cloudSupplier: ComputerRigHardware['cloudSupplier']
  __RowKey: ComputerRigHardware['id']
  __RowType: 'hardwareProfile'
  location: ComputerRigHardware['location']
}

export type ComputerRigData = ComputerRig & {
  __RowType: 'computerRig'
  __RowKey: ComputerRig['id']
  __ItemsNumber: string
  children: HardwareProfileData[]
  location?: HardwareProfileData['location']
}

export function ComputerRigs() {
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'owner' })

  const { data: computerRigs, refetch: refetchComputerRigs } = api.computerRig.readAll()
  const [deleteComputerRig] = api.computerRig.delete()
  const [updateComputerRig] = api.computerRig.update()

  const { data: computerRigHardwares, refetch: refetchComputerRigHardwares } =
    api.computerRigHardware.readAll()
  const [deleteComputerRigHardware] = api.computerRigHardware.delete()
  const [updateComputerRigHardware] = api.computerRigHardware.update()

  const { data: hardwareProfiles, refetch: refetchHardwareProfiles } =
    api.hardwareProfiles.readAll()
  const [updateHardwareProfiles] = api.hardwareProfiles.update()
  const { data: cloudSupplierProfiles = [] } = api.cloudSupplierProfile.readAll()

  const [computerRigIdToAdd, setComputerRigIdToAdd] = useState<ComputerRig['id'] | void>()
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false)

  const data = useMemo<ComputerRigData[] | undefined>(
    () =>
      computerRigs?.map((computerRig) => {
        const filteredComputerRigHardwares = computerRigHardwares?.filter(
          (crh) => crh.computerRig === computerRig.id,
        )

        const hardwareProfileDeps: HardwareProfileData[] = []

        const itemsNumber: Record<string, number> = {}

        filteredComputerRigHardwares?.forEach((computerRigHardware) => {
          const hardwareProfile = hardwareProfiles?.find(
            (hp) => hp.id === computerRigHardware.hardwareProfile,
          )
          if (!hardwareProfile) return

          if (!itemsNumber[hardwareProfile.name]) itemsNumber[hardwareProfile.name] = 0
          itemsNumber[hardwareProfile.name] += 1

          hardwareProfileDeps.push({
            ...hardwareProfile,
            __RowType: 'hardwareProfile',
            __RowKey: computerRigHardware.id,
            computerRigHardwareId: computerRigHardware.id,
            location: computerRigHardware?.location,
            cloudSupplier: computerRigHardware?.cloudSupplier,
          })
        })

        return {
          ...computerRig,
          __RowType: 'computerRig',
          __RowKey: computerRig.id,
          __ItemsNumber: map(itemsNumber, (value, key) => `${value}x ${key}`).join(' + '),
          children: hardwareProfileDeps,
        }
      }),
    [computerRigs, computerRigHardwares, hardwareProfiles],
  )

  function removeEntry(data: ComputerRigData | HardwareProfileData) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () =>
        data.__RowType === 'computerRig'
          ? deleteComputerRig(data.id).unwrap().then(refetchComputerRigs)
          : deleteComputerRigHardware(data.computerRigHardwareId)
              .unwrap()
              .then(refetchComputerRigHardwares),
    })
  }

  return (
    <div className="fullWidth" style={{ marginTop: '-40px' }}>
      <div className="fullWidth flex row end marginBottom10">
        <ButtonAddComputerRigs style={{ marginRight: 10 }} />
        <Tooltip title={t('components.table.fullscreen')}>
          <Button onClick={() => setOpenFullScreen(true)}>
            <FontAwesomeIcon icon="expand" />
          </Button>
        </Tooltip>
      </div>

      <div>
        <Table<ComputerRigData | HardwareProfileData>
          height={500}
          data={data}
          shouldUpdateScroll
          isTree
          rowKey="__RowKey"
          headerHeight={60}
          onUpdateRow={async (data) => {
            if (data.__RowType === 'computerRig') {
              return updateComputerRig({ data })
                .unwrap()
                .then((res) => {
                  refetchComputerRigs()
                  return { ...data, ...res }
                })
            }
            const selectedComputerRigHardware = computerRigHardwares?.find(
              (cr) => cr.id === data.computerRigHardwareId,
            )
            const updateComputerRigHardwarePromise = await updateComputerRigHardware({
              data: {
                ...selectedComputerRigHardware,
                location: data.location,
                cloudSupplier: data.location !== 0 ? null : data.cloudSupplier,
              },
            })
            const updateHardwareProfilesPromise = await updateHardwareProfiles({
              data: { ...data },
            })
              .unwrap()
              .then((res) => {
                refetchHardwareProfiles()
                return { ...data, ...res }
              })
            if (authorized) {
              return Promise.all([updateComputerRigHardwarePromise, updateHardwareProfilesPromise])
            }
            return Promise.resolve()
          }}
          renderTreeToggle={(icon) => icon}
          columns={[
            {
              name: t('computerRig.form.name'),
              tooltip: t('computerRig.form.name_Tooltip'),
              dataKey: 'name',
              treeAccepter: (rowData) => rowData.__RowType === 'computerRig',
              EditComponent: InputCustom,
              cellContent: (rowData) =>
                rowData.__RowType === 'computerRig' ? (
                  <span>
                    <span className="bold fontSize14">{rowData.name}</span>
                    <span className="marginLeft15 grey fontSize12">{rowData.__ItemsNumber}</span>
                  </span>
                ) : null,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
              }),
              columnProps: {
                flexGrow: 2,
              },
            },
            {
              name: t('computerRig.form.rig'),
              tooltip: t('computerRig.form.rig_Tooltip'),
              dataKey: 'rig',
              treeAccepter: (rowData) => rowData.__RowType === 'hardwareProfile',
              cellContent: (rowData) =>
                hardwareProfiles?.find((csp) => csp.id === rowData.id)?.name || null,
              columnProps: {
                width: 200,
              },
            },
            {
              name: t('computerRig.form.type'),
              tooltip: t('computerRig.form.type_Tooltip'),
              dataKey: 'type',
              treeAccepter: (rowData) => rowData.__RowType === 'hardwareProfile',
              cellContent: (rowData) =>
                rowData.__RowType === 'hardwareProfile' ? (
                  <span>
                    {rowData.hardwareType === 'tw' ? (
                      <SystemUnitIcon className="marginRight10" />
                    ) : (
                      <FontAwesomeIcon
                        icon={hardwareTypesIcons[rowData.hardwareType]}
                        className="marginRight10"
                      />
                    )}
                    {t(`constants.hardwareTypes.${rowData.hardwareType}`)}
                  </span>
                ) : null,
              columnProps: {
                width: 180,
              },
            },
            {
              name: t('computerRig.form.location'),
              tooltip: t('computerRig.form.location_Tooltip'),
              dataKey: 'location',
              treeAccepter: (rowData) => rowData.__RowType === 'hardwareProfile',
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: (val: number) => {
                  setValue(val)
                },
                searchable: false,
                cleanable: false,
                style: { margin: '0 auto', width: '90%' },
                data: hardwareLocations.map(({ key, icon, value }) => ({
                  label: (
                    <span>
                      <FontAwesomeIcon icon={icon as IconProp} />
                      <span style={{ marginLeft: '8px' }}>
                        {t<any>(`computerRig.form.locationTypes.${key}`)}
                      </span>
                    </span>
                  ),
                  value,
                })),
                defaultOpen: true,
              }),
              cellContent: (rowData) =>
                rowData.__RowType === 'hardwareProfile' ? (
                  <FontAwesomeIcon
                    icon={
                      hardwareLocations.find((loc) => loc.value === rowData.location)
                        ?.icon as IconProp
                    }
                    className="marginRight10"
                  />
                ) : null,
              columnProps: {
                width: 120,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
            {
              name: t('computerRig.form.cloudSupplier'),
              tooltip: t('computerRig.form.cloudSupplier_Tooltip'),
              treeAccepter: (rowData) => rowData.__RowType === 'hardwareProfile',
              dataKey: 'cloudSupplier',
              cellContent: (rowData) =>
                rowData.location !== 0 ? (
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      cursor: 'not-allowed',
                      width: '100%',
                      height: '100%',
                      padding: 0,
                    }}
                  />
                ) : rowData.__RowType === 'hardwareProfile' ? (
                  cloudSupplierProfiles?.find((csp) => csp.id === rowData.cloudSupplier)?.name
                ) : null,
              disableCellEdit: (rowData) => rowData.location !== 0,
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: setValue,
                data: cloudSupplierProfiles.map(({ name, id }) => ({ label: name, value: id })),
                defaultOpen: true,
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('components.table.add'),
              role: 'editor',
              treeAccepter: (rowData) => rowData.__RowType === 'computerRig',
              cellContent: (rowData) => (
                <Button appearance="subtle" onClick={() => setComputerRigIdToAdd(rowData.id)}>
                  <FontAwesomeIcon icon="plus" />
                </Button>
              ),
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
            {
              name: t('components.table.remove'),
              role: 'editor',
              cellContent: (rowData) => (
                <Button appearance="subtle" onClick={() => removeEntry(rowData)}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              ),
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>
      <Modal
        hideCancel
        validateLabel={t('components.modals.close')}
        size="full"
        open={openFullScreen}
        onValidate={() => Promise.resolve(setOpenFullScreen(false))}
      >
        <ComputerRigs />
      </Modal>
      <AddHardwareProfileModal
        computerRigId={computerRigIdToAdd}
        onCancel={() => Promise.resolve(setComputerRigIdToAdd())}
        onClose={() => Promise.resolve(setComputerRigIdToAdd())}
        rigData={data?.find((rig: ComputerRigData) => rig.id === computerRigIdToAdd)}
      />
    </div>
  )
}
