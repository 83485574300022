import { Schema } from 'rsuite'
import { t } from 'i18next'

export default Schema.Model({
  name: Schema.Types.StringType().isRequired(t('misc.fieldValidation.requiredField')),
  hardwareType: Schema.Types.StringType().isRequired(t('misc.fieldValidation.requiredField')),
  manufacturingCarbonEquivalent: Schema.Types.NumberType(),
  idleCarbonEquivalent: Schema.Types.NumberType(),
  standbyCarbonEquivalent: Schema.Types.NumberType(),
  renderCarbonEquivalent: Schema.Types.NumberType(),
  year: Schema.Types.NumberType(),
})
