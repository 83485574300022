import { useEffect, useMemo, useState } from 'react'
import { TabbedContainer } from 'components'
import { Option, ID, Production } from 'types'
import api from 'services/api'
import { Col, FlexboxGrid, Loader, Panel } from 'rsuite'
import { ProductionDetails } from 'views/ProductionDetails/ProductionDetails'
import { useComputedValue } from 'hooks/useComputedValue'
import { openModal } from 'components/Modal/openModal'
import { sideButtons } from 'components/TabbedContainer/SideButtonsEdition'
import { useAutoSave } from 'hooks/useAutoSave'
import { useNavigate, useParams } from 'react-router-dom'
import { AnnualTravelsForm } from 'views/ProductionsAnnualTravels/ProductionsAnnualTravels'
import { useTranslation } from 'react-i18next'
import { DepartmentGroups } from 'views/DepartmentGroups/DepartmentGroup'
import { ProductionDepartmentsReport } from 'components/Charts/ProductionDepartmentsReport/ProductionDepartmentsReport'
import { ModalAddProduction } from './ModalAddProduction'

export function Productions() {
  const { productionId } = useParams<{ productionId: ID }>()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: productions = [], isLoading } = api.productions.readAll()
  const { data: departmentGroups = [] } = api.departmentGroups.readAll({
    filters: { production: productionId },
  })
  const [updateProduction] = api.productions.update()
  const [createProduction] = api.productions.create()
  const [deleteProduction] = api.productions.delete()

  const [openModalAddProduction, setOpenModalAddProduction] = useState<boolean>(false)
  const [production, setProduction] = useState<Partial<Production> | null>(null)

  const originalProduction = useMemo(() => {
    if (!productionId) return null
    const production = productions.find((p) => p.id === productionId) || null
    return production
  }, [productions, productionId])

  const { computedValue: productionComputedValue, refetch: refetchComputedProductionsValues } =
    useComputedValue<'productions'>({
      type: 'productions',
    })

  const { dataHasChange, setHasBlurred } = useAutoSave({
    originalValue: originalProduction,
    diffValue: production,
    updateComputedData: () => {
      refetchComputedProductionsValues()
    },
    save: () => {
      if (production?.id) updateProduction({ data: production })
    },
    timeout: 15,
  })

  const noComputeValue = Object.keys(productionComputedValue || {}).length === 0

  useEffect(() => {
    if (productionId) {
      const production = productions.find((p) => p.id === productionId) || null
      setProduction(production)
    }
  }, [productions, productionId])

  useEffect(() => {
    if (productions.length && !productionId) {
      navigate(`/productions/${productions[0].id}`)
    }
  }, [productions])

  function addProduction() {
    setOpenModalAddProduction(true)
  }

  function removeProduction() {
    if (!productionId) return
    openModal({
      content: (
        <span>{t('components.modals.removeItemValidation', { name: production?.name })}</span>
      ),
      onValidate: () => deleteProduction(productionId).unwrap(),
    })
  }

  function onProductionTabChange(option?: Option) {
    if (!option) {
      addProduction()
    } else {
      navigate(`/productions/${option.value}`)
    }
  }

  async function updateData() {
    const promises = []

    if (production && dataHasChange) {
      if (production.id) {
        promises.push(updateProduction({ data: production }))
      } else {
        promises.push(createProduction({ data: production }))
      }
    }

    return Promise.all(promises).then(() => {
      refetchComputedProductionsValues()
    })
  }

  function cancelChanges() {
    setProduction(originalProduction)
  }

  if (isLoading) return <Loader />

  return (
    <TabbedContainer
      onTabChange={onProductionTabChange}
      navTitleStyle={{
        position: 'sticky',
        top: 56,
        paddingTop: 20,
        marginTop: -56,
        backgroundColor: 'var(--rs-body)',
        zIndex: 6,
      }}
      options={productions.map((production) => ({
        label: (
          <div className="flex row noWrap alignCenter start">
            <span style={{ marginRight: noComputeValue ? 0 : 10 }}>{production.name}</span>
            {productionComputedValue?.[production.id] ? (
              <div style={{ fontSize: 10 }} className="salmon">
                <div style={{ lineHeight: '14px' }}>
                  {t('misc.units.valueKgEqCo2', {
                    value: new Intl.NumberFormat('fr-FR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(productionComputedValue[production.id].departments.real),
                  })}
                </div>
                {production?.nbEpisodes ? (
                  <div style={{ lineHeight: '14px', color: '#ffd3bd' }}>
                    {t('misc.units.valueKgEqCo2PerEp', {
                      value: new Intl.NumberFormat('fr-FR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(
                        Math.round(
                          productionComputedValue[production.id].departments.real /
                            production.nbEpisodes,
                        ),
                      ),
                    })}
                  </div>
                ) : null}
                {production?.nbEpisodes && production?.episodeLength ? (
                  <div style={{ lineHeight: '14px', color: '#ffd3bd' }}>
                    {t('misc.units.valueKgEqCo2PerMin', {
                      value: new Intl.NumberFormat('fr-FR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(
                        Math.round(
                          productionComputedValue[production.id].departments.real /
                            production.nbEpisodes /
                            production.episodeLength,
                        ),
                      ),
                    })}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ),
        value: production.id,
      }))}
      active={productionId}
      sideButtons={sideButtons({
        add: addProduction,
        remove: removeProduction,
        save: updateData,
        cancelChanges,
        disableSave: true,
        disableDelete: !productionId,
        exponent: t('productions.view.exponent') || '',
        t,
      })}
    >
      <div className="relative fullWidth">
        {!productions.length ? (
          <div
            style={{ width: '100%', height: 100 }}
            className="flex center alignCenter textAlignCenter lightgrey"
          >
            {t('productions.view.noProduction')}&nbsp;
            <a tabIndex={0} onClick={() => addProduction()} role="button">
              {t('productions.view.createProduction')}
            </a>
          </div>
        ) : (
          <div>
            {production ? (
              <FlexboxGrid justify="space-around">
                <FlexboxGrid.Item as={Col} colspan={9} md={9} sm={24} xs={24}>
                  <Panel
                    header={<h5>{t('productions.view.productionDetailsPanelTitle')}</h5>}
                    style={{ marginBottom: 20 }}
                  >
                    <ProductionDetails
                      production={production as Production}
                      setProduction={setProduction}
                      setHasBlurred={setHasBlurred}
                    />
                  </Panel>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item as={Col} colspan={15} md={15} sm={24} xs={24}>
                  <Panel style={{ marginBottom: 20 }}>
                    <div className="fullWidth" style={{ height: 400 }}>
                      {productionComputedValue && productionId ? (
                        <>
                          <h5 style={{ paddingBottom: 10 }}>
                            {t('productions.view.productionReportTitle')}: {production?.name}
                          </h5>
                          <ProductionDepartmentsReport
                            departmentGroups={departmentGroups}
                            productionComputedValue={productionComputedValue}
                            productionId={productionId}
                          />
                        </>
                      ) : null}
                    </div>
                  </Panel>
                  <Panel
                    header={
                      <h5>
                        <span style={{ marginRight: 10 }}>
                          {t('productions.view.travelsPanelTitle')}
                        </span>
                        {productionComputedValue && productionId ? (
                          <span className="salmon normal" style={{ fontSize: '14px' }}>
                            {t('misc.units.valueKgEqCo2PerYear', {
                              value:
                                new Intl.NumberFormat('fr-FR', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }).format(productionComputedValue[productionId]?.trips.real) ??
                                null,
                            })}
                          </span>
                        ) : null}
                      </h5>
                    }
                    style={{ marginBottom: 20 }}
                  >
                    <AnnualTravelsForm
                      model={production as Production}
                      setModel={setProduction}
                      setHasBlurred={setHasBlurred}
                    />
                  </Panel>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            ) : null}
            {productionId ? (
              <FlexboxGrid justify="space-around">
                <FlexboxGrid.Item as={Col} colspan={24} md={24} sm={24} xs={24}>
                  <Panel header={<h5>{t('productions.view.groupsPanelTitle')}</h5>}>
                    <DepartmentGroups
                      productionId={productionId}
                      departmentGroups={departmentGroups}
                    />
                  </Panel>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            ) : null}
          </div>
        )}
        <ModalAddProduction
          open={openModalAddProduction}
          onClose={() => setOpenModalAddProduction(false)}
          setSelectedProduction={(productionId) => navigate(`/productions/${productionId}`)}
        />
      </div>
    </TabbedContainer>
  )
}
