/* eslint-disable react/prop-types */
import { useComputedValue } from 'hooks/useComputedValue'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from 'recharts'
import api from 'services/api'
import { randomColors } from 'styles/colors'
import { Facility } from 'types'
import './Recharts-custom.scss'
import { useTranslation } from 'react-i18next'
import { filter, reduce } from 'lodash'
import { colors } from './facilityRecapColors'

export type FacilityRecapProps = {
  width?: number
  height?: number
  facility: Partial<Facility> | null
}

type TranslationNameType =
  | 'Commutations'
  | 'Cooling'
  | 'Datacenter'
  | 'Diets'
  | 'DepreciationTower'
  | 'DepreciationServer'
  | 'DepreciationScreen'
  | 'DepreciationOthers'
  | 'Others'
  | 'Electricity'
  | 'Heating'
  | 'Paper'
  | 'Streaming'
  | 'Trips'

export function FacilityRecap(props: FacilityRecapProps) {
  const { width = 200, height = 350, facility } = props

  const { t } = useTranslation()

  if (!facility) return null

  const { computedValue: detailsComputedValues } = useComputedValue<'facilitiesDetails'>({
    type: 'facilitiesDetails',
  })
  const { data: facilityDatas = [] } = api.facilityDatas.readAll({
    filters: { facility: facility.id },
  })
  const { data: energySupplierTypes = [] } = api.energySupplierTypes.readAll()

  if (!detailsComputedValues || !facilityDatas) return null

  const isElectric =
    energySupplierTypes.length > 0 &&
    energySupplierTypes.filter((type) => type.name === 'Electricité')

  const chartDatas = facilityDatas
    .map((facilityData) => {
      if (detailsComputedValues !== null && detailsComputedValues?.[facilityData.facility]) {
        const coolingSupplierIsElectric =
          isElectric && facilityData.coolingSupplier === isElectric[0]?.id
        const heatingSupplierIsElectric =
          isElectric && facilityData.heatingSupplier === isElectric[0]?.id

        const datas = detailsComputedValues[facilityData.facility]
        const depreciationDatas = datas[facilityData.year]?.depreciation || {}
        const othersDepreciationDatas = filter(
          depreciationDatas,
          (data: any) => data !== 'tw' || data !== 'sc' || data !== 'sr',
        )
        const paper = datas?.[facilityData.year]?.paper ? datas[facilityData.year].paper : 0
        let electricity: number = 0

        if (coolingSupplierIsElectric && heatingSupplierIsElectric) {
          electricity =
            datas[facilityData.year].electricity -
            datas[facilityData.year].heating -
            datas[facilityData.year].cooling
        } else if (coolingSupplierIsElectric && !heatingSupplierIsElectric) {
          electricity = datas[facilityData.year].electricity - datas[facilityData.year].cooling
        } else if (!coolingSupplierIsElectric && heatingSupplierIsElectric) {
          electricity = datas[facilityData.year].electricity - datas[facilityData.year].heating
        } else {
          electricity = datas[facilityData.year]?.electricity
        }

        return {
          name: facilityData.year,
          Electricity: electricity / 1000 || 0,
          Heating: datas?.[facilityData.year]?.heating
            ? datas[facilityData.year].heating / 1000
            : 0,
          Cooling: datas?.[facilityData.year]?.cooling
            ? datas[facilityData.year].cooling / 1000
            : 0,
          Datacenter: datas?.[facilityData.year]?.datacenterCooling
            ? datas[facilityData.year].datacenterCooling / 1000
            : 0,
          Streaming:
            (datas[facilityData.year].videoStreaming + datas[facilityData.year].audioStreaming) /
              1000 || 0,
          Diets: datas?.[facilityData.year]?.diets ? datas[facilityData.year].diets / 1000 : 0,
          Trips: datas?.[facilityData.year]?.trips ? datas[facilityData.year].trips / 1000 : 0,
          Commutations: datas?.[facilityData.year]?.commutations
            ? datas[facilityData.year].commutations / 1000
            : 0,
          DepreciationTower: depreciationDatas?.tw ? depreciationDatas.tw / 1000 : 0,
          DepreciationServer: depreciationDatas?.sr ? depreciationDatas.sr / 1000 : 0,
          DepreciationScreen: depreciationDatas?.sc ? depreciationDatas.sc / 1000 : 0,
          DepreciationOthers: reduce(othersDepreciationDatas, (acc, val) => acc + val) / 1000 || 0,
          Others: paper / 1000,
        }
      }
      return {
        name: facilityData.year,
        Electricity: 0,
        Heating: 0,
        Cooling: 0,
        Datacenter: 0,
        Streaming: 0,
        Diets: 0,
        Trips: 0,
        Commutations: 0,
        DepreciationTower: 0,
        DepreciationServer: 0,
        DepreciationScreen: 0,
        DepreciationOthers: 0,
        Others: 0,
      }
    })
    .sort((a, b) => a.name - b.name)

  let keys: string[] = []

  if (chartDatas.length > 0) {
    keys = Object.keys((chartDatas as any)?.[0]).filter((key) => key !== 'name')
  }

  const customTick = (props: {
    width: number
    height: number
    x: number
    y: number
    payload: { value: string }
  }) => {
    const { width, height, x, y, payload } = props
    return (
      <foreignObject
        style={{
          fontSize: 14,
          width: width / chartDatas.length,
          height,
          marginLeft: 150,
        }}
        x={x}
        y={y}
        offset={15}
      >
        <div style={{ marginLeft: '50%', width: 'fit-content', transform: 'translateX(-50%)' }}>
          {payload.value}
        </div>
      </foreignObject>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={width}
        height={height}
        data={chartDatas}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 30,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" tick={customTick} />
        <YAxis
          type="number"
          scale="sqrt"
          label={{ value: 't CO2eq', angle: -90, position: 'insideLeft', offset: -25 }}
        />
        {keys.map((key, index) => {
          const color =
            colors.find((el) => el.name === key)?.color ||
            randomColors[(index + 1) % randomColors.length]
          return <Line key={key} dataKey={key} stroke={color} />
        })}
        <Tooltip
          formatter={(value, name: TranslationNameType) => [
            `${Math.round(Number(value) * 100) / 100} t eqCO2`,
            `${t(`productions.graph.${name}`)}`,
          ]}
          allowEscapeViewBox={{ x: true, y: true }}
          cursor={false}
          offset={0}
          position={{ x: 650, y: 0 }}
        />
        <Legend
          formatter={(value: TranslationNameType) => `${t(`productions.graph.${value}`)}`}
          layout="vertical"
          align="right"
          verticalAlign="middle"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
