/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type ComputedValuesState = {
  isUpdating: boolean
}

const initialState: ComputedValuesState = {
  isUpdating: false,
}

const slice = createSlice({
  name: 'computedValuesErrorsUpdater',
  initialState,
  reducers: {
    setIsUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdating = payload
    },
  },
})

export const { setIsUpdating } = slice.actions

export default slice
