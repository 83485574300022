import { stringify } from 'query-string'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { transformRequest, transformResponse, filterResponse } from 'services/api/utils'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  CreateParams,
  DeleteParams,
  ID,
  ItemWithId,
  ReadAllParams,
  ReadParams,
  UpdateParams,
} from 'types'

export const createCRUDEndpoints = <
  Build extends EndpointBuilder<BaseQueryFn, string, string>,
  Item extends { id: ID },
>(
  build: Build,
  endpoint: string,
  tagType: string,
) => ({
  create: build.mutation<Item, CreateParams<Item>>({
    query: ({ queries, data }) => ({
      url: `/${endpoint}/`,
      method: 'POST',
      body: transformRequest(data),
      params: queries,
    }),
    transformResponse: (response: Item) => transformResponse<Item>(response),
    invalidatesTags: [{ type: tagType, id: 'LIST' }],
  }),

  read: build.query<Item, ReadParams>({
    query: ({ id, queries }) => ({
      url: `/${endpoint}/${id}${queries ? `?${stringify(queries)}` : ''}`,
      method: 'GET',
      params: queries,
    }),
    transformResponse: (response: Item) => transformResponse<Item>(response),
    providesTags: (result, error, { id }) => [{ type: tagType, id }],
  }),

  readAll: build.query<Item[], ReadAllParams<Item> | void>({
    query: ({ queries } = {}) => ({
      url: `/${endpoint}/`,
      method: 'GET',
      params: queries,
    }),
    transformResponse: (response: Item[], meta, params) =>
      transformResponse<Item[]>(filterResponse<Item>(response, params?.filters)),
    providesTags: (result) => {
      if (!result) return [{ type: tagType, id: 'LIST' }]
      return [
        ...result.map(({ id }) => ({ type: tagType, id } as const)),
        { type: tagType, id: 'LIST' },
      ]
    },
  }),

  update: build.mutation<Item, UpdateParams<ItemWithId<Item>>>({
    query: ({ queries, data }) => {
      if (Array.isArray(data)) {
        return {
          url: `/${endpoint}/`,
          method: 'PATCH',
          body: transformRequest(data),
          params: queries,
          headers: new Headers({ 'bulk-update': 'true' }),
        }
      }

      return {
        url: `/${endpoint}/${data.id}/`,
        method: 'PATCH',
        body: transformRequest(data),
        params: queries,
      }
    },
    transformResponse: (response: Item) => transformResponse<Item>(response),
    invalidatesTags: (result) => {
      if (!result) return []
      return [
        { type: tagType, id: result.id },
        { type: tagType, id: 'LIST' },
      ]
    },
  }),

  delete: build.mutation<void, DeleteParams>({
    query: (id) => ({
      url: `/${endpoint}/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => {
      if (Array.isArray(id)) return id.map((id) => ({ type: tagType, id }))
      return [{ type: tagType, id }]
    },
  }),
})
