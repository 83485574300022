import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './FormFieldInformation.module.scss'

type FormFieldInformationProps = { tooltip: JSX.Element }
export function FormFieldInformation(props: FormFieldInformationProps) {
  const { tooltip } = props

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={classes.container} tooltip-formfield-element="true" tabIndex={0}>
      <div className={classes.button}>
        <FontAwesomeIcon icon="info" className="white" style={{ fontSize: '20px' }} />
      </div>
      <div className={classes.tooltip}>{tooltip}</div>
    </div>
  )
}
