import { createApi } from '@reduxjs/toolkit/query/react'
import { Diet, DietValue, FacilityData } from 'types'
import { createCRUDEndpoints } from './createCRUDEndpoints'
import { baseQueryWithReauth } from '../baseQuery'
import { transformRequest, transformResponse } from '../utils'

type DietValueParams = {
  dietId: Diet['id']
  facilityDataId: FacilityData['id']
  dietValues: Partial<DietValue>[]
}

const dietsApi = createApi({
  reducerPath: 'diets',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Diets'],
  endpoints: (build) => ({
    ...(() => {
      const endpoints = createCRUDEndpoints<typeof build, Diet>(build, 'diets', 'Diets')
      return {
        createDiet: endpoints.create,
        readDiet: endpoints.read,
        readAllDiet: endpoints.readAll,
        updateDiet: endpoints.update,
        deleteDiet: endpoints.delete,
      }
    })(),
    addDietValues: build.mutation<DietValue[], { data: DietValueParams }>({
      async queryFn(args, api, extraOptions, baseQuery): Promise<any> {
        const { dietValues } = args.data

        const newDietValues = await Promise.all(
          dietValues?.map((dv) =>
            Promise.resolve(
              baseQuery({
                url: '/dietValues/',
                method: 'POST',
                body: transformRequest(dv),
              }),
            ).then(({ data }) => transformResponse<DietValue>(data as DietValue)),
          ) || [],
        )

        return {
          data: newDietValues,
        }
      },
      invalidatesTags: [{ type: 'Diets', id: 'LIST' }],
    }),
    deleteAllDiets: build.mutation({
      async queryFn(args, api, extraOptions, baseQuery): Promise<any> {
        const { facilityDataId } = args.data
        await baseQuery({
          url: `/facilityDatas/${facilityDataId}/deleteAllDiet/`,
          method: 'GET',
        })
        return {
          data: [],
        }
      },
      invalidatesTags: [{ type: 'Diets', id: 'LIST' }],
    }),
  }),
})

export { dietsApi }
