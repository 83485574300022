import { Form, FormHelpTextProps } from 'rsuite'

type FormTooltipProps = FormHelpTextProps & {
  children?: React.ReactNode
}

export function FormTooltip(props: FormTooltipProps) {
  const { children, ...formHelpTextProps } = props
  if (!children) return null
  return <Form.HelpText {...formHelpTextProps}>{children}</Form.HelpText>
}
