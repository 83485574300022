import classes from './Description.module.css'

type DescriptionProps = {
  children: React.ReactNode
  color?: 'softblack' | 'grey' | 'lightgrey' | 'default'
  italic?: boolean
  style?: Record<string, any>
}

export function Description(props: DescriptionProps) {
  const { children, color, italic, style, ...rest } = props

  function getColor() {
    if (color === 'softblack') return '#4a4a4ad9'
    if (color === 'grey') return '#868686'
    if (color === 'lightgrey') return 'lightgrey'
    return undefined
  }

  return (
    <div
      className={classes.description}
      style={{
        color: getColor(),
        fontStyle: italic ? 'italic' : undefined,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
