import { Modal } from 'components'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { EnergySupplierProfile } from 'types'
import { EnergySupplierProfileForm } from './EnergySupplierProfileForm'

type ModalAddEnergySupplierProfileProps = ButtonProps

export function ButtonAddEnergySupplierProfile(props: ModalAddEnergySupplierProfileProps) {
  const { t } = useTranslation()
  const [energySupplierProfile, setEnergySupplierProfile] =
    useState<Partial<EnergySupplierProfile> | null>(null)
  const [modalEnergySupplierProfileIsOpen, setModalEnergySupplierProfileIsOpen] =
    useState<boolean>(false)
  const [createEnergySupplierProfile] = api.energySupplierProfiles.create()

  async function addEnergySupplierProfile() {
    if (energySupplierProfile) await createEnergySupplierProfile({ data: energySupplierProfile })
    setModalEnergySupplierProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalEnergySupplierProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <DoubleIcon icons={['lightbulb', 'circle-info']} className="marginRight10" />{' '}
        {t('energySuppliers.energySupplierProfiles.addButton')}
      </Button>
      <Modal
        open={modalEnergySupplierProfileIsOpen}
        onClose={() => setModalEnergySupplierProfileIsOpen(false)}
        onExited={() => setEnergySupplierProfile(null)}
        onValidate={addEnergySupplierProfile}
        onCancel={() => Promise.resolve(setModalEnergySupplierProfileIsOpen(false))}
        title={t('energySuppliers.energySupplierProfiles.addButton')}
      >
        <EnergySupplierProfileForm
          energySupplierProfile={energySupplierProfile}
          setEnergySupplierProfile={setEnergySupplierProfile}
        />
      </Modal>
    </>
  )
}
