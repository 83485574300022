import { TabbedContainer } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'services/api'
import { FacilityConsumptionForm } from 'views/FacilityConsumption/FacilityConsumptionForm'
import { Commutation } from 'views/Commutation/Commutation'
import { Diet } from 'views/Diet/Diet'
import { DataConsumptionForm } from 'views/DataConsumption/DataConsumptionForm'
import { ComputedValues, Facility, FacilityData as FacilityDataType, Option, Year } from 'types'
import { useAutoSave } from 'hooks/useAutoSave'
import { Button, Col, FlexboxGrid, Panel } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useComputedValue } from 'hooks/useComputedValue'
import { useRole } from 'hooks/useRole'
import { FacilityDataRecap } from 'components/Charts/FacilityDataRecap/FacilityDataRecap'
import { FTEForm } from 'views/FTE/FTEForm'
import FacilitiesAnnualTravelsForm from 'views/FacilitiesAnnualTravels/FacilitiesAnnualTravels'

type FacilityDataProps = {
  facilityId: Facility['id']
  selectedYear: Year
  setSelectedYear: (selectedYear: Year | number) => void
}

export function FacilityData(props: FacilityDataProps) {
  const { t } = useTranslation()
  const { facilityId, selectedYear, setSelectedYear } = props
  const { authorized } = useRole({ requiredRole: 'editor' })

  const { data: facilityDatas = [] } = api.facilityDatas.readAll({
    filters: { facility: facilityId },
  })
  const { data: commutationTypes } = api.commutationTypes.readAll()
  const { data: dietTypes } = api.dietTypes.readAll()

  const noCommutationTypes = (commutationTypes?.length || 0) === 0
  const noDietTypes = (dietTypes?.length || 0) === 0

  const facilityYearsTabs = useMemo<Option[]>(() => {
    const facilityDatasYears: Year[] = facilityDatas.map((fy) => fy.year)

    let alreadyDefinedYears = facilityDatasYears
    if (facilityDatasYears.length === 0) {
      alreadyDefinedYears = [new Date().getFullYear()]
    }

    const firstYear = Math.min(...alreadyDefinedYears) - 1
    const lastYear = Math.max(...alreadyDefinedYears) + 1

    const allYears = []

    let i = firstYear
    while (i <= lastYear) {
      allYears.push(i)
      i += 1
    }

    return allYears.map((year) => {
      const yearExist = facilityDatasYears.includes(year)
      return {
        label: <div className={`fontSize12 ${!yearExist ? 'lightgrey' : ''}`}>{year}</div>,
        value: year.toString(),
      }
    })
  }, [facilityDatas])

  const [createFacilityData] = api.facilityDatas.create()
  const [updateFacilityData, { isLoading: updateFacilityDataIsUpdating }] =
    api.facilityDatas.update()

  const [facilityData, setFacilityData] = useState<Partial<FacilityDataType> | null>(null)

  const { computedValue: detailsComputedValues, refetch } = useComputedValue<'facilitiesDetails'>({
    type: 'facilitiesDetails',
    skip: !facilityData?.id,
  })

  function formatDetailsUnitValue(key: keyof ComputedValues['facilitiesDetails'][number][string]) {
    if (!facilityData?.facility || !facilityData?.year || !detailsComputedValues) return null
    let value = detailsComputedValues[facilityData.facility]?.[facilityData.year]?.[key]
    if (typeof value !== 'number') return null

    if (value > 10) value = Math.round(value)
    return (
      <span className="salmon normal" style={{ fontSize: '14px' }}>
        {t('misc.units.valueKgEqCo2PerYear', {
          value: new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value),
        })}
      </span>
    )
  }

  const originalFacilityData = useMemo(
    () => facilityDatas.find((fd) => fd.year === selectedYear) || null,
    [facilityDatas, selectedYear],
  )

  const { dataHasChange, setHasBlurred } = useAutoSave({
    originalValue: originalFacilityData,
    diffValue: facilityData,
    updateComputedData: () => {
      refetch()
    },
    save: () => {
      if (facilityData?.id) updateFacilityData({ data: facilityData })
    },
    timeout: 15,
  })

  function onFacilityDataTabChange(option?: Option) {
    if (!option) return
    const selectedYear = Number(option.value)
    if (dataHasChange && facilityData?.id) updateFacilityData({ data: facilityData })
    setSelectedYear(selectedYear)
  }

  async function saveFacilityData() {
    if (facilityData?.id) {
      return updateFacilityData({ data: facilityData })
        .unwrap()
        .catch((error) => {
          console.error(error)
        })
    }

    if (facilityDatas.find((fd) => fd.year === selectedYear)) {
      return Promise.reject(new Error('The facility datas for this year already exist.'))
    }

    return createFacilityData({
      data: {
        ...facilityData,
        facility: facilityId,
        year: selectedYear,
      },
    })
      .unwrap()
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    setFacilityData(facilityDatas.find((fd) => fd.year === selectedYear) || null)
  }, [selectedYear, facilityDatas])

  return (
    <div className="flex column fullWidth relative">
      {!originalFacilityData || !authorized ? null : (
        <div style={{ position: 'absolute', top: 40, right: 0, zIndex: 1 }}>
          <Button
            appearance="primary"
            color="green"
            onClick={() => saveFacilityData()}
            disabled={!originalFacilityData || !dataHasChange}
            loading={updateFacilityDataIsUpdating}
            className="marginRight8"
          >
            <FontAwesomeIcon icon="floppy-disk" />
          </Button>
        </div>
      )}
      <TabbedContainer
        style={{ width: 'auto', margin: 0, marginTop: 40, padding: 0 }}
        onTabChange={onFacilityDataTabChange}
        options={facilityYearsTabs}
        active={selectedYear.toString()}
      >
        {!originalFacilityData ? (
          <div
            style={{ width: '100%', height: 100 }}
            className="flex center alignCenter textAlignCenter lightgrey"
          >
            {t('facilityDatas.view.noFacilityDatas', { year: selectedYear })}
            &nbsp;
            {authorized ? (
              <a tabIndex={0} onClick={() => saveFacilityData()} role="button">
                {t('facilityDatas.view.createFacility')}
              </a>
            ) : null}
          </div>
        ) : (
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item as={Col} colspan={11} lg={24} md={24} sm={24} xs={24}>
              <Panel style={{ marginBottom: 20 }}>
                <h5 style={{ marginTop: -20 }}>{t('facilityDatas.report.title')}</h5>
                <div
                  className="fullWidth"
                  style={{ position: 'relative', height: 400, marginTop: 30 }}
                >
                  <FacilityDataRecap
                    detailsComputedValues={detailsComputedValues}
                    facilityData={facilityData}
                  />
                </div>
              </Panel>
              <Panel
                header={<h5>{t('facilityDatas.view.ftePanelTitle')}</h5>}
                style={{ marginBottom: 20 }}
              >
                <FTEForm
                  facilityData={facilityData}
                  setFacilityData={setFacilityData}
                  setHasBlurred={setHasBlurred}
                />
              </Panel>
            </FlexboxGrid.Item>
            <Panel
              header={<h5>{t('facilityDatas.view.consumptionsPanelTitle')}</h5>}
              style={{ marginBottom: 20 }}
            >
              <p style={{ marginBottom: '20px' }}>
                {t('facilityDatas.view.consumptionsPanelTextInfo')}
              </p>
              <FacilityConsumptionForm
                facilityData={facilityData}
                setFacilityData={setFacilityData}
                setHasBlurred={setHasBlurred}
              />
            </Panel>

            <FlexboxGrid.Item as={Col} colspan={12} md={12} sm={24} xs={24}>
              <Panel
                header={
                  <h5>
                    <span style={{ marginRight: 10 }}>
                      {t('productions.view.travelsPanelTitle')}
                    </span>
                  </h5>
                }
                style={{ marginBottom: 20 }}
              >
                <FacilitiesAnnualTravelsForm model={facilityData} setModel={setFacilityData} />
              </Panel>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={11} lg={12} md={24} sm={24} xs={24}>
              <Panel
                header={<h5>{t('facilityDatas.view.dataConsumptionPanelTitle')}</h5>}
                style={{ marginBottom: 20 }}
              >
                <DataConsumptionForm
                  facilityData={facilityData}
                  setFacilityData={setFacilityData}
                  setHasBlurred={setHasBlurred}
                />
              </Panel>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={11} lg={12} md={24} sm={24} xs={24}>
              <Panel
                header={
                  <h5>
                    <span
                      style={{
                        display: !noCommutationTypes || authorized ? 'block' : 'iniline',
                        marginRight: 10,
                      }}
                    >
                      {t('facilityDatas.view.commutationsPanelTitle')}&nbsp;
                    </span>
                    <span style={{ display: 'block' }}>
                      {formatDetailsUnitValue('commutations')}
                    </span>
                  </h5>
                }
                style={{ marginBottom: 20 }}
              >
                <Commutation
                  facilityDataId={originalFacilityData.id}
                  refecthComputedValue={refetch}
                  commutationTypes={commutationTypes}
                  noCommutationTypes={noCommutationTypes}
                  commutationsCarbonValue={formatDetailsUnitValue('commutations')}
                />
              </Panel>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={11} lg={12} md={24} sm={24} xs={24}>
              <Panel
                header={
                  <h5>
                    <span style={{ display: 'block', marginRight: 10 }}>
                      {t('facilityDatas.view.dietsPanelTitle')}
                    </span>
                    <span style={{ display: 'block' }}>{formatDetailsUnitValue('diets')}</span>
                  </h5>
                }
                style={{ marginBottom: 20 }}
              >
                <Diet
                  facilityDataId={originalFacilityData.id}
                  refecthComputedValue={refetch}
                  dietTypes={dietTypes}
                  noDietTypes={noDietTypes}
                  dietsCarbonValue={formatDetailsUnitValue('diets')}
                />
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        )}
      </TabbedContainer>
    </div>
  )
}
