export const colors = [
  { name: 'Electricity', color: '#ff9900' },
  { name: 'Heating', color: '#fce5cd' },
  { name: 'Cooling', color: '#f9cb9c' },
  {
    name: 'Datacenter',
    color: '#f6b26b',
  },
  { name: 'Diets', color: '#b6d7a8' },
  { name: 'Trips', color: '#b7b7b7' },
  { name: 'Streaming', color: '#a569bd' },
  { name: 'Commutations', color: '#666666' },
  { name: 'DepreciationTower', color: '#cfe2f3' },
  { name: 'DepreciationServer', color: '#9fc5e8' },
  { name: 'DepreciationScreen', color: '#3d85c6' },
  { name: 'DepreciationOthers', color: '#f4d03f' },
  { name: 'Others', color: '#19A890' },
]
