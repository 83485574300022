/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState, store } from 'services/store/store'
import { auth } from 'services/authentication/localStorageToken'
import { StudioUser, Token, User } from 'types'
import api from 'services/api'
import { baseQueryWithReauth } from 'services/api/baseQuery'
import { userRoles } from 'services/constants/userRoles'

export type AuthState = {
  user: User | null
  auth: {
    token: Token | null
    authenticated: boolean
  }
}

const slice = createSlice({
  name: 'authentication',
  initialState: () => {
    const tokens = auth.getTokens()
    return {
      user: null,
      auth: {
        authenticated: Boolean(tokens),
        token: tokens,
      },
    } as AuthState
  },
  reducers: {
    setToken: (state, { payload: token }: PayloadAction<Token>) => {
      if (token) {
        state.auth.authenticated = true
        state.auth.token = token
      } else {
        state.auth.authenticated = false
      }
    },
    unsetToken: (state) => {
      state.auth.token = null
      state.auth.authenticated = false
    },

    setUser: (state, { payload: user }: PayloadAction<User | null>) => {
      state.user = user
    },
  },
})

const getUser = (initialState?: RootState): User => {
  const state: RootState = initialState || store.getState()
  return state.authentication.user
}

const getRole = (initialState?: RootState): keyof typeof userRoles | undefined => {
  const state: RootState = initialState || store.getState()
  return state.authentication.user?.role
}

const setUser = async (user: User | null) => {
  const finalUser = { ...user } as User

  if (user) {
    const companyUser = await baseQueryWithReauth(
      {
        url: '/companyUsers',
        params: { user: user.id },
      },
      api as any,
      {},
    )

    const studioUser: StudioUser = (companyUser.data as any)[0]

    finalUser.role = studioUser.role as keyof typeof userRoles
    finalUser.isAdmin = studioUser.isAdmin
  }
  store.dispatch(slice.actions.setUser(finalUser))
}

const getToken = (initialState?: RootState): AuthState['auth'] => {
  const state: RootState = initialState || store.getState()
  return state.authentication.auth
}

const setToken = (tokens: Token) => {
  store.dispatch(slice.actions.setToken(tokens))
  localStorage.setItem('token', JSON.stringify(tokens))
}

const unsetToken = () => {
  store.dispatch(slice.actions.unsetToken())
  localStorage.removeItem('token')
}

export { getToken, setToken, unsetToken, getUser, setUser, getRole }

export default slice
