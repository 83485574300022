import { t } from 'i18next'
import { CSSProperties } from 'react'

/**
 * Indexes of array are the value
 */
export const scheduleTypes = ['Progressive', 'Constant', 'Continuous'] as const

const scheduleTypeShapes = (
  width: number,
  height: number,
  color: string,
): Record<typeof scheduleTypes[number], CSSProperties> => ({
  Progressive: {
    width,
    height,
    transform: 'skew(-40deg)',
    backgroundColor: color,
  },
  Constant: {
    minWidth: width,
    minHeight: height,
    backgroundColor: color,
  },
  Continuous: {
    width: 0,
    height: 0,
    borderBottom: `${height}px solid ${color}`,
    borderLeft: `${width}px solid transparent`,
  },
})

export const scheduleTypeOptions = scheduleTypes.map((label, value) => ({
  label: (
    <div className="flex row alignCenter">
      <div className="flex center" style={{ width: 40 }}>
        <div style={scheduleTypeShapes(20, 15, 'var(--rs-gray-500)')[label]} />
      </div>
      <span className="marginRight8">{t(`constants.scheduleTypes.${label}`)}</span>
    </div>
  ),
  value,
}))
