import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { FacilityHardware } from 'types'
import { FacilityHardwareForm } from './FacilityHardwareForm'

type ModalAddFacilityHardwareProps = ButtonProps

export function ButtonAddFacilityHardware(buttonProps: ModalAddFacilityHardwareProps) {
  const { t } = useTranslation()
  const [facilityHardware, setFacilityHardware] = useState<Partial<FacilityHardware> | null>(null)
  const [modalFacilityHardwareIsOpen, setModalFacilityHardwareIsOpen] = useState<boolean>(false)
  const [createFacilityHardware] = api.facilityHardware.create()

  async function addFacilityHardware() {
    if (facilityHardware) {
      await createFacilityHardware({ data: facilityHardware })
    }
    setModalFacilityHardwareIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalFacilityHardwareIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} appearance="primary" color="blue" {...buttonProps}>
        <FontAwesomeIcon icon="plus" className="marginRight10" /> {t('facilityHardware.addButton')}
      </Button>
      <Modal
        open={modalFacilityHardwareIsOpen}
        onClose={() => setModalFacilityHardwareIsOpen(false)}
        onExited={() => setFacilityHardware(null)}
        onValidate={addFacilityHardware}
        onCancel={() => Promise.resolve(setModalFacilityHardwareIsOpen(false))}
        title={t('facilityHardware.addButton')}
      >
        <FacilityHardwareForm
          facilityHardware={facilityHardware}
          setFacilityHardware={setFacilityHardware}
        />
      </Modal>
    </>
  )
}
