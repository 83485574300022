/* eslint-disable react/prop-types */
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useComputedValue } from 'hooks/useComputedValue'
import api from 'services/api'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'

export type StudioCarbonCostProps = {
  width?: number
  height?: number
}

type TranslationNameType = 'ProductionsPreviz' | 'ProductionsReel' | 'Facilities'

export function StudioCarbonCost(props: StudioCarbonCostProps) {
  const { width = 200, height = 350 } = props

  const { computedValue: facilitiesComputedValue } = useComputedValue<'facilities'>({
    type: 'facilities',
  })
  const { computedValue: productionsComputedValue } = useComputedValue<'productions'>({
    type: 'productions',
  })

  const { t } = useTranslation()

  const { data: productions = [] } = api.productions.readAll()
  const { data: facilityDatas = [] } = api.facilityDatas.readAll()

  const productionsDatas = productions.flatMap((production) => {
    if (productionsComputedValue !== null) {
      const { byYear } = productionsComputedValue[production.id]
      return map(byYear, (value, key) => ({
        year: key,
        prodReel: value.real,
        prodPreviz: value.previz,
      }))
    }
    return {
      year: '',
      prodReel: 0,
      prodPreviz: 0,
    }
  })

  const facilitiesDatas = facilityDatas.map((facility) => {
    if (facilitiesComputedValue !== null) {
      const real = facilitiesComputedValue[facility.facility][facility.year]
      return {
        year: facility.year.toString(),
        facilityReel: real,
      }
    }
    return {
      year: '',
      facilityReel: 0,
    }
  })

  type AllDatas = {
    year: string
    facilityReel: number
    prodReel: number
    prodPreviz: number
  }[]

  const allDatas: AllDatas = productionsDatas
    .map((data) => ({ ...data, facilityReel: 0 }))
    .concat(facilitiesDatas.map((data) => ({ ...data, prodReel: 0, prodPreviz: 0 })))
    .reduce((acc, data) => {
      const cc = acc.findIndex((e) => e.year === data.year)
      if (cc === -1) {
        acc.push({
          year: data.year,
          facilityReel: data.facilityReel,
          prodReel: data.prodReel,
          prodPreviz: data.prodPreviz,
        })
      } else {
        acc[cc].facilityReel += data.facilityReel
        acc[cc].prodReel += data.prodReel
        acc[cc].prodPreviz += data.prodPreviz
      }
      return acc
    }, [] as AllDatas)
    .sort((a, b) => Number(a?.year) - Number(b?.year))

  const chartDatas = allDatas
    .map((data) => {
      if (facilitiesDatas !== null && productionsDatas !== null) {
        return {
          year: data?.year,
          ProductionsPreviz: data?.prodPreviz ? data.prodPreviz / 1000 : 0,
          ProductionsReel: data?.prodReel ? data.prodReel / 1000 : 0,
          Facilities: data?.facilityReel ? data.facilityReel / 1000 : 0,
        }
      }
      return {
        year: '',
        ProductionsPreviz: 0,
        ProductionsReel: 0,
        Facilities: 0,
      }
    })
    .slice(Math.max(allDatas.length - 5, 0))

  const customTick = (props: {
    width: number
    height: number
    x: number
    y: number
    payload: { value: string }
  }) => {
    const { width, height, x, y, payload } = props
    return (
      <foreignObject
        style={{
          fontSize: 14,
          width: width / chartDatas.length,
          height,
          marginLeft: 150,
        }}
        x={x}
        y={y}
        offset={15}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>{payload?.value}</div>
      </foreignObject>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={width}
        height={height}
        data={chartDatas}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 40,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="year" scale="band" tick={customTick} />
        <YAxis
          yAxisId="left"
          orientation="left"
          label={{ value: 't CO2eq', angle: -90, position: 'insideLeft', offset: -35 }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{ value: 't CO2eq / min', angle: -90, position: 'right', offset: 35 }}
          stroke="#D3756A"
        />
        <Bar yAxisId="left" dataKey="Facilities" stackId="a" barSize={50} fill="#1675E0" />
        <Bar
          yAxisId="left"
          dataKey="Facilities"
          stackId="b"
          barSize={50}
          fill="#1675E0"
          legendType="none"
          tooltipType="none"
        />
        <Bar yAxisId="left" dataKey="ProductionsPreviz" stackId="a" barSize={50} fill="#FFC90E" />
        <Bar yAxisId="left" dataKey="ProductionsReel" stackId="b" barSize={50} fill="#FF7F27" />
        <Tooltip
          formatter={(value, name: TranslationNameType) => [
            `${Math.round(Number(value) * 100) / 100} ${t(`studio.graph.unit${name}`)}`,
            `${t(`studio.graph.${name}`)}`,
          ]}
          position={{ x: 10, y: 0 }}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <Legend formatter={(value: TranslationNameType) => `${t(`studio.graph.${value}`)}`} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
