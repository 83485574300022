import { Modal } from 'components'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { ComputerRig } from 'types'
import { ComputerRigsForm } from './ComputerRigsForm'

type ModalAddComputerRigsProps = ButtonProps

export function ButtonAddComputerRigs(props: ModalAddComputerRigsProps) {
  const { t } = useTranslation()
  const [computerRig, setComputerRig] = useState<Partial<ComputerRig> | null>(null)
  const [modalComputerRigIsOpen, setModalComputerRigIsOpen] = useState<boolean>(false)

  const [createComputerRig] = api.computerRig.create()
  const { data: studios = [] } = api.studios.readAll()

  async function addComputerRig() {
    if (computerRig) await createComputerRig({ data: { ...computerRig, company: studios[0].id } })
    setModalComputerRigIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalComputerRigIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} appearance="primary" {...props}>
        <DoubleIcon icons={['computer', 'plus']} className="marginRight10" />{' '}
        {t('computerRig.addButton')}
      </Button>
      <Modal
        open={modalComputerRigIsOpen}
        onClose={() => setModalComputerRigIsOpen(false)}
        onExited={() => setComputerRig(null)}
        onValidate={addComputerRig}
        onCancel={() => Promise.resolve(setModalComputerRigIsOpen(false))}
        title={t('computerRig.addButton')}
      >
        <ComputerRigsForm computerRig={computerRig} setComputerRig={setComputerRig} />
      </Modal>
    </>
  )
}
