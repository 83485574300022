import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { CloudSupplierProfile } from 'types'
import { CloudSupplierProfileForm } from './CloudSupplierProfileForm'

type ModalAddCloudSupplierProfileProps = ButtonProps

export function ButtonAddCloudSupplierProfile(props: ModalAddCloudSupplierProfileProps) {
  const { t } = useTranslation()
  const [cloudSupplierProfile, setCloudSupplierProfile] =
    useState<Partial<CloudSupplierProfile> | null>(null)
  const [modalCloudSupplierProfileIsOpen, setModalCloudSupplierProfileIsOpen] =
    useState<boolean>(false)
  const [createCloudSupplierProfile] = api.cloudSupplierProfile.create()

  async function addCloudSupplierProfile() {
    if (cloudSupplierProfile) await createCloudSupplierProfile({ data: cloudSupplierProfile })
    setModalCloudSupplierProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalCloudSupplierProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <FontAwesomeIcon icon="cloud" className="marginRight10" />{' '}
        {t('cloudSupplierProfile.addButton')}
      </Button>
      <Modal
        open={modalCloudSupplierProfileIsOpen}
        onClose={() => setModalCloudSupplierProfileIsOpen(false)}
        onExited={() => setCloudSupplierProfile(null)}
        onValidate={addCloudSupplierProfile}
        onCancel={() => Promise.resolve(setModalCloudSupplierProfileIsOpen(false))}
        title={t('cloudSupplierProfile.addButton')}
      >
        <CloudSupplierProfileForm
          cloudSupplierProfile={cloudSupplierProfile}
          setCloudSupplierProfile={setCloudSupplierProfile}
        />
      </Modal>
    </>
  )
}
