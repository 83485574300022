import { t } from 'i18next'
import { map } from 'lodash'
import {
  ComposedChart,
  Line,
  Bar as ChartBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { randomColors } from 'styles/colors'

export type FacilitiesReportProps = {
  computedValue: any
  facilitiesDatas: any
  studioFacilities?: any
  width?: number
  height?: number
}

export function FacilitiesReport(props: FacilitiesReportProps) {
  const { studioFacilities, facilitiesDatas, computedValue, width = 200, height = 350 } = props
  const years = [
    // @ts-ignore
    ...new Set(
      map(computedValue, (facility) => Object.keys(facility))
        .flat()
        .slice(-6)
        .sort(),
    ),
  ]

  const chartDatas = years.map((year) => {
    const yearDatas: any = {
      name: year,
    }
    studioFacilities?.forEach(({ name, id }: any) => {
      const facilityDatas = facilitiesDatas.find(
        (el: any) => el.facility === id && el.year === Number(year),
      )
      yearDatas[name] = computedValue[id][year] / 1000 || 0
      // eslint-disable-next-line no-unsafe-optional-chaining
      yearDatas[`${name}_pday`] = computedValue[id][year] / 1000 / facilityDatas?.overallFte || 0
    })
    return yearDatas
  })

  const keys = chartDatas.length
    ? Object.keys((chartDatas as any)?.[0]).filter((key) => key !== 'name')
    : []
  const keysPday = keys.filter((key) => key.endsWith('pday'))
  const facilities = keys.filter((key) => !key.endsWith('pday'))

  const customTick = (props: {
    width: number
    height: number
    x: number
    y: number
    payload: { value: string }
  }) => {
    // eslint-disable-next-line react/prop-types
    const { width, height, x, y, payload } = props
    return (
      <foreignObject
        style={{
          fontSize: 14,
          width: width / years.length,
          height,
          marginLeft: 150,
        }}
        x={x}
        y={y}
        offset={15}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* eslint-disable-next-line react/prop-types */}
          {payload?.value}
        </div>
      </foreignObject>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={width}
        height={height}
        data={chartDatas}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" tick={customTick} />
        <YAxis
          yAxisId="left"
          orientation="left"
          label={{ value: 't CO2eq', angle: -90, position: 'insideLeft', offset: -15 }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: t('misc.units.kgEqCo2PerPersonPerDay'),
            angle: -90,
            position: 'insideBottomLeft',
            offset: 55,
          }}
        />
        {studioFacilities.length > 1
          ? facilities.map((facility, index) => (
              <ChartBar
                yAxisId="left"
                dataKey={facility}
                key={facility}
                barSize={40}
                fill={randomColors[index % randomColors.length]}
              />
            ))
          : null}
        {keysPday.map((key, index) => (
          <Line
            yAxisId="right"
            dataKey={key}
            key={key}
            type="monotone"
            stroke={randomColors[index % randomColors.length]}
          />
        ))}
        <Tooltip
          formatter={(value, name: string) =>
            `${Math.round(Number(value) * 100) / 100} ${
              name.endsWith('pday') ? t('misc.units.kgEqCo2PerPersonPerDay') : 't CO2eq'
            }`
          }
        />
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
