import { useRef, useState } from 'react'
import { Button, Form, FormInstance, Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { InputCustom } from 'components'
import api from 'services/api'
import { User } from 'types'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import classes from './SignUp.module.css'
import { signUpValidationForm } from './signUpValidationForm'

export function SignUp() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [signUp, setSignUp] = useState<Partial<User>>({
    username: '',
    password: '',
    email: '',
  })
  const [errors, setErrors] = useState<string[] | void>()

  const formRef = useRef<FormInstance>()

  const [createUser, { isLoading }] = api.users.create()
  const [getToken, { isLoading: tokenLoading }] = api.token.getToken()

  async function handleSignUp() {
    if (formRef.current?.check()) {
      try {
        await createUser({ data: signUp }).unwrap()
        if (signUp.username && signUp.password) {
          await getToken({ username: signUp.username, password: signUp.password }).unwrap()
          navigate('/')
        }
      } catch (error: any) {
        if (error.data) {
          setErrors(map(error.data, (value) => value))
        }
      }
    }
  }

  return (
    <div className="fullWidth fullHeight flex center alignCenter">
      <div className={classes.container}>
        <Panel header={t('signUp.title')} className="rs-panel-large-header" shaded>
          <Form
            fluid
            ref={(ref: FormInstance) => {
              formRef.current = ref
            }}
            onChange={setSignUp}
            onSubmit={handleSignUp}
            formValue={signUp}
            model={signUpValidationForm}
            onCheck={() => setErrors()}
          >
            <Form.Group>
              <Form.ControlLabel>{t('signUp.username')}</Form.ControlLabel>
              <Form.Control name="username" type="string" accepter={InputCustom} />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>{t('signUp.email')}</Form.ControlLabel>
              <Form.Control name="email" type="email" accepter={InputCustom} />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>{t('signUp.password')}</Form.ControlLabel>
              <Form.Control name="password" type="password" accepter={InputCustom} />
            </Form.Group>

            {errors ? errors.map((error) => <div className={classes.error}>{error}</div>) : null}

            <div className="flex end alignCenter marginTop20">
              <Button appearance="primary" type="submit" disabled={isLoading || tokenLoading}>
                {t('signUp.signupButton')}
              </Button>
            </div>
            <div className="flex fullWidth end paddingTop20">
              <a href="/login">{t('signUp.backLoginPage')}</a>
            </div>
          </Form>
        </Panel>
      </div>
    </div>
  )
}
