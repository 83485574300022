import { useEffect, useRef } from 'react'
import PubSub from 'pubsub-js'
import { Notification, useToaster } from 'rsuite'
import type { NotificationProps } from 'rsuite/esm/Notification/Notification'

type Props = {
  children: React.ReactElement
}

type NotificationParams = NotificationProps & {
  message: JSX.Element | JSX.Element[]
  subMessage?: JSX.Element | JSX.Element[] | string | string[]
}

const NOTIFICATION_SUB_ID = 'NOTIFY'

export function Notifications(props: Props) {
  const { children } = props

  const toaster = useToaster()

  const sub = useRef<string>()

  let index = 0

  function messageComponent(notification: NotificationParams) {
    const { type, message, subMessage, ...rest } = notification

    index += 1

    return (
      <Notification key={index} type={type} header={<h6>{message}</h6>} closable {...rest}>
        {subMessage}
      </Notification>
    )
  }

  function onRequestNoftification(notificationID: string, notification: NotificationParams) {
    toaster.push(messageComponent(notification), { placement: 'bottomStart' })
  }

  useEffect(() => {
    sub.current = PubSub.subscribe(NOTIFICATION_SUB_ID, onRequestNoftification)
    return () => {
      PubSub.unsubscribe(sub.current as string)
    }
  })

  return children
}

export const notifications = {
  notify(
    message: JSX.Element | JSX.Element[] | string | string[],
    params?: Omit<NotificationParams, 'message'>,
  ) {
    PubSub.publish(NOTIFICATION_SUB_ID, { message, type: 'info', ...params })
  },
  warning(
    message: JSX.Element | JSX.Element[] | string | string[],
    params?: Omit<NotificationParams, 'message'>,
  ) {
    PubSub.publish(NOTIFICATION_SUB_ID, { message, type: 'warning', ...params })
  },
  error(
    message: JSX.Element | JSX.Element[] | string | string[],
    params?: Omit<NotificationParams, 'message'>,
  ) {
    PubSub.publish(NOTIFICATION_SUB_ID, { message, type: 'error', ...params })
  },
  success(
    message: JSX.Element | JSX.Element[] | string | string[],
    params?: Omit<NotificationParams, 'message'>,
  ) {
    PubSub.publish(NOTIFICATION_SUB_ID, { message, type: 'success', ...params })
  },
}
