import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from 'services/store/store'
import { MainRoutes } from 'routes/MainRoutes'
import { Notifications } from 'components'
import { FormFieldInformations } from 'components/FormFieldInformations/FormFieldInformations'

export function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <FormFieldInformations />
        <Notifications>
          <MainRoutes />
        </Notifications>
        <div id="modals" />
      </BrowserRouter>
    </Provider>
  )
}
