import { Modal } from 'components'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { CountryProfile } from 'types'
import { CountryProfileForm } from './CountryProfileForm'

type ModalAddCountryProfileProps = ButtonProps

export function ButtonAddCountryProfile(props: ModalAddCountryProfileProps) {
  const { t } = useTranslation()

  const [countryProfile, setCountryProfile] = useState<Partial<CountryProfile> | null>(null)
  const [modalCountryProfileIsOpen, setModalCountryProfileIsOpen] = useState<boolean>(false)
  const [createCountryProfile] = api.countryProfiles.create()

  async function addCountryProfile() {
    if (countryProfile) await createCountryProfile({ data: countryProfile })
    setModalCountryProfileIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalCountryProfileIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <DoubleIcon icons={['earth-americas', 'circle-info']} className="marginRight10" />{' '}
        {t('countryProfile.addButton')}
      </Button>
      <Modal
        open={modalCountryProfileIsOpen}
        onClose={() => setModalCountryProfileIsOpen(false)}
        onExited={() => setCountryProfile(null)}
        onValidate={addCountryProfile}
        onCancel={() => Promise.resolve(setModalCountryProfileIsOpen(false))}
        title={t('countryProfile.addButton')}
      >
        <CountryProfileForm countryProfile={countryProfile} setCountryProfile={setCountryProfile} />
      </Modal>
    </>
  )
}
