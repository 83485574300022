import { useEffect, useRef, useState } from 'react'
import { setIsUpdating } from 'services/reducers/computedValuesReducer'
import { useAppDispatch } from './store'

type useAutoSaveParams = {
  originalValue?: Object | null
  diffValue?: Object | null
  save?: () => void
  updateComputedData?: () => void
  /**
   * Timer in second
   */
  timeout?: number
}

export function useAutoSave(params: useAutoSaveParams) {
  const dispatch = useAppDispatch()
  const { originalValue, diffValue, save, updateComputedData, timeout } = params || {}

  const [dataHasChange, setDataHasChange] = useState<boolean>(false)
  const [hasBlurred, setHasBlurred] = useState<boolean>(false)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const computedDataTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  function updateComputedValueWithTimeout() {
    if (!updateComputedData || !timeout || timeout < 1) return
    dispatch(setIsUpdating(true))
    if (computedDataTimeoutRef.current) clearTimeout(computedDataTimeoutRef.current)
    computedDataTimeoutRef.current = setTimeout(() => {
      updateComputedData()
      dispatch(setIsUpdating(false))
    }, timeout * 100)
  }

  function saveTimeout() {
    if (!save || !timeout || timeout < 1) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      save()
    }, timeout * 1000)
  }

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    if (JSON.stringify(originalValue) !== JSON.stringify(diffValue)) {
      setDataHasChange(true)
      if (save && hasBlurred) {
        save()
        setHasBlurred(false)
        updateComputedValueWithTimeout()
      } else if (save && timeout && timeout > 0) {
        saveTimeout()
      }
    } else {
      setDataHasChange(false)
      setHasBlurred(false)
    }
  }, [originalValue, diffValue, hasBlurred])

  return { dataHasChange, setDataHasChange, setHasBlurred }
}
