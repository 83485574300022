import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, SelectPicker } from 'rsuite'
import { userRoles } from 'services/constants/userRoles'
import { generateRandomPassword } from 'utils/generateRandomPassword'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { FormUser } from './ButtonAddStudioUser'

type StudioUsersTableFormProps = {
  studioUser: FormUser | null
  setStudioUser: (studioUser: FormUser) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function StudioUsersTableForm(props: StudioUsersTableFormProps) {
  const { t } = useTranslation()
  const { studioUser, setStudioUser, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  function generatePassword() {
    setStudioUser({
      ...studioUser,
      password: generateRandomPassword(),
    })
  }

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setStudioUser}
      onCheck={setFormError}
      formValue={studioUser || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('studioUser.form.username')}
          <FormTooltip tooltip>{t('studioUser.form.username_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="username"
          accepter={InputCustom}
          tooltip-id="studioUser.form.username_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('studioUser.form.email')}
          <FormTooltip tooltip>{t('studioUser.form.email_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="email"
          accepter={InputCustom}
          tooltip-id="studioUser.form.email_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('studioUser.form.password')}
          <FormTooltip tooltip>{t('studioUser.form.password_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="password"
          accepter={InputCustom}
          tooltip-id="studioUser.form.password_FieldInformation"
        />
        <div className="fullWidth flex end">
          <Button
            color="green"
            appearance="primary"
            onClick={generatePassword}
            className="marginTop10"
          >
            {t('studioUser.generateRandomPasswordButton')}
          </Button>
        </div>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('studioUser.form.userRole')}
          <FormTooltip tooltip>{t('studioUser.form.userRole_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="role"
          accepter={SelectPicker}
          data={Object.keys(userRoles).map((ur) => ({
            value: ur,
            label: t(`constants.userRoles.${ur as keyof typeof userRoles}`),
          }))}
          style={{ width: '100%', marginBottom: 20 }}
          tooltip-id="studioUser.form.userRole_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
