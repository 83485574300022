import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { createCRUDApi } from 'services/api/createCRUDApi'
import type {
  Studio,
  Facility,
  DietType,
  Production,
  ComputerRig,
  CommutationType,
  CommutationProfile,
  CountryProfile,
  DietProfile,
  Document,
  EnergySupplierProfile,
  EnergySupplierType,
  Department,
  CloudSupplierProfile,
  StudioUser,
  ComputerRigHardware,
  DepartmentGroup,
  DepartmentGroupData,
  DepartmentGroupServerUsage,
  FacilityData,
  FacilityHardware,
  HardwareProfile,
  User,
} from 'types'
import { authApi } from './endpoints/authentication'
import authenticationSlice from '../authentication/authReducer'
import computedValuesSlice from '../reducers/computedValuesReducer'
import { dietsApi } from './endpoints/diets'
import { commutationsApi } from './endpoints/commutations'
import { errorsHandling } from './errorsHandling'
import { computeApi } from './endpoints/compute'

export const apis = {
  token: authApi,
  cloudSupplierProfile: createCRUDApi<CloudSupplierProfile>({
    tagName: 'CloudSupplierProfile',
    endpoint: 'cloudSupplierProfile',
  }),
  compute: computeApi,
  commutations: commutationsApi,
  commutationProfiles: createCRUDApi<CommutationProfile>({
    tagName: 'CommutationProfiles',
    endpoint: 'commutationProfiles',
  }),
  commutationTypes: createCRUDApi<CommutationType>({
    tagName: 'CommutationTypes',
    endpoint: 'commutationTypes',
  }),
  computerRig: createCRUDApi<ComputerRig>({
    tagName: 'ComputerRig',
    endpoint: 'computerRig',
  }),
  computerRigHardware: createCRUDApi<ComputerRigHardware>({
    tagName: 'ComputerRigHardware',
    endpoint: 'computerRigHardware',
  }),
  countryProfiles: createCRUDApi<CountryProfile>({
    tagName: 'CountryProfiles',
    endpoint: 'countryProfiles',
  }),
  departments: createCRUDApi<Department>({ tagName: 'Department', endpoint: 'department' }),
  departmentGroups: createCRUDApi<DepartmentGroup>({
    tagName: 'DepartmentGroups',
    endpoint: 'departmentGroups',
  }),
  departmentGroupDatas: createCRUDApi<DepartmentGroupData>({
    tagName: 'DepartmentGroupDatas',
    endpoint: 'departmentGroupDatas',
  }),
  departmentGroupServerUsage: createCRUDApi<DepartmentGroupServerUsage>({
    tagName: 'DepartmentGroupServerUsage',
    endpoint: 'departmentGroupServerUsage',
  }),
  diets: dietsApi,
  dietProfiles: createCRUDApi<DietProfile>({ tagName: 'DietProfiles', endpoint: 'dietProfiles' }),
  dietTypes: createCRUDApi<DietType>({ tagName: 'DietTypes', endpoint: 'dietTypes' }),
  documents: createCRUDApi<Document>({ tagName: 'Documents', endpoint: 'documents' }),
  energySupplierProfiles: createCRUDApi<EnergySupplierProfile>({
    tagName: 'EnergySupplierProfiles',
    endpoint: 'energySupplierProfiles',
  }),
  energySupplierTypes: createCRUDApi<EnergySupplierType>({
    tagName: 'EnergySupplierTypes',
    endpoint: 'energyTypes',
  }),
  facilities: createCRUDApi<Facility>({ tagName: 'Facilities', endpoint: 'facilities' }),
  facilityDatas: createCRUDApi<FacilityData>({
    tagName: 'FacilityData',
    endpoint: 'facilityDatas',
  }),
  facilityHardware: createCRUDApi<FacilityHardware>({
    tagName: 'DacilityHardware',
    endpoint: 'facilityHardware',
  }),
  hardwareProfiles: createCRUDApi<HardwareProfile>({
    tagName: 'HardwareProfile',
    endpoint: 'hardwareProfiles',
  }),
  studios: createCRUDApi<Studio>({ tagName: 'Studios', endpoint: 'companies' }),
  studioUsers: createCRUDApi<StudioUser>({ tagName: 'StudioUser', endpoint: 'companyUsers' }),
  productions: createCRUDApi<Production>({ tagName: 'Production', endpoint: 'productions' }),
  users: createCRUDApi<User>({ tagName: 'Auth', endpoint: 'auth/users' }),
}

export const reducers = reduce(
  apis,
  (reducers, api) => ({ ...reducers, [api.reducerPath]: api.reducer }),
  {
    [authenticationSlice.name]: authenticationSlice.reducer,
    [computedValuesSlice.name]: computedValuesSlice.reducer,
  },
)

export const middlewares = map(apis, (api) => api.middleware).concat([errorsHandling])

const api = {
  compute: {
    ...apis.compute,
    calculate: apis.compute.useCalculateQuery,
  },
  countryProfiles: {
    ...apis.countryProfiles,
    create: apis.countryProfiles.useCreateMutation,
    read: apis.countryProfiles.useReadQuery,
    readAll: apis.countryProfiles.useReadAllQuery,
    update: apis.countryProfiles.useUpdateMutation,
    delete: apis.countryProfiles.useDeleteMutation,
  },
  departments: {
    ...apis.departments,
    create: apis.departments.useCreateMutation,
    read: apis.departments.useReadQuery,
    readAll: apis.departments.useReadAllQuery,
    update: apis.departments.useUpdateMutation,
    delete: apis.departments.useDeleteMutation,
  },
  dietProfiles: {
    ...apis.dietProfiles,
    create: apis.dietProfiles.useCreateMutation,
    read: apis.dietProfiles.useReadQuery,
    readAll: apis.dietProfiles.useReadAllQuery,
    update: apis.dietProfiles.useUpdateMutation,
    delete: apis.dietProfiles.useDeleteMutation,
  },
  energySupplierProfiles: {
    ...apis.energySupplierProfiles,
    create: apis.energySupplierProfiles.useCreateMutation,
    read: apis.energySupplierProfiles.useReadQuery,
    readAll: apis.energySupplierProfiles.useReadAllQuery,
    update: apis.energySupplierProfiles.useUpdateMutation,
    delete: apis.energySupplierProfiles.useDeleteMutation,
  },
  energySupplierTypes: {
    ...apis.energySupplierTypes,
    create: apis.energySupplierTypes.useCreateMutation,
    read: apis.energySupplierTypes.useReadQuery,
    readAll: apis.energySupplierTypes.useReadAllQuery,
    update: apis.energySupplierTypes.useUpdateMutation,
    delete: apis.energySupplierTypes.useDeleteMutation,
  },
  studios: {
    ...apis.studios,
    create: apis.studios.useCreateMutation,
    read: apis.studios.useReadQuery,
    readAll: apis.studios.useReadAllQuery,
    update: apis.studios.useUpdateMutation,
    delete: apis.studios.useDeleteMutation,
  },
  commutations: {
    ...apis.commutations,
    create: apis.commutations.useCreateCommutationMutation,
    read: apis.commutations.useReadCommutationQuery,
    readAll: apis.commutations.useReadAllCommutationQuery,
    update: apis.commutations.useUpdateCommutationMutation,
    delete: apis.commutations.useDeleteCommutationMutation,
    addCommutationValue: apis.commutations.useAddCommutationValuesMutation,
    deleteAllCommutations: apis.commutations.useDeleteAllCommutationsMutation,
  },
  commutationProfiles: {
    ...apis.commutationProfiles,
    create: apis.commutationProfiles.useCreateMutation,
    read: apis.commutationProfiles.useReadQuery,
    readAll: apis.commutationProfiles.useReadAllQuery,
    update: apis.commutationProfiles.useUpdateMutation,
    delete: apis.commutationProfiles.useDeleteMutation,
  },
  commutationTypes: {
    ...apis.commutationTypes,
    create: apis.commutationTypes.useCreateMutation,
    read: apis.commutationTypes.useReadQuery,
    readAll: apis.commutationTypes.useReadAllQuery,
    update: apis.commutationTypes.useUpdateMutation,
    delete: apis.commutationTypes.useDeleteMutation,
  },
  diets: {
    ...apis.diets,
    create: apis.diets.useCreateDietMutation,
    read: apis.diets.useReadDietQuery,
    readAll: apis.diets.useReadAllDietQuery,
    update: apis.diets.useUpdateDietMutation,
    delete: apis.diets.useDeleteDietMutation,
    addDietValues: apis.diets.useAddDietValuesMutation,
    deleteAllDiets: apis.diets.useDeleteAllDietsMutation,
  },
  dietTypes: {
    ...apis.dietTypes,
    create: apis.dietTypes.useCreateMutation,
    read: apis.dietTypes.useReadQuery,
    readAll: apis.dietTypes.useReadAllQuery,
    update: apis.dietTypes.useUpdateMutation,
    delete: apis.dietTypes.useDeleteMutation,
  },
  facilities: {
    ...apis.facilities,
    create: apis.facilities.useCreateMutation,
    read: apis.facilities.useReadQuery,
    readAll: apis.facilities.useReadAllQuery,
    update: apis.facilities.useUpdateMutation,
    delete: apis.facilities.useDeleteMutation,
  },
  computerRig: {
    ...apis.computerRig,
    create: apis.computerRig.useCreateMutation,
    read: apis.computerRig.useReadQuery,
    readAll: apis.computerRig.useReadAllQuery,
    update: apis.computerRig.useUpdateMutation,
    delete: apis.computerRig.useDeleteMutation,
  },
  users: {
    ...apis.users,
    create: apis.users.useCreateMutation,
    read: apis.users.useReadQuery,
    readAll: apis.users.useReadAllQuery,
    update: apis.users.useUpdateMutation,
    delete: apis.users.useDeleteMutation,
  },
  token: {
    ...apis.token,
    getToken: apis.token.useGetTokenMutation,
    refreshToken: apis.token.useRefreshTokenMutation,
    verifyToken: apis.token.useVerifyTokenMutation,
  },
  me: {
    ...apis.token,
    activation: apis.token.useActivationMutation,
    resendActivation: apis.token.useResendActivationMutation,

    setPassword: apis.token.useSetPasswordMutation,
    resetPassword: apis.token.useResetPasswordMutation,
    resetPasswordConfirm: apis.token.useResetPasswordConfirmMutation,

    setUsername: apis.token.useSetUsernameMutation,
    resetUsername: apis.token.useResetUsernameMutation,
    resetUsernameConfirm: apis.token.useResetUsernameConfirmMutation,

    readMe: apis.token.useReadMeQuery,
    updateMe: apis.token.useUpdateMeMutation,
    deleteMe: apis.token.useDeleteMeMutation,
  },
  cloudSupplierProfile: {
    ...apis.cloudSupplierProfile,
    create: apis.cloudSupplierProfile.useCreateMutation,
    read: apis.cloudSupplierProfile.useReadQuery,
    readAll: apis.cloudSupplierProfile.useReadAllQuery,
    update: apis.cloudSupplierProfile.useUpdateMutation,
    delete: apis.cloudSupplierProfile.useDeleteMutation,
  },
  computerRigHardware: {
    ...apis.computerRigHardware,
    create: apis.computerRigHardware.useCreateMutation,
    read: apis.computerRigHardware.useReadQuery,
    readAll: apis.computerRigHardware.useReadAllQuery,
    update: apis.computerRigHardware.useUpdateMutation,
    delete: apis.computerRigHardware.useDeleteMutation,
  },
  departmentGroups: {
    ...apis.departmentGroups,
    create: apis.departmentGroups.useCreateMutation,
    read: apis.departmentGroups.useReadQuery,
    readAll: apis.departmentGroups.useReadAllQuery,
    update: apis.departmentGroups.useUpdateMutation,
    delete: apis.departmentGroups.useDeleteMutation,
  },
  departmentGroupDatas: {
    ...apis.departmentGroupDatas,
    create: apis.departmentGroupDatas.useCreateMutation,
    read: apis.departmentGroupDatas.useReadQuery,
    readAll: apis.departmentGroupDatas.useReadAllQuery,
    update: apis.departmentGroupDatas.useUpdateMutation,
    delete: apis.departmentGroupDatas.useDeleteMutation,
  },
  departmentGroupServerUsage: {
    ...apis.departmentGroupServerUsage,
    create: apis.departmentGroupServerUsage.useCreateMutation,
    read: apis.departmentGroupServerUsage.useReadQuery,
    readAll: apis.departmentGroupServerUsage.useReadAllQuery,
    update: apis.departmentGroupServerUsage.useUpdateMutation,
    delete: apis.departmentGroupServerUsage.useDeleteMutation,
  },
  documents: {
    ...apis.documents,
    create: apis.documents.useCreateMutation,
    read: apis.documents.useReadQuery,
    readAll: apis.documents.useReadAllQuery,
    update: apis.documents.useUpdateMutation,
    delete: apis.documents.useDeleteMutation,
  },
  facilityDatas: {
    ...apis.facilityDatas,
    create: apis.facilityDatas.useCreateMutation,
    read: apis.facilityDatas.useReadQuery,
    readAll: apis.facilityDatas.useReadAllQuery,
    update: apis.facilityDatas.useUpdateMutation,
    delete: apis.facilityDatas.useDeleteMutation,
  },
  facilityHardware: {
    ...apis.facilityHardware,
    create: apis.facilityHardware.useCreateMutation,
    read: apis.facilityHardware.useReadQuery,
    readAll: apis.facilityHardware.useReadAllQuery,
    update: apis.facilityHardware.useUpdateMutation,
    delete: apis.facilityHardware.useDeleteMutation,
  },
  hardwareProfiles: {
    ...apis.hardwareProfiles,
    create: apis.hardwareProfiles.useCreateMutation,
    read: apis.hardwareProfiles.useReadQuery,
    readAll: apis.hardwareProfiles.useReadAllQuery,
    update: apis.hardwareProfiles.useUpdateMutation,
    delete: apis.hardwareProfiles.useDeleteMutation,
  },
  studioUsers: {
    ...apis.studioUsers,
    create: apis.studioUsers.useCreateMutation,
    read: apis.studioUsers.useReadQuery,
    readAll: apis.studioUsers.useReadAllQuery,
    update: apis.studioUsers.useUpdateMutation,
    delete: apis.studioUsers.useDeleteMutation,
  },
  productions: {
    ...apis.productions,
    create: apis.productions.useCreateMutation,
    read: apis.productions.useReadQuery,
    readAll: apis.productions.useReadAllQuery,
    update: apis.productions.useUpdateMutation,
    delete: apis.productions.useDeleteMutation,
  },
}

export default api
