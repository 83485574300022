import { useEffect, useMemo, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import PubSub from 'pubsub-js'
import { useTranslation } from 'react-i18next'
import notify, { useToaster } from 'react-hot-toast/headless'
import classes from './FormFieldInformations.module.scss'
import { FormFieldInformation } from './FormFieldInformation'

const FROMFIELD_SUB_ID = 'FROMFIELD_SUB_ID'

export function FormFieldInformations() {
  const { t } = useTranslation()
  const { toasts, handlers } = useToaster({ duration: 10000000 })
  const { startPause, endPause, updateHeight } = handlers

  const sub = useRef<string>()

  async function onRequestNoftification(notificationID: string, { key }: { key: string }) {
    notify(key)
  }

  useEffect(() => {
    sub.current = PubSub.subscribe(FROMFIELD_SUB_ID, onRequestNoftification)
    return () => {
      PubSub.unsubscribe(sub.current as string)
    }
  })

  const toastEl = useMemo(() => {
    const toast = toasts[0]

    if (!toast) return null

    const ref = (el: HTMLDivElement | null) => {
      if (el && typeof toast.height !== 'number') {
        const { height } = el.getBoundingClientRect()
        updateHeight(toast.id, height)
      }
    }

    const key = toast.message as string

    const title = t(key?.replace('_FieldInformation', '') as any)
    const content = t(key as any)

    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <div className={classes.info} key={toast.id} ref={ref} {...toast.ariaProps}>
        {' '}
        <FormFieldInformation
          tooltip={
            <>
              {title ? <div className={classes.notifHeader}>{title}</div> : null}
              {content ? (
                <ReactMarkdown>{content}</ReactMarkdown>
              ) : (
                <p style={{ fontStyle: 'italic' }}>{t('misc.tooltip.noInformation')}</p>
              )}
            </>
          }
        />
      </div>
    )
  }, [toasts])

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 50,
        right: 30,
        maxWidth: 300,
        zIndex: 10000,
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toastEl}
    </div>
  )
}

export function formFieldNotification(key: string | null) {
  PubSub.publish(FROMFIELD_SUB_ID, { key })
}
