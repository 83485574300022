import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { DietType } from 'types'
import { DietTypeForm } from './DietTypeForm'

type ModalAddDietTypeProps = ButtonProps

export function ButtonAddDietType(props: ModalAddDietTypeProps) {
  const { t } = useTranslation()
  const [dietType, setDietType] = useState<Partial<DietType> | null>(null)
  const [modalDietTypeIsOpen, setModalDietTypeIsOpen] = useState<boolean>(false)
  const [createDietType] = api.dietTypes.create()

  async function addDietType() {
    if (dietType) await createDietType({ data: dietType })
    setModalDietTypeIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalDietTypeIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <FontAwesomeIcon icon="burger" className="marginRight10" />{' '}
        {t('diet.settings.dietTypes.addButton')}
      </Button>
      <Modal
        open={modalDietTypeIsOpen}
        onClose={() => setModalDietTypeIsOpen(false)}
        onExited={() => setDietType(null)}
        onValidate={addDietType}
        onCancel={() => Promise.resolve(setModalDietTypeIsOpen(false))}
        title={t('diet.settings.dietTypes.addButton')}
      >
        <DietTypeForm dietType={dietType} setDietType={setDietType} />
      </Modal>
    </>
  )
}
