import { useTranslation } from 'react-i18next'

export function Welcome() {
  const { t } = useTranslation()
  return (
    <div className="fullWidth fullHeight flex center alignCenter grey fontSize18">
      {t('welcome.welcome')}
    </div>
  )
}
