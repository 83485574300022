import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRole } from 'services/authentication/authReducer'
import { userRoles } from 'services/constants/userRoles'

type UseRoleParams = {
  requiredRole?: keyof typeof userRoles
}

export function useRole(params: UseRoleParams) {
  const { requiredRole } = params
  const [authorized, setAuthorized] = useState(!requiredRole)

  const userRole = useSelector(getRole)

  function testRole(requiredRole?: keyof typeof userRoles) {
    if (!requiredRole) return true
    const roles = Object.keys(userRoles)
    const userLevel = roles.findIndex((r) => r === userRole)
    const requiredLevel = roles.findIndex((r) => r === requiredRole)
    return userLevel <= requiredLevel
  }

  useEffect(() => {
    setAuthorized(testRole(requiredRole))
  }, [requiredRole, userRole])

  return { authorized, testRole }
}
