import { Modal } from 'components'
import { ModalProps } from 'components/Modal/Modal'
import { notifications } from 'components/Notifications/Notifications'
import { useMemo, useState } from 'react'
import { Form, Input } from 'rsuite'
import api from 'services/api'
import { Commutation, CommutationValue, FacilityData, MetaDatas } from 'types'

type ModalAddCommutationListProps = Partial<ModalProps> & {
  facilityDataId: FacilityData['id']
  commutation: Commutation
}

export function ModalAddCommutationList(props: ModalAddCommutationListProps) {
  const {
    commutation,
    facilityDataId,
    onExited: propsOnExited,
    onClose: propsOnClose,
    ...modalProps
  } = props

  const [serverList, setCommutationList] = useState<string>('')

  const { data: commutationTypes = [] } = api.commutationTypes.readAll()
  const [createCommutationValue] = api.commutations.addCommutationValue()

  const commutationTypesNames = useMemo(
    () => commutationTypes?.map(({ name }) => name) || [],
    [commutationTypes],
  )

  function onExited(node: HTMLElement) {
    propsOnExited?.(node)
    setCommutationList('')
  }

  function onClose() {
    propsOnClose?.()
  }

  function onCancel() {
    onClose()
    return Promise.resolve()
  }

  async function onValidateList() {
    const list = serverList.split('\n')
    const values: Omit<CommutationValue, keyof MetaDatas>[][] = []

    try {
      list.forEach((stringCommutation: string) => {
        const strValues = stringCommutation.split(',')

        if (strValues.length !== commutationTypes?.length) {
          notifications.error('Parsing error', { subMessage: 'Bad parsing of values.' })
        }

        const commutationValues: Omit<CommutationValue, keyof MetaDatas>[] = []

        commutationTypes.forEach(({ id }, index) => {
          const distance = Number(strValues[index])

          if (Number.isNaN(distance) || !strValues[index]) return

          commutationValues.push({
            commutation: commutation.id,
            commutationType: id,
            distance,
          })
        })

        values.push(commutationValues)
      })

      await Promise.all(
        values.map((newCommutationValue) =>
          createCommutationValue({
            data: {
              commutationValues: newCommutationValue,
              facilityDataId,
              commutationId: commutation?.id,
            },
          }),
        ),
      )
    } catch (error) {
      console.error(error)
    } finally {
      onClose?.()
    }
  }

  return (
    <Modal
      onClose={onClose}
      onValidate={onValidateList}
      onCancel={onCancel}
      onExited={onExited}
      title="Add a list of commutations"
      {...modalProps}
    >
      <Input
        as="textarea"
        rows={8}
        value={serverList}
        onChange={(value: string) => setCommutationList(value)}
        placeholder={`${commutationTypesNames.join(',')}\n(one per line)`}
      />
      <Form.HelpText>
        <br />
        <dt>format:</dt>
        <dd>{commutationTypesNames.join(',')}</dd>
        <dd>
          0,3,0,0,...
          <br />
          0,0,12,0,...
          <br />
          ,6,,1,...
          <br />
          ...
        </dd>
      </Form.HelpText>
    </Modal>
  )
}
