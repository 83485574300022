import { InputCustom } from 'components'
import { useComputedValue } from 'hooks/useComputedValue'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup, SelectPicker } from 'rsuite'
import api from 'services/api'
import { ComputedValues, FacilityData } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type FacilityConsumptionProps = {
  facilityData: Partial<FacilityData> | null
  setFacilityData: (faciltity: Partial<FacilityData>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function FacilityConsumptionForm(props: FacilityConsumptionProps) {
  const { facilityData, setFacilityData, getFormErrors, setHasBlurred } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { t } = useTranslation()

  const [formError, setFormError] = useState({})

  const { data: energySupplierTypes = [] } = api.energySupplierTypes.readAll()

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  const { computedValue } = useComputedValue<'facilitiesDetails'>({
    type: 'facilitiesDetails',
    skip: !facilityData?.id,
  })

  function formatUnitValue(key: keyof ComputedValues['facilitiesDetails'][number][string]) {
    if (!facilityData?.facility || !facilityData?.year || !computedValue) return null
    let value = computedValue[facilityData.facility]?.[facilityData.year]?.[key]
    if (typeof value !== 'number') return null
    if (value > 10) value = Math.round(value)
    return (
      <span className="salmon">
        {t('misc.units.valueKgEqCo2', {
          value: new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value),
        })}
      </span>
    )
  }

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setFacilityData}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={facilityData || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.annualElectricityConsumption')}{' '}
          {formatUnitValue('electricity')}
          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.annualElectricityConsumption_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="annualElectricityConsumption"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.consumption.form.annualElectricityConsumption_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.KWH')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.heatingSupplier')}

          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.heatingSupplier_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="heatingSupplier"
          accepter={SelectPicker}
          tooltip-id="facilityDatas.consumption.form.heatingSupplier_FieldInformation"
          data={energySupplierTypes.map((et) => ({ value: et.id, label: et.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.annualHeatingConsumption')}{' '}
          {formatUnitValue('heating')}
          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.annualHeatingConsumption_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="annualHeatingConsumption"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.consumption.form.annualHeatingConsumption_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.hotKWH')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.coolingSupplier')}

          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.coolingSupplier_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="coolingSupplier"
          accepter={SelectPicker}
          tooltip-id="facilityDatas.consumption.form.coolingSupplier_FieldInformation"
          data={energySupplierTypes.map((et) => ({ value: et.id, label: et.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.annualCoolingConsumption')}{' '}
          {formatUnitValue('cooling')}
          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.annualCoolingConsumption_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="annualCoolingConsumption"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.consumption.form.annualCoolingConsumption_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.coldKWH')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.dataCenterCoolingSupplier')}

          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.dataCenterCoolingSupplier_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="datacenterCoolingSupplier"
          accepter={SelectPicker}
          tooltip-id="facilityDatas.consumption.form.dataCenterCoolingSupplier_FieldInformation"
          data={energySupplierTypes.map((et) => ({ value: et.id, label: et.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.consumption.form.annualDataCenterConsumption')}&nbsp;
          {formatUnitValue('datacenterCooling')}
          <FormTooltip tooltip>
            {t('facilityDatas.consumption.form.annualDataCenterConsumption_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="annualDatacenterCoolingConsumption"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.consumption.form.annualDataCenterConsumption_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.coldKWH')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}
