import { InputCustom } from 'components'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'rsuite'
import api from 'services/api'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { t } from 'i18next'

type CommutationValueFormProps = {
  value: { [key: string]: number } | null
  setValue: (value: { [key: string]: number }) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  isAverage: boolean
}

export function CommutationValueForm(props: CommutationValueFormProps) {
  const { value, setValue, getFormErrors, isAverage } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: commutationTypes } = api.commutationTypes.readAll()

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setValue}
      onCheck={setFormError}
      formValue={value || {}}
    >
      {commutationTypes?.map(({ name }) => (
        <Form.Group key={name}>
          <Form.ControlLabel>
            {capitalize(name)}
            <FormTooltip tooltip>
              {t('commutation.numberOfCommutation', { name: name.toLocaleLowerCase() })}
            </FormTooltip>
          </Form.ControlLabel>
          <InputGroup style={{ width: '100%' }}>
            <Form.Control
              name={name}
              type={!isAverage ? 'number' : 'float'}
              accepter={InputCustom}
            />
            <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
          </InputGroup>
        </Form.Group>
      ))}
    </Form>
  )
}
