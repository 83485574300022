import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { getApiURI } from 'services/api/utils'
import { auth } from 'services/authentication/localStorageToken'
import { setToken } from 'services/authentication/authReducer'
import { notifications } from 'components/Notifications/Notifications'

export const baseQuery = fetchBaseQuery({
  baseUrl: getApiURI(),
  mode: 'cors',
  prepareHeaders: (headers) => {
    const token = auth.getAuthenticationHeader()
    if (token) headers.set('Authorization', token)
    return headers
  },
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  function logout() {
    notifications.error('Session timed out', {
      subMessage: 'You need to log back in to your session.',
    })

    auth.logout()
    window.location.href = '/login'
    return result
  }

  if (result.error?.status === 401) {
    const refreshToken = auth.getRefreshToken()

    if (!refreshToken) return logout()

    const query = {
      url: '/token/refresh/',
      method: 'POST',
      body: { refresh: refreshToken },
    }

    const refreshResult = (await baseQuery(query, api, {})) as { data?: { access: string } }

    if (!refreshResult.data?.access) return logout()

    setToken({ refresh: refreshToken, access: refreshResult.data.access })
    result = await baseQuery(args, api, extraOptions)
  }

  return result
}
