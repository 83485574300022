import 'highlight.js/styles/github-dark-dimmed.css'
import hljs from 'highlight.js/lib/core'
import { HTMLAttributes } from 'react'
import classes from './CodeHighlighter.module.scss'

hljs.registerLanguage('python', require('highlight.js/lib/languages/python'))

type HighlighterProps = {
  content: string
  language: 'python'
} & Partial<HTMLAttributes<HTMLPreElement>>

export function CodeHighlighter({
  content,
  language,
  className,
  ...rest
}: HighlighterProps): JSX.Element {
  const highlighted = hljs.highlight(language, content)
  return (
    <div className={classes.container}>
      <pre className={`${classes.code} ${className || ''} hljs`} {...rest}>
        {/* eslint-disable-next-line react/no-danger */}
        <code dangerouslySetInnerHTML={{ __html: highlighted.value }} />
      </pre>
    </div>
  )
}
