import { Facilities } from 'pages/Facilities/Facilities'
import { Studios } from 'pages/Studios/Studios'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Settings } from 'pages/Settings/Settings'
import { Algo } from 'pages/Algo/Algo'
import { Productions } from 'pages/Productions/Productions'
import { Wiki } from 'pages/Wiki/Wiki'
import api from 'services/api'
import { useSelector } from 'react-redux'

export function AuthenticatedRoutes() {
  api.me.readMe()

  const { role, isAdmin } = useSelector((state: any) => ({
    role: state.authentication.user?.role,
    isAdmin: state.authentication.user?.isAdmin,
  }))

  const isSuperUser = role === 'owner' && isAdmin

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/studio" />} />
      <Route path="/algo" element={<Algo />} />
      <Route
        path="/settings/"
        element={<Navigate replace to={isSuperUser ? '/settings/commutations' : '/'} />}
      />
      <Route path="/settings/:page" element={isSuperUser ? <Settings /> : <Navigate to="/" />} />
      <Route path="/studio" element={<Studios />} />
      <Route path="/facilities/" element={<Facilities />} />
      <Route path="/facilities/:facilityId" element={<Facilities />} />
      <Route path="/productions" element={<Productions />} />
      <Route path="/productions/:productionId" element={<Productions />} />
      <Route path="/wiki" element={<Wiki />} />
    </Routes>
  )
}
