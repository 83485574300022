import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const hardwareTypes = {
  tw: 'Workstation',
  sc: 'Screen',
  tb: 'Tablet',
  lp: 'Laptop',
  sr: 'Server',
  ot: 'Other',
}

export const hardwareTypesIcons: Record<keyof typeof hardwareTypes, IconProp> = {
  tw: 'computer',
  sc: 'desktop',
  tb: 'tablet',
  lp: 'laptop',
  sr: 'server',
  ot: 'question',
}
