import React, { useMemo } from 'react'
import { InputPickerProps, Loader, SelectPicker } from 'rsuite'
import api from 'services/api'
import classes from './CountryPicker.module.scss'

type CountryPickerProps = InputPickerProps & {
  value?: string
}

export function CountryPicker(props: CountryPickerProps): React.ReactElement {
  const { value, ...inputPickerProps } = props

  const { data: countryProfiles = [], isLoading: countryProfilesIsLoading } =
    api.countryProfiles.readAll()

  const options = useMemo(
    () => countryProfiles.map((cp) => ({ label: cp.name, value: cp.id })),
    [countryProfiles],
  )

  return countryProfilesIsLoading ? (
    <Loader />
  ) : (
    <SelectPicker {...inputPickerProps} className={classes.picker} value={value} data={options} />
  )
}
