import { Link } from 'react-router-dom'
import { Button, Form } from 'rsuite'
import { useEffect, useMemo, useState } from 'react'
import { ButtonMenu, Modal } from 'components'
import api from 'services/api'
import {
  Commutation as CommutationProp,
  CommutationType,
  CommutationValue,
  FacilityData,
  ID,
} from 'types'
import { forEach, reduce } from 'lodash'
import { Description } from 'components/Description/Descrption'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { CommutationValueForm } from './CommutationValueForm'
import { ModalAddCommutationList } from './ModalAddCommutationList'

type CommutationProps = {
  facilityDataId: FacilityData['id']
  refecthComputedValue: () => void
  commutationTypes: CommutationType[] | undefined
  noCommutationTypes: boolean
  commutationsCarbonValue: JSX.Element | null
  style?: Record<string, any>
}

export function Commutation(props: CommutationProps) {
  const {
    facilityDataId,
    refecthComputedValue,
    commutationTypes,
    noCommutationTypes,
    commutationsCarbonValue,
    style,
  } = props
  const { t } = useTranslation()

  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: commutations = [], isLoading: isLoadingCommutations } = api.commutations.readAll({
    filters: { facilityDatas: facilityDataId },
  })
  const [createCommutation] = api.commutations.create()
  const [addCommutationValue] = api.commutations.addCommutationValue()
  const [deleteAllCommutations] = api.commutations.deleteAllCommutations()

  const commutation: CommutationProp | null = useMemo(
    () => commutations.find((c) => c.facilityDatas === facilityDataId) || null,
    [commutations],
  )

  const [commutationValuesForm, setCommutationValuesForm] = useState<{
    [key: string]: number
  } | null>(null)

  const [modalCommutationValueIsOpen, setModalCommutationValueIsOpen] = useState<boolean>(false)
  const [modalCommutationListIsOpen, setModalCommutationListIsOpen] = useState<boolean>(false)
  const [isAverage, setIsAverage] = useState<boolean>(false)
  const [modalCommutationValueTitle, setModalCommutationValueTitle] = useState<string>('')

  function formToCommutationValue(
    values: { [key: string]: number } | null,
    commutationId: string | undefined,
  ): Partial<CommutationValue>[] {
    const commutationValues: Partial<CommutationValue>[] = []

    forEach(values, (value, name) => {
      const commutationType = commutationTypes?.find((c: CommutationType) => c.name === name)
      if (!commutationType) return
      commutationValues.push({
        commutation: commutationId,
        distance: value,
        commutationType: commutationType.id,
      })
    })

    return commutationValues
  }

  const commutationTypesById: Record<CommutationType['id'], CommutationType | undefined> = useMemo(
    () =>
      commutationTypes?.reduce((acc: any, ct: CommutationType) => ({ ...acc, [ct.id]: ct }), {}) ||
      {},
    [commutationTypes],
  )

  const averageValues = useMemo<
    Record<CommutationType['name'], CommutationValue['distance']>
  >(() => {
    if (commutation && Object.keys(commutationTypesById).length > 0) {
      const sums = commutation.values.reduce<
        Record<CommutationType['name'], CommutationValue['distance']>
      >((acc, commutationValue) => {
        const commutationType = commutationTypesById[commutationValue.commutationType]
        if (!commutationType) return acc
        if (!acc[commutationType.name]) acc[commutationType.name] = 0
        acc[commutationType.name] += commutationValue.distance
        return acc
      }, {})

      return reduce(
        sums,
        (acc, sum, name) => {
          const commutationType = commutationTypes?.find((ct: CommutationType) => ct.name === name)
          const total = commutation.values.filter(
            (value) => value.commutationType === commutationType?.id,
          )
          return {
            ...acc,
            [name]: sum / total.length,
          }
        },
        {},
      )
    }
    return {}
  }, [commutation, commutationTypesById])

  async function createCommutationValue() {
    if (isAverage) {
      await deleteAllCommutations({ data: { facilityDataId } }).unwrap()
      setModalCommutationValueIsOpen(false)

      const response: any = await createCommutation({ data: { facilityDatas: facilityDataId } })
      await addCommutationValue({
        data: {
          facilityDataId,
          commutationId: response?.data.id as ID,
          commutationValues: formToCommutationValue(commutationValuesForm, response?.data.id),
        },
      }).unwrap()
    } else {
      await addCommutationValue({
        data: {
          facilityDataId,
          commutationId: commutation?.id as ID,
          commutationValues: formToCommutationValue(commutationValuesForm, commutation?.id),
        },
      }).unwrap()
      setModalCommutationValueIsOpen(false)
    }

    refecthComputedValue()
    setIsAverage(false)
  }

  useEffect(() => {
    if (!commutation && !isLoadingCommutations && !isAverage) {
      createCommutation({ data: { facilityDatas: facilityDataId } })
    }
  }, [commutation, isLoadingCommutations])

  if (!commutation) return null

  return (
    <>
      <div
        className="fullWidth flex end"
        style={{
          ...style,
          marginTop:
            noCommutationTypes || !authorized ? -20 : commutationsCarbonValue ? '-69px' : '-57px',
        }}
      >
        {noCommutationTypes || !authorized ? null : (
          <>
            <Button
              appearance="primary"
              color="blue"
              onClick={() => {
                setModalCommutationValueTitle(t('commutation.addCommutationValue'))
                setModalCommutationValueIsOpen(true)
              }}
            >
              <span>
                <FontAwesomeIcon icon="plus" className="marginRight10" />
                {t('commutation.menu.addCommutationValue')}
              </span>
            </Button>
            <ButtonMenu
              appearance="primary"
              color="blue"
              onClick={() => {
                setModalCommutationValueTitle(t('commutation.setCommutationAverage'))
                setModalCommutationValueIsOpen(true)
                setIsAverage(true)
              }}
              label={
                <span>
                  <FontAwesomeIcon icon="chart-column" className="marginRight10" />
                  {t('commutation.menu.setCommutationAverage')}
                </span>
              }
              actions={[
                {
                  label: t('commutation.menu.addCommutationList'),
                  icon: 'list',
                  disabled: !commutationTypes?.length,
                  onClick: () => setModalCommutationListIsOpen(true),
                  key: 'addList',
                },
              ]}
            />
          </>
        )}
      </div>

      <Description style={{ marginTop: !noCommutationTypes || authorized ? 0 : 39, fontSize: 'inherit', padding: '30px 0' }}>
      <Trans
        style={{ fontSize: '8px' }}
        i18nKey="commutation.description"
        components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            link1: <a
              aria-label="link"
              href="https://docs.google.com/forms/d/1ZmZqnhzE02KqeX7npkXysy68uEx6UsaLj80Bbst3tgI/edit"
              target="_blank"
              title="My link1"
              rel="noreferrer"
            />,
          }}
      />
      </Description>

      {noCommutationTypes ? (
        <div
          style={{ width: '100%', height: 300 }}
          className="flex center alignCenter textAlignCenter lightgrey"
        >
          {t('commutation.noCommutation')}&nbsp;
          {authorized ? (
            <Link to="/settings/commutations">{t('commutation.createCommutationLink')}</Link>
          ) : null}
        </div>
      ) : (
        <Form fluid plaintext formValue={{}}>
          {commutationTypes?.map(({ name }: CommutationType) => (
            <Form.Group key={name}>
              <Form.ControlLabel>
                {t('commutation.form.average', { name: name.toLocaleLowerCase() })}
                <FormTooltip tooltip>
                  {t('commutation.form.average_Tooltip', {
                    name: name.toLocaleLowerCase(),
                    length: commutations.length,
                  })}
                </FormTooltip>
              </Form.ControlLabel>
              {t('misc.units.valueKm', {
                value: Math.round((averageValues[name] || 0) * 100) / 100,
              })}
            </Form.Group>
          ))}
        </Form>
      )}
      <Modal
        open={modalCommutationValueIsOpen}
        onClose={() => setModalCommutationValueIsOpen(false)}
        onExited={() => setCommutationValuesForm(null)}
        onValidate={createCommutationValue}
        onCancel={() => Promise.resolve(setModalCommutationValueIsOpen(false))}
        title={modalCommutationValueTitle}
      >
        <CommutationValueForm
          value={commutationValuesForm}
          setValue={setCommutationValuesForm}
          isAverage={isAverage}
        />
      </Modal>
      <ModalAddCommutationList
        open={modalCommutationListIsOpen}
        onClose={() => {
          setModalCommutationListIsOpen(false)
        }}
        onCancel={() => Promise.resolve(setModalCommutationListIsOpen(false))}
        facilityDataId={facilityDataId}
        commutation={commutation}
      />
    </>
  )
}
