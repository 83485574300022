import { Form, SelectPicker } from 'rsuite'
import { Modal, type ModalProps } from 'components'
import { useTranslation } from 'react-i18next'
import api from 'services/api'
import { ComputerRig, HardwareProfile } from 'types'
import { useState } from 'react'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hardwareTypesIcons } from 'services/constants/hadwareTypes'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { SystemUnitIcon } from 'components/Icons/SystemUnit'
import { notifications } from 'components/Notifications/Notifications'
import { hardwareAddModalBehaviour } from 'utils/constants'
import { ComputerRigData } from './ComputerRigs'

type AddHardwareProfileModalProps = {
  computerRigId: ComputerRig['id'] | void
  rigData?: ComputerRigData
} & Partial<Omit<ModalProps, 'children'>>

export function AddHardwareProfileModal(props: AddHardwareProfileModalProps) {
  const { computerRigId, onClose, rigData, ...modalProps } = props

  const { t } = useTranslation()
  const [selectedHarwareProfile, setSelectedHardwareProfile] = useState<
    HardwareProfile | undefined
  >()

  const { data: hardwareProfiles = [] } = api.hardwareProfiles.readAll()
  const [createComputerRigHardware] = api.computerRigHardware.create()

  async function handleAddHardware(behaviour: string) {
    if (selectedHarwareProfile && computerRigId) {
      try {
        await createComputerRigHardware({
          data: {
            computerRig: computerRigId,
            hardwareProfile: selectedHarwareProfile.id,
            location: selectedHarwareProfile.isCloud ? 0 : 2,
          },
        }).unwrap()
        return behaviour === hardwareAddModalBehaviour.CONTINUE
          ? notifications.success(
              t('hardwareProfile.addSuccessMessage', {
                hardwareName: selectedHarwareProfile?.name,
              }),
            )
          : onClose?.()
      } catch (err: any) {
        throw new Error(err)
      }
    }
    return Promise.resolve()
  }

  return (
    <Modal
      open={!!computerRigId}
      {...modalProps}
      title={t('computerRig.modal.title')}
      onClose={onClose}
      onValidate={() => handleAddHardware(hardwareAddModalBehaviour.CLOSE)}
      onValidateAndContinue={() => handleAddHardware(hardwareAddModalBehaviour.CONTINUE)}
      cancelLabel={t('components.modals.close')}
      validateLabel={t('components.modals.add')}
      validateAndContinueLabel={t('components.modals.addAndContinue')}
      rigData={rigData}
    >
      <Form
        fluid
        onChange={(hp) => {
          setSelectedHardwareProfile(
            hardwareProfiles.find(
              (hardwareProfile) => hardwareProfile.id === hp.hardwareProfile,
            ) as HardwareProfile,
          )
        }}
        formValue={selectedHarwareProfile || undefined}
      >
        <Form.Group>
          <Form.ControlLabel>
            {t('computerRig.form.rig')}
            <FormTooltip tooltip>{t('computerRig.form.rig_Tooltip')}</FormTooltip>
          </Form.ControlLabel>
          <Form.Control
            name="hardwareProfile"
            accepter={SelectPicker}
            tooltip-id="computerRig.form.rig_FieldInformation"
            style={{ width: '100%' }}
            searchable
            data={map(hardwareProfiles, (hardwareProfile) => ({
              label: (
                <span>
                  {hardwareProfile.hardwareType === 'tw' ? (
                    <SystemUnitIcon className="marginRight10" />
                  ) : (
                    <FontAwesomeIcon
                      icon={hardwareTypesIcons[hardwareProfile.hardwareType]}
                      className="marginRight10"
                    />
                  )}
                  {hardwareProfile.name}
                </span>
              ),
              value: hardwareProfile.id,
            }))}
          />
        </Form.Group>
      </Form>
    </Modal>
  )
}
