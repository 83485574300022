import { t } from 'i18next'
import { Schema } from 'rsuite'

const { StringType } = Schema.Types

export const forgotPasswordValidationForm = Schema.Model({
  email: StringType()
    .isRequired(t('misc.fieldValidation.requiredField') || '')
    .isEmail(t('misc.fieldValidation.emailValidation') || ''),
})
