import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import { Studio } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type StudioFormProps = {
  studio: Partial<Studio> | void
  setStudio: (faciltity: Partial<Studio>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function StudioForm(props: StudioFormProps) {
  const { studio, setStudio, getFormErrors } = props
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setStudio}
      onCheck={setFormError}
      formValue={studio || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('studio.form.name')}
          <FormTooltip tooltip>{t('studio.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="studio.form.name_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
