import { Description } from 'components/Description/Descrption'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from 'components'
import { User } from 'types'

type ModalNewUserCreatedProps = {
  open: boolean
  newUser: User & { password: string }
} & Omit<ModalProps, 'children'>

export function ModalNewUserCreated(props: ModalNewUserCreatedProps) {
  const { open, newUser, ...modalProps } = props
  const { t } = useTranslation()

  return (
    <Modal open={open} {...modalProps} title={t('studioUser.newUserCreate')} hideCancel>
      <div className="fullWidth padding20">
        <Description color="grey">
          <p>{t('studioUser.newUserModal.description1')}</p>
          <p>{t('studioUser.newUserModal.description2')}</p>
          <p className="bold">{t('studioUser.newUserModal.description3')}</p>
        </Description>

        <code>
          <pre>
            <dt>{t('studioUser.form.username')}:</dt>
            <dd>{newUser.username}</dd>
            <dt>{t('studioUser.form.email')}:</dt>
            <dd>{newUser.email}</dd>
            <dt>{t('studioUser.form.password')}:</dt>
            <dd>{newUser.password}</dd>
          </pre>
        </code>
      </div>
    </Modal>
  )
}
