import { useRole } from 'hooks/useRole'
import { Input, InputProps } from 'rsuite'
import { userRoles } from 'services/constants/userRoles'

type Props = InputProps & {
  role?: keyof typeof userRoles
}

export function InputCustom(props: Props) {
  const { value, type, role, disabled, onFocus, onChange, onDoubleClick, ...rest } = props

  const { authorized } = useRole({ requiredRole: role })

  function handleOnChange(value: string, e: any) {
    if (type === 'number') {
      if (Number(value) < 0) return
      const parsedValue = parseInt(value || '0', 10)
      e.target.value = parsedValue
      onChange?.(parsedValue as unknown as string, e)
      return
    }
    if (type === 'float') {
      if (Number(value) < 0) return
      e.target.value = value || '0'
      onChange?.(Number(value) as unknown as string, e)
      return
    }
    onChange?.(value, e)
  }

  function handleOnFocus(e: any) {
    e.target?.select?.()
    onFocus?.(e)
  }

  function handleOnDoubleClick(e: any) {
    e.target?.select?.()
    onDoubleClick?.(e)
  }

  return (
    <Input
      value={type === 'number' || type === 'float' ? value || 0 : value || ''}
      type={type === 'float' ? 'number' : type}
      step={type === 'float' ? 0.1 : 1}
      disabled={disabled || !authorized}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onDoubleClick={handleOnDoubleClick}
      {...rest}
    />
  )
}
