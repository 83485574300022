/* eslint-disable react/prop-types */
import { useComputedValue } from 'hooks/useComputedValue'
import { useTranslation } from 'react-i18next'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { randomColors } from 'styles/colors'
import { DepartmentGroup } from 'types'
import './Recharts-custom.scss'

export type ProductionDepartmentsReportProps = {
  width?: number
  height?: number
  departmentGroups: DepartmentGroup[]
  productionComputedValue: Record<
    string,
    {
      departments: {
        previz: number
        real: number
      }
      trips: {
        previz: number
        real: number
      }
    }
  >
  productionId: string
}

export function ProductionDepartmentsReport(props: ProductionDepartmentsReportProps) {
  const {
    width = 200,
    height = 350,
    departmentGroups,
    productionComputedValue,
    productionId,
  } = props

  const { t } = useTranslation()

  const { computedValue } = useComputedValue<'departmentGroups'>({
    type: 'departmentGroups',
  })

  const chartDatas = departmentGroups
    .map((department) => {
      const data = computedValue[department.id]
      return {
        name: department.name,
        Previz: data?.previz ? data.previz / 1000 : 0,
        Reel: data?.real ? data.real / 1000 : 0,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))

  chartDatas.push({
    name: t('productions.graph.name'),
    Previz: productionComputedValue[productionId]?.trips?.previz
      ? productionComputedValue[productionId].trips.previz / 1000
      : 0,
    Reel: productionComputedValue[productionId]?.trips?.real
      ? productionComputedValue[productionId].trips.real / 1000
      : 0,
  })

  const indirectCost = departmentGroups.map((department) => {
    if (computedValue[department.id]) {
      const data = computedValue[department.id]
      return {
        Previz: data?.previz_direct && data?.previz ? (data.previz - data.previz_direct) / 1000 : 0,
        Reel: data?.real_direct && data?.real ? (data.real - data.real_direct) / 1000 : 0,
      }
    }
    return {
      Previz: 0,
      Reel: 0,
    }
  })

  chartDatas.push({
    name: 'Indirect',
    Previz: indirectCost.reduce((acc, cost) => acc + cost.Previz, 0),
    Reel: indirectCost.reduce((acc, cost) => acc + cost.Reel, 0),
  })

  const customTick = (props: {
    width: number
    height: number
    x: number
    y: number
    payload: { value: string }
  }) => {
    const { width, x, y, payload } = props
    return (
      <foreignObject
        style={{
          fontSize: 14,
          width: width / chartDatas.length,
          height: 90,
        }}
        x={x}
        y={y}
        offset={15}
      >
        <div
          style={{
            marginLeft: '50%',
            width: 'fit-content',
            minWidth: 'fit-content',
            transform: 'translateX(-50%)',
          }}
        >
          {payload.value}
        </div>
      </foreignObject>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={width}
        height={height}
        data={chartDatas}
        margin={{
          top: 20,
          right: 20,
          bottom: 90,
          left: 30,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" interval={0} tick={customTick} />
        <YAxis label={{ value: 'tCO2eq', angle: -90, position: 'insideLeft', offset: -25 }} />
        <Bar dataKey="Previz" barSize={20} fill={randomColors[1]} />
        <Bar dataKey="Reel" barSize={20} fill={randomColors[3]} />
        <Tooltip
          formatter={(value, name: 'Previz' | 'Reel') => [
            `${Math.round(Number(value) * 100) / 100} tCO2eq`,
            `${t(`productions.graph.carbonCost${name}`)}`,
          ]}
        />
        <Legend
          formatter={(value: 'Previz' | 'Reel') => `${t(`productions.graph.carbonCost${value}`)}`}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
