import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import type { CommutationType } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type CommutationTypeFormProps = {
  commutationType: Partial<CommutationType> | null
  setCommutationType: (commutationType: Partial<CommutationType>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function CommutationTypeForm(props: CommutationTypeFormProps) {
  const { t } = useTranslation()
  const { commutationType, setCommutationType, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setCommutationType}
      onCheck={setFormError}
      formValue={commutationType || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('commutation.settings.commutations.form.name')}
          <FormTooltip tooltip>
            {t('commutation.settings.commutations.form.name_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="commutation.settings.commutations.form.name_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
