!(function (e) {
  const t = (e.de = e.de || {})
  ;(t.dictionary = Object.assign(t.dictionary || {}, {
    '%0 of %1': '%0 von %1',
    'Align cell text to the bottom': 'Zellentext unten ausrichten',
    'Align cell text to the center': 'Zellentext zentriert ausrichten',
    'Align cell text to the left': 'Zellentext linksbündig ausrichten',
    'Align cell text to the middle': 'Zellentext mittig ausrichten',
    'Align cell text to the right': 'Zellentext rechtsbündig ausrichten',
    'Align cell text to the top': 'Zellentext oben ausrichten',
    'Align table to the left': 'Tabelle links ausrichten',
    'Align table to the right': 'Tabelle rechts ausrichten',
    Alignment: 'Ausrichtung',
    Aquamarine: 'Aquamarinblau',
    Background: 'Hintergrund',
    Big: 'Groß',
    Black: 'Schwarz',
    'Block quote': 'Blockzitat',
    Blue: 'Blau',
    Bold: 'Fett',
    Border: 'Rahmen',
    'Break text': 'Bild teilt Text',
    'Bulleted List': 'Aufzählungsliste',
    'Bulleted list styles toolbar': 'Darstellung der ungeordneten Liste',
    Cancel: 'Abbrechen',
    'Caption for image: %0': 'Bildunterschrift: %0',
    'Caption for the image': 'Bildunterschrift',
    'Cell properties': 'Zelleneigenschaften',
    'Center table': 'Tabelle zentrieren',
    'Centered image': 'Zentriertes Bild',
    'Change image text alternative': 'Alternativtext ändern',
    'Choose heading': 'Überschrift auswählen',
    Circle: 'Leerer Kreis',
    Code: 'Code',
    Color: 'Farbe',
    'Color picker': 'Farbwähler',
    Column: 'Spalte',
    Dashed: 'Gestrichelt',
    Decimal: 'Dezimalzahlen',
    'Decimal with leading zero': 'Dezimalzahlen mit vorangestellten Nullen',
    'Decrease indent': 'Einzug verkleinern',
    Default: 'Standard',
    'Delete column': 'Spalte löschen',
    'Delete row': 'Zeile löschen',
    'Dim grey': 'Dunkelgrau',
    Dimensions: 'Größe',
    Disc: 'Gefüllter Kreis',
    'Document colors': 'Dokumentfarben',
    Dotted: 'Gepunktet',
    Double: 'Doppelt',
    Downloadable: 'Herunterladbar',
    'Dropdown toolbar': 'Dropdown-Liste Werkzeugleiste',
    'Edit block': 'Absatz bearbeiten',
    'Edit link': 'Link bearbeiten',
    'Editor block content toolbar': 'Editor Blockinhalt-Toolbar',
    'Editor contextual toolbar': 'Editor kontextuelle Toolbar',
    'Editor editing area: %0': 'Bearbeitungsbereich des Editors: %0',
    'Editor toolbar': 'Editor Werkzeugleiste',
    'Enter image caption': 'Bildunterschrift eingeben',
    'Enter table caption': 'Tabellenüberschrift eingeben',
    'Font Background Color': 'Hintergrundfarbe',
    'Font Color': 'Schriftfarbe',
    'Font Family': 'Schriftart',
    'Font Size': 'Schriftgröße',
    'Full size image': 'Bild in voller Größe',
    Green: 'Grün',
    Grey: 'Grau',
    Groove: 'Eingeritzt',
    'Header column': 'Kopfspalte',
    'Header row': 'Kopfzeile',
    Heading: 'Überschrift',
    'Heading 1': 'Überschrift 1',
    'Heading 2': 'Überschrift 2',
    'Heading 3': 'Überschrift 3',
    'Heading 4': 'Überschrift 4',
    'Heading 5': 'Überschrift 5',
    'Heading 6': 'Überschrift 6',
    Height: 'Höhe',
    'Horizontal line': 'Horizontale Linie',
    'Horizontal text alignment toolbar':
      'Werkzeugleiste für die horizontale Zellentext-Ausrichtung',
    Huge: 'Sehr groß',
    'Image resize list': 'Bildgrößen-Liste',
    'Image toolbar': 'Bild Werkzeugleiste',
    'image widget': 'Bild-Steuerelement',
    'In line': 'Text in Zeile',
    'Increase indent': 'Einzug vergrößern',
    Insert: 'Einfügen',
    'Insert code block': 'Code-Block einfügen',
    'Insert column left': 'Spalte links einfügen',
    'Insert column right': 'Spalte rechts einfügen',
    'Insert image': 'Bild einfügen',
    'Insert image via URL': 'Bild von URL einfügen',
    'Insert media': 'Medium einfügen',
    'Insert paragraph after block': 'Absatz nach Block einfügen',
    'Insert paragraph before block': 'Absatz vor Block einfügen',
    'Insert row above': 'Zeile oben einfügen',
    'Insert row below': 'Zeile unten einfügen',
    'Insert table': 'Tabelle einfügen',
    Inset: 'Eingelassen',
    Italic: 'Kursiv',
    'Justify cell text': 'Zellentext als Blocksatz ausrichten',
    'Left aligned image': 'Linksbündiges Bild',
    'Light blue': 'Hellblau',
    'Light green': 'Hellgrün',
    'Light grey': 'Hellgrau',
    Link: 'Link',
    'Link URL': 'Link Adresse',
    'List properties': 'Listeneigenschaften',
    'Lower-latin': 'Kleingeschriebene lateinische Buchstaben',
    'Lower–roman': 'Kleingeschriebene römische Zahlen',
    'Media URL': 'Medien-URL',
    'media widget': 'Medien-Widget',
    'Merge cell down': 'Zelle unten verbinden',
    'Merge cell left': 'Zelle links verbinden',
    'Merge cell right': 'Zelle rechts verbinden',
    'Merge cell up': 'Zelle verbinden',
    'Merge cells': 'Zellen verbinden',
    Next: 'Nächste',
    None: 'Kein Rahmen',
    'Numbered List': 'Nummerierte Liste',
    'Numbered list styles toolbar': 'Darstellung der geordneten Liste',
    'Open in a new tab': 'In neuem Tab öffnen',
    'Open link in new tab': 'Link im neuen Tab öffnen',
    'Open media in new tab': 'Medien in neuem Tab öffnen',
    Orange: 'Orange',
    Original: 'Original',
    Outset: 'Geprägt',
    Padding: 'Innenabstand',
    Paragraph: 'Absatz',
    'Paste the media URL in the input.': 'Medien-URL in das Eingabefeld einfügen.',
    'Plain text': 'Nur Text',
    'Press Enter to type after or press Shift + Enter to type before the widget':
      'Drücken Sie die Eingabetaste, um nach dem Widget zu tippen oder Shift + Eingabetaste, um vor dem Widget zu tippen.',
    Previous: 'vorherige',
    Purple: 'Violett',
    Red: 'Rot',
    Redo: 'Wiederherstellen',
    'Remove color': 'Farbe entfernen',
    'Resize image': 'Bildgröße ändern',
    'Resize image to %0': 'Bildgröße ändern in %0',
    'Resize image to the original size': 'Bild in Originalgröße ändern',
    'Restore default': 'Standard wiederherstellen',
    'Reversed order': 'Umgekehrte Reihenfolge',
    'Rich Text Editor': 'Rich Text Editor',
    Ridge: 'Hervorgehoben',
    'Right aligned image': 'Rechtsbündiges Bild',
    Row: 'Zeile',
    Save: 'Speichern',
    'Select all': 'Alles auswählen',
    'Select column': 'Spalte auswählen',
    'Select row': 'Zeile auswählen',
    'Show more items': 'Mehr anzeigen',
    'Side image': 'Seitenbild',
    Small: 'Klein',
    Solid: 'Durchgezogen',
    'Split cell horizontally': 'Zelle horizontal teilen',
    'Split cell vertically': 'Zelle vertikal teilen',
    Square: 'Quadrat',
    'Start at': 'Beginnen mit',
    'Start index must be greater than 0.': 'Der Startwert muss größer als 0 sein.',
    Style: 'Rahmenart',
    'Table alignment toolbar': 'Werkzeugleiste für die Tabellen-Ausrichtung',
    'Table cell text alignment': 'Ausrichtung des Zellentextes',
    'Table properties': 'Tabelleneigenschaften',
    'Table toolbar': 'Tabelle Werkzeugleiste',
    'Text alternative': 'Alternativtext',
    'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
      'Die Farbe ist ungültig. Probieren Sie „#FF0000“ oder „rgb(255,0,0)“ oder „red“.',
    'The URL must not be empty.': 'Die URL darf nicht leer sein.',
    'The value is invalid. Try "10px" or "2em" or simply "2".':
      'Der Wert ist ungültig. Probieren Sie „10px“ oder „2em“ oder „2“.',
    'This link has no URL': 'Dieser Link hat keine Adresse',
    'This media URL is not supported.': 'Diese Medien-URL wird nicht unterstützt.',
    Tiny: 'Sehr klein',
    'Tip: Paste the URL into the content to embed faster.':
      'Tipp: Zum schnelleren Einbetten können Sie die Medien-URL in den Inhalt einfügen.',
    'Toggle caption off': 'Tabellenüberschrift deaktivieren',
    'Toggle caption on': 'Tabellenüberschrift aktivieren',
    'Toggle the circle list style': 'Leeren Kreis einstellen',
    'Toggle the decimal list style': 'Dezimalzahlen einstellen',
    'Toggle the decimal with leading zero list style':
      'Dezimalzahlen mit vorangestellten Nullen einstellen',
    'Toggle the disc list style': 'Gefüllten Kreis einstellen',
    'Toggle the lower–latin list style': 'Kleingeschriebene lateinische Buchstaben einstellen',
    'Toggle the lower–roman list style': 'Kleingeschriebene römische Zahlen einstellen',
    'Toggle the square list style': 'Quadrat einstellen',
    'Toggle the upper–latin list style': 'Großgeschriebene lateinische Buchstaben einstellen',
    'Toggle the upper–roman list style': 'Großgeschriebene römische Zahlen einstellen',
    Turquoise: 'Türkis',
    Underline: 'Unterstrichen',
    Undo: 'Rückgängig',
    Unlink: 'Link entfernen',
    Update: 'Aktualisieren',
    'Update image URL': 'Bild-URL aktualisieren',
    'Upload failed': 'Hochladen fehlgeschlagen',
    'Upload in progress': 'Upload läuft',
    'Upper-latin': 'Großgeschriebene lateinische Buchstaben',
    'Upper-roman': 'Großgeschriebene römische Zahlen',
    'Vertical text alignment toolbar': 'Werkzeugleiste für die vertikale Zellentext-Ausrichtung',
    White: 'Weiß',
    'Widget toolbar': 'Widget Werkzeugleiste',
    Width: 'Breite',
    'Wrap text': 'Text umfließt Bild',
    Yellow: 'Gelb',
  })),
    (t.getPluralForm = function (e) {
      return 1 != e
    })
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}))
