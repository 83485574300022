import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom, Modal } from 'components'
import { Description } from 'components/Description/Descrption'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, FlexboxGrid, SelectPicker } from 'rsuite'
import api from 'services/api'
import { energyCategories } from 'services/constants/energyCategories'
import { EnergySupplierProfile, EnergySupplierType } from 'types'
import { ButtonAddEnergySupplierProfile } from 'views/EnergySupplierProfile/ButtonAddEnergySupplierProfile'
import { EnergySupplierProfileForm } from 'views/EnergySupplierProfile/EnergySupplierProfileForm'
import { ButtonAddEnergySupplierType } from 'views/EnergySupplierType/ButtonAddEnergySupplierType'
import { EnergySupplierTypeForm } from 'views/EnergySupplierType/EnergySupplierTypeForm'

export function EnergySuppliers() {
  const { t } = useTranslation()

  const [deleteEnergySupplierProfile] = api.energySupplierProfiles.delete()
  const [updateEnergySupplierProfile] = api.energySupplierProfiles.update()
  const [deleteEnergySupplierType] = api.energySupplierTypes.delete()
  const [updateEnergySupplierType] = api.energySupplierTypes.update()
  const { data: energySupplierProfiles = [] } = api.energySupplierProfiles.readAll()
  const { data: energySupplierTypes = [], isLoading: isLoadingEnergySupplierTypes } =
    api.energySupplierTypes.readAll()
  const { data: countryProfiles = [], isLoading: isLoadingCountryProfiles } =
    api.countryProfiles.readAll()

  const [modalEnergySupplierTypeIsOpen, setModalEnergySupplierTypeIsOpen] = useState<boolean>(false)
  const [energySupplierTypeToEdit, setEnergySupplierTypeToEdit] =
    useState<Partial<EnergySupplierType> | null>(null)
  const [modalEnergySupplierProfileIsOpen, setModalEnergySupplierProfileIsOpen] =
    useState<boolean>(false)
  const [energySupplierProfileToEdit, setEnergySupplierProfileToEdit] =
    useState<Partial<EnergySupplierProfile> | null>(null)

  function removeEntry(energySupplierProfile: EnergySupplierProfile) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteEnergySupplierProfile(energySupplierProfile.id),
    })
  }

  function removeEntryEnergySupplierType(energySupplierType: EnergySupplierType) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', { name: `${energySupplierType?.name}` })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteEnergySupplierType(energySupplierType.id),
    })
  }

  const removeButtonEnergySupplierType = (energySupplierType: EnergySupplierType) => (
    <Button appearance="subtle" onClick={() => removeEntryEnergySupplierType(energySupplierType)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  const removeButton = (energySupplierProfile: EnergySupplierProfile) => (
    <Button appearance="subtle" onClick={() => removeEntry(energySupplierProfile)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  if (isLoadingEnergySupplierTypes || isLoadingCountryProfiles) return null

  return (
    <div className="fullWidth">
      <h3 className="marginBottom20">{t('energySuppliers.title')}</h3>
      <Description>
        {t('energySuppliers.description1')}
        <br />
        {t('energySuppliers.description2')}&nbsp;
        <span className="bold">{t('energySuppliers.description3')}</span>
        {t('energySuppliers.description4')}
      </Description>

      <FlexboxGrid justify="space-around">
        <FlexboxGrid.Item as={Col} colspan={8} md={8} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('energySuppliers.energySuppliers.title')}</h4>
            <ButtonAddEnergySupplierType />
          </div>

          <div>
            <Table
              height={500}
              data={energySupplierTypes}
              headerHeight={60}
              onUpdateRow={(data) => updateEnergySupplierType({ data }).unwrap()}
              columns={[
                {
                  name: t('energySuppliers.energySuppliers.form.name'),
                  tooltip: t('energySuppliers.energySuppliers.form.name_Tooltip'),
                  dataKey: 'name',
                  columnProps: {
                    flexGrow: 1,
                  },
                  EditComponent: InputCustom,
                  editComponentProps: (value, setValue) => ({
                    value,
                    onChange: setValue,
                  }),
                },
                {
                  name: t('components.table.remove'),
                  role: 'editor',
                  cellContent: removeButtonEnergySupplierType,
                  columnProps: {
                    fixed: 'right',
                    width: 60,
                  },
                  cellProps: {
                    style: {
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  },
                },
              ]}
            />
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={14} md={14} sm={24} xs={24}>
          <div className="fullWidth flex spaceBetween marginBottom10">
            <h4>{t('energySuppliers.energySupplierProfiles.title')}</h4>
            <ButtonAddEnergySupplierProfile />
          </div>

          <div>
            <Table
              height={500}
              wordWrap="break-word"
              data={energySupplierProfiles}
              headerHeight={60}
              onUpdateRow={(data) => updateEnergySupplierProfile({ data }).unwrap()}
              columns={[
                {
                  name: t('energySuppliers.energySupplierProfiles.form.energyType'),
                  tooltip: t('energySuppliers.energySupplierProfiles.form.energyType_Tooltip'),
                  dataKey: 'energySupplierType',
                  columnProps: {
                    flexGrow: 1,
                  },
                  cellContent: (rowData) =>
                    energySupplierTypes?.find((e) => e.id === rowData.energyType)?.name,
                  EditComponent: SelectPicker,
                  editComponentProps: (value, setValue, endEdit) => ({
                    value,
                    onExiting: endEdit,
                    onChange: setValue,
                    data: energySupplierTypes.map(({ name, id }) => ({ label: name, value: id })),
                    defaultOpen: true,
                  }),
                },
                {
                  name: t('energySuppliers.energySupplierProfiles.form.country'),
                  tooltip: t('energySuppliers.energySupplierProfiles.form.country_Tooltip'),
                  dataKey: 'country',
                  columnProps: {
                    flexGrow: 1,
                  },
                  cellContent: (rowData) =>
                    countryProfiles.find((cp) => cp.id === rowData.country)?.name,
                  EditComponent: SelectPicker,
                  editComponentProps: (value, setValue, endEdit) => ({
                    value,
                    onExiting: endEdit,
                    onChange: setValue,
                    data: countryProfiles.map(({ name, id }) => ({ label: name, value: id })),
                    defaultOpen: true,
                  }),
                },
                {
                  name: t('energySuppliers.energySupplierProfiles.form.category'),
                  tooltip: t('energySuppliers.energySupplierProfiles.form.category_Tooltip'),
                  dataKey: 'category',
                  cellContent: (rowData) => t(`constants.energyCategories.${rowData.category}`),
                  columnProps: {
                    flexGrow: 1,
                  },
                  EditComponent: SelectPicker,
                  editComponentProps: (value, setValue, endEdit) => ({
                    value,
                    onExiting: endEdit,
                    onChange: setValue,
                    data: Object.keys(energyCategories).map((value) => ({
                      label: t(`constants.energyCategories.${value as 'cold' | 'heat'}`),
                      value,
                    })),
                    defaultOpen: true,
                    searchable: false,
                  }),
                },
                {
                  name: t('energySuppliers.energySupplierProfiles.form.carbonEquivalent'),
                  tooltip: t(
                    'energySuppliers.energySupplierProfiles.form.carbonEquivalent_Tooltip',
                  ),
                  dataKey: 'carbonEquivalent',
                  columnProps: {
                    flexGrow: 1,
                  },
                  EditComponent: InputCustom,
                  editComponentProps: (value, setValue) => ({
                    value,
                    onChange: setValue,
                    type: 'float',
                  }),
                },
                {
                  name: t('energySuppliers.energySupplierProfiles.form.source'),
                  tooltip: t('energySuppliers.energySupplierProfiles.form.source_Tooltip'),
                  dataKey: 'source',
                  columnProps: {
                    flexGrow: 2,
                  },
                  EditComponent: InputCustom,
                  editComponentProps: (value, setValue, _, getCellInformations) => ({
                    value,
                    onChange: setValue,
                    as: 'textarea',
                    style: { resize: 'none', height: getCellInformations().height },
                  }),
                },
                {
                  name: t('components.table.remove'),
                  role: 'editor',
                  cellContent: removeButton,
                  columnProps: {
                    fixed: 'right',
                    width: 60,
                  },
                  cellProps: {
                    style: {
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  },
                },
              ]}
            />
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Modal
        open={modalEnergySupplierProfileIsOpen}
        onClose={() => setModalEnergySupplierProfileIsOpen(false)}
        onExited={() => setEnergySupplierProfileToEdit(null)}
        onValidate={() => {
          if (energySupplierProfileToEdit) {
            return updateEnergySupplierProfile({ data: energySupplierProfileToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalEnergySupplierProfileIsOpen(false))}
        title={t('energySuppliers.energySupplierProfiles.title')}
      >
        <EnergySupplierProfileForm
          energySupplierProfile={energySupplierProfileToEdit}
          setEnergySupplierProfile={setEnergySupplierProfileToEdit}
        />
      </Modal>
      <Modal
        open={modalEnergySupplierTypeIsOpen}
        onClose={() => setModalEnergySupplierTypeIsOpen(false)}
        onExited={() => setEnergySupplierTypeToEdit(null)}
        onValidate={() => {
          if (energySupplierTypeToEdit) {
            return updateEnergySupplierType({ data: energySupplierTypeToEdit }).unwrap()
          }
          return Promise.resolve()
        }}
        onCancel={() => Promise.resolve(setModalEnergySupplierTypeIsOpen(false))}
        title={t('energySuppliers.energySuppliers.title')}
      >
        <EnergySupplierTypeForm
          energySupplierType={energySupplierTypeToEdit}
          setEnergySupplierType={setEnergySupplierTypeToEdit}
        />
      </Modal>
    </div>
  )
}
