import { InputCustom } from 'components'
import { map } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker } from 'rsuite'
import api from 'services/api'
import { energyCategories } from 'services/constants/energyCategories'
import type { EnergySupplierProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type EnergySupplierProfileFormProps = {
  energySupplierProfile: Partial<EnergySupplierProfile> | null
  setEnergySupplierProfile: (energySupplierProfile: Partial<EnergySupplierProfile>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function EnergySupplierProfileForm(props: EnergySupplierProfileFormProps) {
  const { t } = useTranslation()
  const { energySupplierProfile, setEnergySupplierProfile, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: energySupplierTypes = [] } = api.energySupplierTypes.readAll()
  const { data: countryProfiles = [] } = api.countryProfiles.readAll()
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setEnergySupplierProfile}
      onCheck={setFormError}
      formValue={energySupplierProfile || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('energySuppliers.energySupplierProfiles.form.energyType')}
          <FormTooltip tooltip>
            {t('energySuppliers.energySupplierProfiles.form.energyType_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="energyType"
          accepter={SelectPicker}
          tooltip-id="energySuppliers.energySupplierProfiles.form.energyType_FieldInformation"
          data={energySupplierTypes.map((et) => ({ value: et.id, label: et.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('energySuppliers.energySupplierProfiles.form.category')}
          <FormTooltip tooltip>
            {t('energySuppliers.energySupplierProfiles.form.category_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="category"
          accepter={SelectPicker}
          tooltip-id="energySuppliers.energySupplierProfiles.form.category_FieldInformation"
          searchable={false}
          data={map(energyCategories, (label, value) => ({ value, label }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('energySuppliers.energySupplierProfiles.form.country')}
          <FormTooltip tooltip>
            {t('energySuppliers.energySupplierProfiles.form.country_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="country"
          accepter={SelectPicker}
          tooltip-id="energySuppliers.energySupplierProfiles.form.country_FieldInformation"
          data={countryProfiles.map((ct) => ({ value: ct.id, label: ct.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('energySuppliers.energySupplierProfiles.form.carbonEquivalent')}
          <FormTooltip tooltip>
            {t('energySuppliers.energySupplierProfiles.form.carbonEquivalent_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="carbonEquivalent"
          accepter={InputCustom}
          tooltip-id="energySuppliers.energySupplierProfiles.form.carbonEquivalent_FieldInformation"
          type="float"
        />
      </Form.Group>
    </Form>
  )
}
