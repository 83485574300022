!(function (e) {
  const a = (e.es = e.es || {})
  ;(a.dictionary = Object.assign(a.dictionary || {}, {
    '%0 of %1': '%0 de %1',
    'Align cell text to the bottom': 'Alinear texto de celda hacia abajo',
    'Align cell text to the center': 'Centrar texto de celda',
    'Align cell text to the left': 'Alinear texto de celda a la izquierda',
    'Align cell text to the middle': 'Alinear texto de celda al medio',
    'Align cell text to the right': 'Alinear texto de celda a la derecha',
    'Align cell text to the top': 'Alinear texto de celda hacia arriba',
    'Align table to the left': 'Alinear tabla a la izquierda',
    'Align table to the right': 'Alinear tabla a la derecha',
    Alignment: 'Alineación',
    Aquamarine: 'Aguamarina',
    Background: 'Fondo',
    Big: 'Grande',
    Black: 'Negro',
    'Block quote': 'Cita de bloque',
    Blue: 'Azul',
    Bold: 'Negrita',
    Border: 'Borde',
    'Break text': 'Permitir quebrar texto',
    'Bulleted List': 'Lista con viñetas',
    'Bulleted list styles toolbar': 'Estilos de lista con viñetas',
    Cancel: 'Cancelar',
    'Caption for image: %0': 'Título de la imagen: %0',
    'Caption for the image': 'Descripción de la imagen',
    'Cell properties': 'Propiedades de celda',
    'Center table': 'Centrar tabla',
    'Centered image': 'Imagen centrada',
    'Change image text alternative': 'Cambiar el texto alternativo de la imagen',
    'Choose heading': 'Elegir Encabezado',
    Circle: 'Círculo',
    Code: 'Código',
    Color: 'Color',
    'Color picker': 'Selector de color',
    Column: 'Columna',
    Dashed: 'Línea discontinua',
    Decimal: 'Decimal',
    'Decimal with leading zero': 'Decimal con cero',
    'Decrease indent': 'Disminuir sangría',
    Default: 'Por defecto',
    'Delete column': 'Eliminar columna',
    'Delete row': 'Eliminar fila',
    'Dim grey': 'Gris Oscuro',
    Dimensions: 'Dimensiones',
    Disc: 'Disco',
    'Document colors': 'Colores del documento',
    Dotted: 'Línea de puntos',
    Double: 'Doble línea',
    Downloadable: 'Descargable',
    'Dropdown toolbar': 'Barra de herramientas desplegable',
    'Edit block': 'Cuadro de edición',
    'Edit link': 'Editar enlace',
    'Editor block content toolbar': 'Barra de herramientas de contenido del bloque del editor',
    'Editor contextual toolbar': 'Barra de herramientas contextual del editor',
    'Editor editing area: %0': 'Área de edición del editor: %0',
    'Editor toolbar': 'Barra de herramientas de edición',
    'Enter image caption': 'Introducir título de la imagen',
    'Enter table caption': 'Ingresar título de tabla',
    'Font Background Color': 'Color de Fondo',
    'Font Color': 'Color de Fuente',
    'Font Family': 'Fuente',
    'Font Size': 'Tamaño de fuente',
    'Full size image': 'Imagen a tamaño completo',
    Green: 'Verde',
    Grey: 'Gris',
    Groove: 'Bisel',
    'Header column': 'Columna de encabezado',
    'Header row': 'Fila de encabezado',
    Heading: 'Encabezado',
    'Heading 1': 'Encabezado 1',
    'Heading 2': 'Encabezado 2',
    'Heading 3': 'Encabezado 3',
    'Heading 4': 'Encabezado 4',
    'Heading 5': 'Encabezado 5',
    'Heading 6': 'Encabezado 6',
    Height: 'Altura',
    'Horizontal line': 'Línea horizontal',
    'Horizontal text alignment toolbar': 'Alineación horizontal de texto',
    Huge: 'Enorme',
    'Image resize list': 'Listado para redimensionar imagen',
    'Image toolbar': 'Barra de herramientas de imagen',
    'image widget': 'Widget de imagen',
    'In line': 'En línea',
    'Increase indent': 'Aumentar sangría',
    Insert: 'Insertar',
    'Insert code block': 'Insertar bloque de código',
    'Insert column left': 'Insertar columna izquierda',
    'Insert column right': 'Insertar columna derecha',
    'Insert image': 'Insertar imagen',
    'Insert image via URL': 'Insertar imagen vía URL',
    'Insert media': 'Insertar contenido multimedia',
    'Insert paragraph after block': 'Insertar párrafo después del bloque',
    'Insert paragraph before block': 'Insertar párrafo antes del bloque',
    'Insert row above': 'Insertar fila encima',
    'Insert row below': 'Insertar fila debajo',
    'Insert table': 'Insertar tabla',
    Inset: 'Incrustación',
    Italic: 'Cursiva',
    'Justify cell text': 'Justificar texto de celda',
    'Left aligned image': 'Imagen alineada a la izquierda',
    'Light blue': 'Azul Claro',
    'Light green': 'Verde Claro',
    'Light grey': 'Gris Claro',
    Link: 'Enlace',
    'Link URL': 'URL del enlace',
    'List properties': 'Propiedades de la lista',
    'Lower-latin': 'Latinos minúsculas',
    'Lower–roman': 'Romanos minúsculas',
    'Media URL': 'URL del contenido multimedia',
    'media widget': 'Widget de contenido multimedia',
    'Merge cell down': 'Combinar celda inferior',
    'Merge cell left': 'Combinar celda izquierda',
    'Merge cell right': 'Combinar celda derecha',
    'Merge cell up': 'Combinar celda superior',
    'Merge cells': 'Combinar celdas',
    Next: 'Siguiente',
    None: 'Ninguno',
    'Numbered List': 'Lista numerada',
    'Numbered list styles toolbar': 'Estilos de lista numerada',
    'Open in a new tab': 'Abrir en una pestaña nueva ',
    'Open link in new tab': 'Abrir enlace en una pestaña nueva',
    'Open media in new tab': 'Abrir medio en una pestaña nueva',
    Orange: 'Anaranjado',
    Original: 'Original',
    Outset: 'Relieve',
    Padding: 'Márgenes',
    Paragraph: 'Párrafo',
    'Paste the media URL in the input.': 'Pega la URL del contenido multimedia',
    'Plain text': 'Texto plano',
    'Press Enter to type after or press Shift + Enter to type before the widget':
      'Pulse Intro para escribir después o pulse Mayús + Intro para escribir antes del «widget».',
    Previous: 'Anterior',
    Purple: 'Morado',
    Red: 'Rojo',
    Redo: 'Rehacer',
    'Remove color': 'Quitar color',
    'Resize image': 'Redimensionar imagen',
    'Resize image to %0': 'Redimensionar imagen al %0',
    'Resize image to the original size': 'Redimensionar imagen al tamaño original',
    'Restore default': 'Restaurar valores predeterminados',
    'Reversed order': 'Orden inverso',
    'Rich Text Editor': 'Editor de Texto Enriquecido',
    Ridge: 'Marco',
    'Right aligned image': 'Imagen alineada a la derecha',
    Row: 'Fila',
    Save: 'Guardar',
    'Select all': 'Seleccionar todo',
    'Select column': 'Seleccionar columna',
    'Select row': 'Seleccionar fila',
    'Show more items': 'Mostrar más elementos',
    'Side image': 'Imagen lateral',
    Small: 'Pequeño',
    Solid: 'Sólido',
    'Split cell horizontally': 'Dividir celdas horizontalmente',
    'Split cell vertically': 'Dividir celdas verticalmente',
    Square: 'Cuadrado',
    'Start at': 'Empezar en',
    'Start index must be greater than 0.': 'El número de inicio debe ser mayor que 0.',
    Style: 'Estilo',
    'Table alignment toolbar': 'Alineación de tabla',
    'Table cell text alignment': 'Alineación texto de celda',
    'Table properties': 'Propiedades de tabla',
    'Table toolbar': 'Barra de herramientas de tabla',
    'Text alternative': 'Texto alternativo',
    'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
      'El color es inválido. Intente con "#FF0000", "rgb(255,0,0)" o "red".',
    'The URL must not be empty.': 'La URL no debe estar vacía',
    'The value is invalid. Try "10px" or "2em" or simply "2".':
      'El valor es inválido. Intente con "10px", "2em" o simplemente "2".',
    'This link has no URL': 'Este enlace no tiene URL',
    'This media URL is not supported.': 'La URL de este contenido multimedia no está soportada',
    Tiny: 'Minúsculo',
    'Tip: Paste the URL into the content to embed faster.':
      'Tip: pega la URL dentro del contenido para embeber más rápido',
    'Toggle caption off': 'Desactivar título',
    'Toggle caption on': 'Activar título',
    'Toggle the circle list style': 'Cambiar estilo de viñeta a círculo',
    'Toggle the decimal list style': 'Cambiar estilo de lista a decimal',
    'Toggle the decimal with leading zero list style':
      'Cambiar estilo de lista decimal empezando con cero',
    'Toggle the disc list style': 'Cambiar estilo de viñeta a disco',
    'Toggle the lower–latin list style': 'Cambiar estilo de lista a números latinos en minúsculas',
    'Toggle the lower–roman list style': 'Cambiar estilo de lista a números romanos en minúsculas',
    'Toggle the square list style': 'Cambiar estilo de viñeta a cuadrado',
    'Toggle the upper–latin list style': 'Cambiar estilo de lista a números latinos en mayúsculas',
    'Toggle the upper–roman list style': 'Cambiar estilo de lista a números roanos en mayúsculas',
    Turquoise: 'Turquesa',
    Underline: 'Subrayado',
    Undo: 'Deshacer',
    Unlink: 'Quitar enlace',
    Update: 'Actualizar',
    'Update image URL': 'Actualizar imagen vía URL',
    'Upload failed': 'Fallo en la subida',
    'Upload in progress': 'Subida en progreso',
    'Upper-latin': 'Latinos minúsculas',
    'Upper-roman': 'Romanos mayúscula',
    'Vertical text alignment toolbar': 'Alineación vertical de texto',
    White: 'Blanco',
    'Widget toolbar': 'Barra de herramientas del widget',
    Width: 'Ancho',
    'Wrap text': 'Mantener texto unido',
    Yellow: 'Amarillo',
  })),
    (a.getPluralForm = function (e) {
      return 1 == e ? 0 : 0 != e && e % 1e6 == 0 ? 1 : 2
    })
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}))
