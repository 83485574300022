import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup } from 'rsuite'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type AnnualTravelsFormProps<Model> = {
  model: Partial<Model> | null
  setModel: (faciltity: Partial<Model>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function FacilitiesAnnualTravelsForm<Model>(props: AnnualTravelsFormProps<Model>) {
  const { model, setModel, getFormErrors } = props

  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={(value) => {
        setModel(value as Model)
      }}
      onCheck={setFormError}
      formValue={model || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilities.annualTravels.annualTravelsByPlane')}
          <FormTooltip tooltip>
            {t('facilities.annualTravels.annualTravelsByPlane_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control name="annualKmTraveldByPlane" accepter={InputCustom} type="number" />
          <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('facilities.annualTravels.annualTravelsByTrain')}
          <FormTooltip tooltip>
            {t('facilities.annualTravels.annualTravelsByTrain_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control name="annualKmTraveldByTrain" accepter={InputCustom} type="number" />
          <InputGroup.Addon>{t('misc.units.km')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}

export default FacilitiesAnnualTravelsForm
