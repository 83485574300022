/** @flow */

export const targetIsChild = (
  event?: React.FocusEvent<HTMLDivElement>,
  _target?: Element,
  _self?: Element,
): boolean => {
  const self = _self || event?.currentTarget
  let target: Element | undefined | null = _target || (event?.relatedTarget as Element)

  if (!target || !self) return false

  while (target) {
    if (self.isEqualNode(target)) return true

    /**
     * Return false only if the tree pass by the app root.
     * If not, a menu declared outside of the app root could be clicked.
     */
    if (target.id === 'root') return false

    target = target.parentNode as Element
  }

  return true
}

export function isEqualNode(event: React.SyntheticEvent<any, any>, _target?: HTMLElement): boolean {
  const target = _target || event.nativeEvent.target
  const source = event.nativeEvent.relatedTarget
  if (!source || !target) return false
  return target.isEqualNode(source)
}
