import {
  Button,
  ButtonGroup,
  ButtonProps,
  IconButton,
  NavItemProps,
  PopoverProps,
  Whisper,
  WhisperProps,
} from 'rsuite'
import { OverlayTriggerHandle } from 'rsuite/esm/Picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconType, MenuAction } from 'types'
import { useRef } from 'react'
import { ArrowDown } from '@rsuite/icons'
import { navItemsMenu } from 'components'

type MenuProps = Partial<WhisperProps> & {
  actions: (Omit<NavItemProps, 'icon'> & MenuAction)[]
  children?: JSX.Element
  icon?: IconType
  label: React.ReactNode
  style?: React.CSSProperties
  iconButtonStyle?: React.CSSProperties
  className?: string
  onClick?: (e: React.SyntheticEvent<HTMLElement, MouseEvent>) => void
  menuProps?: Partial<PopoverProps> & { style?: Record<string, any> }
  appearance?: ButtonProps['appearance']
  color?: ButtonProps['color']
}

export function ButtonMenu(props: MenuProps) {
  const {
    actions,
    icon,
    label,
    style,
    className,
    onClick,
    menuProps,
    iconButtonStyle,
    appearance,
    color,
    ...whisperProps
  } = props

  const whisperRef = useRef<OverlayTriggerHandle | null>(null)

  return (
    <ButtonGroup className="flex row noWrap">
      <Button
        style={style}
        className={className}
        onClick={onClick}
        appearance={appearance}
        color={color}
      >
        {icon ? <FontAwesomeIcon icon={icon} className="marginRight5" /> : null}
        {label}
      </Button>
      <Whisper
        placement="bottomEnd"
        trigger="click"
        speaker={navItemsMenu({
          menuActions: actions,
          handleClose: whisperRef.current?.close,
          menuProps,
        })}
        {...whisperProps}
        ref={(ref) => {
          whisperRef.current = ref
        }}
      >
        <IconButton
          appearance={appearance}
          color={color}
          icon={<ArrowDown />}
          style={iconButtonStyle}
        />
      </Whisper>
    </ButtonGroup>
  )
}
