import { forEach } from 'lodash'
import api from 'services/api'
import { unsetToken } from 'services/authentication/authReducer'
import { store } from 'services/store/store'
import { Token } from 'types'

function getTokens(): Token | null {
  const storedToken = localStorage.getItem('token')
  if (!storedToken) return null
  let token
  try {
    token = JSON.parse(storedToken) as Token
  } catch (error) {
    token = null
  }
  return token
}

function getAccessToken(): Token['access'] | null {
  const token = getTokens()
  if (!token) return null
  return token.access
}

function getRefreshToken(): Token['refresh'] | null {
  const token = getTokens()
  if (!token) return null
  return token.refresh
}

function logout(): void {
  forEach(api, (ap) => store.dispatch(ap.util.resetApiState()))
  unsetToken()
}

function getAuthenticationHeader(): string | null {
  const token = getAccessToken()
  if (!token) {
    return null
  }
  return `Bearer ${token}`
}

export const auth = {
  logout,

  getTokens,
  getAccessToken,
  getRefreshToken,

  getAuthenticationHeader,
}
