import { useState } from 'react'
import { Modal, ModalProps } from 'components/Modal/Modal'
import api from 'services/api'
import { ID, Facility } from 'types'
import { Form } from 'rsuite'
import { InputCustom } from 'components'
import { useTranslation } from 'react-i18next'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type ModalAddFacilityProps = Partial<ModalProps> & {
  studioId: ID
  onCreate: (facility: Facility) => void
}

export function CreateFacility(props: ModalAddFacilityProps) {
  const {
    studioId,
    onCreate,
    open,
    onExited: propsOnExited,
    onClose: propsOnClose,
    ...modalProps
  } = props
  const { t } = useTranslation()

  const [createFacility] = api.facilities.create()

  const [facility, setFacility] = useState<Partial<Facility>>({})

  const { authorized } = useRole({ requiredRole: 'editor' })

  function onExited(node: HTMLElement) {
    propsOnExited?.(node)
    setFacility({})
  }

  function onClose() {
    propsOnClose?.()
  }

  function onCancel() {
    onClose()
    return Promise.resolve()
  }

  function onValidate() {
    return createFacility({ data: { ...facility, company: studioId } })
      .unwrap()
      .then((facility) => {
        onCreate(facility)
        onClose()
      })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onValidate={onValidate}
      onCancel={onCancel}
      onExited={onExited}
      title={t('facilities.createModal.title')}
      cancelLabel={facility?.id ? t('components.modals.close') : t('components.modals.cancel')}
      {...modalProps}
    >
      <Form
        fluid
        plaintext={!authorized}
        onChange={(values) => {
          setFacility(values)
        }}
        formValue={facility}
      >
        <Form.Group>
          <Form.ControlLabel>
            {t('facilities.createModal.name')}
            <FormTooltip tooltip>{t('facilities.createModal.name_Tooltip')}</FormTooltip>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCustom}
            tooltip-id="facilities.createModal.title_FieldInformation"
          />
        </Form.Group>
      </Form>
    </Modal>
  )
}
