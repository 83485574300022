/* eslint-disable no-restricted-syntax */
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import { notifications as notif } from 'components/Notifications/Notifications'
import { auth } from 'services/authentication/localStorageToken'
import { t } from 'i18next'
import { map } from 'lodash'

export const errorsHandling: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && action.error && typeof action.payload === 'object') {
    const { payload } = action

    const status = payload.originalStatus || payload.status

    if (status === 401) {
      if (payload.data?.code === 'user_not_found') {
        if (window.location.pathname !== '/login') {
          auth.logout()
          window.location.pathname = '/login'
        }
        return next(action)
      }

      if (payload.data?.code === 'token_not_valid') {
        return next(action)
      }
    }

    if (status === 500) {
      notif.error(`Error ${payload.originalStatus}`, {
        subMessage: t('errors.api.500_title'),
        duration: 5000,
      })
    }

    if (status === 'FETCH_ERROR') {
      notif.error(t('errors.api.fetchError_title'), {
        subMessage: <span>{t('errors.api.fetchError')}</span>,
        duration: 5000,
      })
      if (window.location.pathname !== '/login') {
        auth.logout()
        window.location.pathname = '/login'
      }
    }

    if (typeof payload.data === 'object') {
      const notifications = map(
        payload.data,
        (reasons: string[] | string, error: string, index) => (
          <div
            className="flex column marginTop5"
            key={typeof reasons === 'string' ? reasons : reasons.join(String(index))}
          >
            <div className="bold">{error}</div>
            <div>
              {typeof reasons === 'string' ? (
                <div>{reasons}</div>
              ) : Array.isArray(reasons) ? (
                reasons.map((reason: string | { message: string }, index: number) => {
                  let message

                  if (typeof reason === 'object') {
                    message = reason.message
                  } else {
                    message = reason
                  }

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={String(index)}>{message}</div>
                  )
                })
              ) : null}
            </div>
          </div>
        ),
      )

      notif.error(`Error ${status}`, { subMessage: notifications, duration: 5000 })
    }
  }

  return next(action)
}
