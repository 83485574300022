import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Plus } from '@rsuite/icons'
import { TFunction } from 'i18next'
import { Button, Tooltip, Whisper } from 'rsuite'

type Params = {
  add?: () => void
  remove?: () => void
  update?: () => void
  save?: () => void
  cancelChanges?: () => void
  disableAdd?: boolean
  disableSave?: boolean
  disableDelete?: boolean
  disableUpdate?: boolean
  exponent?: string | number
  t: TFunction
}

export const sideButtons = (params: Params) => {
  const {
    disableSave,
    disableDelete,
    disableAdd,
    save,
    cancelChanges,
    remove,
    add,
    update,
    exponent,
    t,
  } = params

  return [
    !disableSave && save ? (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {exponent
              ? t('components.tabbedContainer.saveExponent', { exponent })
              : t('components.tabbedContainer.save')}
          </Tooltip>
        }
        open={disableSave ? false : undefined}
      >
        <Button appearance="primary" color="green" onClick={save} disabled={disableSave}>
          <FontAwesomeIcon icon="floppy-disk" className="marginRight5" />
          &nbsp;
          {t('components.tabbedContainer.save')}
        </Button>
      </Whisper>
    ) : null,

    ...(!disableSave && !!cancelChanges
      ? [
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{t('components.tabbedContainer.cancelChanges')}</Tooltip>}
          >
            <Button onClick={cancelChanges}>
              <FontAwesomeIcon icon="rotate-left" className="marginRight5" />
              &nbsp;
              {t('components.tabbedContainer.cancel')}
            </Button>
          </Whisper>,
        ]
      : []),

    add ? (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {exponent
              ? t('components.tabbedContainer.addExponent', { exponent })
              : t('components.tabbedContainer.add')}
          </Tooltip>
        }
        open={disableAdd ? false : undefined}
      >
        <Button onClick={add} disabled={disableAdd}>
          <Plus className="marginRight5" /> {t('components.tabbedContainer.add')}
        </Button>
      </Whisper>
    ) : null,

    remove ? (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {exponent
              ? t('components.tabbedContainer.deleteExponent', { exponent })
              : t('components.tabbedContainer.delete')}
          </Tooltip>
        }
        open={disableDelete ? false : undefined}
      >
        <Button onClick={remove} disabled={disableDelete}>
          <FontAwesomeIcon icon="trash" className="marginRight5" />
          &nbsp;
          {t('components.tabbedContainer.delete')}
        </Button>
      </Whisper>
    ) : null,

    update ? (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {exponent
              ? t('components.tabbedContainer.updateExponent', { exponent })
              : t('components.tabbedContainer.update')}
          </Tooltip>
        }
        open={disableDelete ? false : undefined}
      >
        <Button onClick={update} disabled={disableDelete}>
          <FontAwesomeIcon icon="pen" className="marginRight5" />
          &nbsp;
          {t('components.tabbedContainer.update')}
        </Button>
      </Whisper>
    ) : null,
  ]
}
