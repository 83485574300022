import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputCustom } from 'components'
import { openModal } from 'components/Modal/openModal'
import { Table } from 'components/Table/Table'
import { useRole } from 'hooks/useRole'
import { useTranslation } from 'react-i18next'
import { Button, DatePicker, SelectPicker } from 'rsuite'
import api from 'services/api'
import { FacilityHardware } from 'types'
import { formatDateToYYYYMMDD } from 'utils/formatDate'
import { ButtonAddFacilityHardware } from 'views/FacilityHardwares/ButtonAddFacilityHardwares'

export function FacilityHardwares() {
  const { t } = useTranslation()
  const { authorized } = useRole({ requiredRole: 'editor' })

  const [deleteFacilityHardware] = api.facilityHardware.delete()
  const [updateFacilityHardware] = api.facilityHardware.update()
  const { data: facilityHardwares = [] } = api.facilityHardware.readAll()
  const { data: hardwareProfiles = [] } = api.hardwareProfiles.readAll()
  const { data: facilities = [] } = api.facilities.readAll()

  function removeEntry(facilityHardware: FacilityHardware) {
    openModal({
      content: (
        <span>
          {t('components.modals.removeItemValidation', {
            name: t('components.modals.thisProfile'),
          })}
          <br />
          <span className="bold">{t('components.modals.removeItemValidationLossData')}</span>
        </span>
      ),
      validateLabel: t('components.modals.remove'),
      validateColor: 'red',
      onValidate: () => deleteFacilityHardware(facilityHardware.id),
    })
  }

  const removeButton = (facilityHardware: FacilityHardware) => (
    <Button appearance="subtle" onClick={() => removeEntry(facilityHardware)}>
      <FontAwesomeIcon icon="trash" />
    </Button>
  )

  return (
    <div className="fullWidth" style={{ marginTop: authorized ? '-56px' : undefined }}>
      {authorized ? (
        <div className="fullWidth flex end marginBottom10">
          <ButtonAddFacilityHardware />
        </div>
      ) : null}

      <div>
        <Table
          height={500}
          virtualized
          data={facilityHardwares}
          headerHeight={70}
          wordWrap="break-word"
          onUpdateRow={(data) =>
            updateFacilityHardware({
              data: { ...data, depreciationStart: formatDateToYYYYMMDD(data.depreciationStart) },
            }).unwrap()
          }
          columns={[
            {
              name: t('facilityHardware.form.hardwareProfile'),
              tooltip: t('facilityHardware.form.hardwareProfile_Tooltip'),
              dataKey: 'hardwareProfile',
              cellContent: (rowData: FacilityHardware) =>
                hardwareProfiles?.find((csp) => csp.id === rowData.hardwareProfile)?.name,
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: setValue,
                data: hardwareProfiles
                  .map(({ name, id }) => ({ label: name, value: id }))
                  .sort((a, b) => a.label.localeCompare(b.label)),
                defaultOpen: true,
              }),
              columnProps: {
                flexGrow: 2,
              },
            },
            {
              name: t('facilityHardware.form.facility'),
              tooltip: t('facilityHardware.form.facility_Tooltip'),
              dataKey: 'facility',
              cellContent: (rowData: FacilityHardware) =>
                facilities.find((f) => f.id === rowData.facility)?.name,
              EditComponent: SelectPicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value,
                onExiting: endEdit,
                onChange: setValue,
                data: facilities.map(({ name, id }) => ({ label: name, value: id })),
                defaultOpen: true,
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('facilityHardware.form.date'),
              tooltip: t('facilityHardware.form.year_Tooltip'),
              dataKey: 'depreciationStart',
              cellContent: (rowData: FacilityHardware) => {
                const date = new Date(formatDateToYYYYMMDD(rowData.depreciationStart))
                return (
                  <div>
                    {`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`}
                  </div>
                )
              },
              EditComponent: DatePicker,
              editComponentProps: (value, setValue, endEdit) => ({
                value: new Date(value),
                onExiting: endEdit,
                onChange: setValue,
                format: 'yyyy-MM',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('facilityHardware.form.number'),
              tooltip: t('facilityHardware.form.number_Tooltip'),
              dataKey: 'number',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'number',
              }),
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('facilityHardware.form.depreciationTime'),
              tooltip: t('facilityHardware.form.depreciationTime_Tooltip'),
              dataKey: 'depreciationTime',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'number',
              }),
              columnProps: {
                flexGrow: 2,
              },
            },
            {
              name: t('facilityHardware.form.pastDepreciationTime'),
              tooltip: t('facilityHardware.form.depreciationTime_Tooltip'),
              dataKey: 'pastDepreciationTime',
              EditComponent: InputCustom,
              editComponentProps: (value, setValue) => ({
                value,
                onChange: setValue,
                type: 'number',
              }),
              columnProps: {
                flexGrow: 2,
              },
            },
            {
              name: t('facilityHardware.form.carbonEquivalent_manufacturingCostValue'),
              tooltip: t('facilityHardware.form.carbonEquivalent_manufacturingCostValue_Tooltip'),
              cellContent: ({ hardwareProfile }: FacilityHardware) => {
                const { manufacturingCarbonEquivalent } =
                  hardwareProfiles.find((hp) => hp.id === hardwareProfile) || {}
                return <span>{manufacturingCarbonEquivalent}</span>
              },
              columnProps: {
                flexGrow: 1,
              },
            },
            {
              name: t('facilityHardware.form.unitRender_consumption'),
              tooltip: t('facilityHardware.form.unitRender_consumption_Tooltip'),
              cellContent: ({ hardwareProfile }: FacilityHardware) => {
                const { renderConsumption } =
                  hardwareProfiles.find((hp) => hp.id === hardwareProfile) || {}
                return <span>{renderConsumption}</span>
              },
              columnProps: {
                flexGrow: 1,
              },
            },

            {
              name: t('components.table.remove'),
              role: 'editor',
              cellContent: removeButton,
              columnProps: {
                fixed: 'right',
                width: 60,
              },
              cellProps: {
                style: {
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            },
          ]}
        />
      </div>
    </div>
  )
}
