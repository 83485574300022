export const GROUP_TYPES = [
  {
    name: 'people',
    id: 0,
  },
  {
    name: 'computer',
    id: 1,
  },
]
export const hardwareAddModalBehaviour = {
  CLOSE: 'close',
  CONTINUE: 'continue',
}
