import { Modal } from 'components'
import { ModalProps } from 'components/Modal/Modal'
import { notifications } from 'components/Notifications/Notifications'
import { useMemo, useState } from 'react'
import { Form, Input } from 'rsuite'
import api from 'services/api'
import { Diet, DietValue, FacilityData, MetaDatas } from 'types'

type ModalAddDietListProps = Partial<ModalProps> & {
  dietId: Diet['id']
  facilityDataId: FacilityData['id']
}

export function ModalAddDietList(props: ModalAddDietListProps) {
  const {
    dietId,
    onExited: propsOnExited,
    onClose: propsOnClose,
    facilityDataId,
    ...modalProps
  } = props

  const [serverList, setDietList] = useState<string>('')

  const { data: dietTypes = [] } = api.dietTypes.readAll()
  const [createDietValue] = api.diets.addDietValues()

  const dietTypesNames = useMemo(() => dietTypes?.map(({ name }) => name) || [], [dietTypes])

  function onExited(node: HTMLElement) {
    propsOnExited?.(node)
    setDietList('')
  }

  function onClose() {
    propsOnClose?.()
  }

  function onCancel() {
    onClose()
    return Promise.resolve()
  }

  async function onValidateList() {
    const list = serverList.split('\n')
    const allDietValues: Omit<DietValue, keyof MetaDatas>[][] = []

    list.forEach((stringDiet: string) => {
      const strValues = stringDiet.split(',')

      if (strValues.length !== dietTypes?.length) {
        notifications.error('Parsing error', { subMessage: 'Bad parsing of values.' })
        return
      }

      const dietValues: Omit<DietValue, keyof MetaDatas>[] = []

      dietTypes.forEach(({ id }, index) => {
        const number = Number(strValues[index])

        if (Number.isNaN(number) || number === 0) return

        dietValues.push({
          diet: dietId,
          dietType: id,
          number,
        })
      })

      allDietValues.push(dietValues)
    })

    return Promise.all(
      allDietValues.map((newDietValue) =>
        createDietValue({ data: { dietValues: newDietValue, dietId, facilityDataId } }),
      ),
    ).finally(onClose)
  }

  return (
    <Modal
      onClose={onClose}
      onValidate={onValidateList}
      onCancel={onCancel}
      onExited={onExited}
      title="Add a list of diets"
      {...modalProps}
    >
      <Input
        as="textarea"
        rows={8}
        value={serverList}
        onChange={(value: string) => setDietList(value)}
        placeholder={`${dietTypesNames.join(',')}\n(one per line)`}
      />
      <Form.HelpText>
        <br />
        <dt>format:</dt>
        <dd>{dietTypesNames.join(',')}</dd>
        <dd>
          0,3,0,0,...
          <br />
          0,0,12,0,...
          <br />
          ,6,,1,...
          <br />
          ...
        </dd>
      </Form.HelpText>
    </Modal>
  )
}
