import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTrash,
  faPen,
  faEllipsis,
  faPlus,
  faCirclePlus,
  faCircleInfo,
  faList,
  faFloppyDisk,
  faRotateLeft,
  faPlane,
  faBurger,
  faDatabase,
  faComputer,
  faEarthAmericas,
  faLightbulb,
  faServer,
  faCar,
  faCloud,
  faXmark,
  faInfo,
  faUser,
  faExpand,
  faDesktop,
  faTablet,
  faLaptop,
  faQuestion,
  faChartColumn,
  faHouse,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTrash,
  faPen,
  faEllipsis,
  faPlus,
  faCirclePlus,
  faCircleInfo,
  faList,
  faFloppyDisk,
  faRotateLeft,
  faPlane,
  faBurger,
  faDatabase,
  faComputer,
  faEarthAmericas,
  faLightbulb,
  faServer,
  faCar,
  faCloud,
  faXmark,
  faInfo,
  faUser,
  faExpand,
  faDesktop,
  faTablet,
  faLaptop,
  faQuestion,
  faChartColumn,
  faHouse,
  faBriefcase,
)
