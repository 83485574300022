/* eslint-disable react/no-unstable-nested-components */
import { FocusEvent, useEffect, useRef, useState } from 'react'
import { targetIsChild } from 'utils/focus'
import { ComputerRigHardware } from 'types'
import { Loader } from 'rsuite'
import { Column } from './Table'
import classes from './Cell.module.scss'

type CellProps<RowType> = {
  column: Column<RowType>
  rowIndex: number
  rowData: any
  disableCellEdit?: (rowData: any) => boolean
  setCurrentEditingCell: (cellId: string | void) => void
  currentEditingCell: string | void
  onValidate?: (value: RowType) => Promise<RowType | ComputerRigHardware>
}

export function Cell<RowType>(props: CellProps<RowType>) {
  const {
    column,
    rowData,
    rowIndex,
    setCurrentEditingCell,
    currentEditingCell,
    onValidate,
    disableCellEdit,
  } = props
  const { cellContent, dataKey, EditComponent, editComponentProps } = column

  const [value, setValue] = useState<any>(dataKey ? rowData[dataKey] : null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const editableContainerRef = useRef<HTMLDivElement | null>(null)

  function cellId(dataKey: Column<RowType>['dataKey'], rowIndex: number) {
    return `${String(dataKey)}-${String(rowIndex)}`
  }

  const isEditing = currentEditingCell === cellId(dataKey, rowIndex)

  function endEdit() {
    if (!onValidate || !dataKey) return

    if (value === rowData[dataKey]) {
      setCurrentEditingCell()
      return
    }

    setIsLoading(true)
    onValidate({ ...rowData, [dataKey]: value })
      .then(() => {
        setIsLoading(false)
        setCurrentEditingCell()
      })
      .catch(() => {
        setValue(dataKey ? rowData[dataKey] : null)
        setCurrentEditingCell()
        setIsLoading(false)
      })
  }

  function onBlurCell(event: FocusEvent<HTMLDivElement>) {
    if (targetIsChild(event)) return
    endEdit()
  }

  function onStartEdit(dataKey: Column<RowType>['dataKey'], rowIndex: number) {
    setCurrentEditingCell(cellId(dataKey, rowIndex))
  }

  function onKeyDown(event: any) {
    if (event.key === 'Enter') {
      if (isEditing) endEdit()
      else onStartEdit(dataKey, rowIndex)
    }
    if (event.key === 'Escape') {
      setValue(dataKey ? rowData[dataKey] : null)
      setCurrentEditingCell()
    }
  }

  useEffect(() => {
    if (isEditing) {
      editableContainerRef.current?.querySelector('input')?.focus?.()
    }
  }, [isEditing])

  const getCellInformations = () => ({
    height: editableContainerRef?.current?.offsetHeight || 0,
  })

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onDoubleClick={() => !disableCellEdit?.(rowData) && onStartEdit(dataKey, rowIndex)}
      onBlur={isEditing ? onBlurCell : undefined}
      onKeyDown={onKeyDown}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      ref={editableContainerRef}
      className={`${classes.container} ${EditComponent ? 'rs-table-cell-editable' : ''}`}
    >
      {isLoading ? (
        <Loader />
      ) : EditComponent &&
        editComponentProps &&
        onValidate &&
        currentEditingCell === cellId(dataKey, rowIndex) ? (
        <EditComponent {...editComponentProps(value, setValue, endEdit, getCellInformations)} />
      ) : (
        cellContent?.(rowData, rowIndex) || (dataKey ? rowData[dataKey] : null)
      )}
    </div>
  )
}
