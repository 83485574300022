import { TooltipProps as RSTooltipProps, Tooltip as RSTooltip, Whisper, WhisperProps } from 'rsuite'

export type TooltipProps = { title?: string } & RSTooltipProps & Partial<WhisperProps>

export function Tooltip(props: TooltipProps) {
  const { placement = 'top', controlId, trigger = 'hover', title, children } = props

  if (!title || !children) return null

  return (
    <Whisper
      placement={placement}
      controlId={controlId}
      trigger={trigger}
      speaker={<RSTooltip>{title}</RSTooltip>}
    >
      {children}
    </Whisper>
  )
}
