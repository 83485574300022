import { t } from 'i18next'
import { reduce } from 'lodash'
import { useEffect, useState } from 'react'
import { PieChart, Pie as PieComponent, Cell, ResponsiveContainer, Legend, Sector } from 'recharts'

export type FacilityDataRecapProps = {
  detailsComputedValues: any
  facilityData: any
  width?: number
  height?: number
}

export function FacilityDataRecap(props: FacilityDataRecapProps) {
  const { width = 400, height = 400, detailsComputedValues, facilityData } = props
  const [datas, setDatas] = useState<{ name: string; value: number; color?: string }[]>([])
  const [activeChart, setActiveChart] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [reportTotal, setReportTotal] = useState<number>(0)
  useEffect(() => {
    if (facilityData?.facility && facilityData?.year && detailsComputedValues) {
      const {
        audioStreaming,
        cooling,
        datacenterCooling,
        diets,
        electricity,
        heating,
        trips,
        videoStreaming,
        commutations,
        depreciation,
        paper,
      } = detailsComputedValues[facilityData.facility]?.[facilityData.year] || {}
      const others = paper
      const { tw, sr, sc, ...rest } = depreciation || {}
      setDatas([
        { name: t('facilityDatas.report.electricity'), value: electricity, color: '#ff9900' },
        { name: t('facilityDatas.report.heating'), value: heating, color: '#fce5cd' },
        { name: t('facilityDatas.report.cooling'), value: cooling, color: '#f9cb9c' },
        {
          name: t('facilityDatas.report.datacenterCooling'),
          value: datacenterCooling,
          color: '#f6b26b',
        },
        {
          name: t('facilityDatas.report.streaming'),
          value: videoStreaming + audioStreaming,
          color: '#a569bd',
        },
        { name: t('facilityDatas.report.diets'), value: diets, color: '#b6d7a8' },
        { name: t('facilityDatas.report.trips'), value: trips, color: '#b7b7b7' },
        { name: t('facilityDatas.report.commutations'), value: commutations, color: '#666666' },
        { name: t('facilityDatas.report.tower'), value: tw, color: '#cfe2f3' },
        { name: t('facilityDatas.report.server'), value: sr, color: '#9fc5e8' },
        { name: t('facilityDatas.report.screen'), value: sc, color: '#3d85c6' },
        {
          name: t('facilityDatas.report.depreciationOthers'),
          value: reduce(rest, (acc, val) => acc + val),
          color: '#f4d03f',
        },
        { name: t('facilityDatas.report.others'), value: others, color: '#19A890' },
      ])
      setReportTotal(
        ((electricity || 0) +
          (heating || 0) +
          (cooling || 0) +
          (datacenterCooling || 0) +
          (videoStreaming || 0) +
          (audioStreaming || 0) +
          (diets || 0) +
          (trips || 0) +
          (commutations || 0) +
          (Object.values(depreciation || {}).length
            ? reduce(depreciation, (acc, val) => acc + val)
            : 0)) /
          1000,
      )
    }
  }, [facilityData, detailsComputedValues])

  const renderLegend = ({ payload }: any) => (
    <ul>
      {payload.map((entry: any, index: any) => (
        <>
          {/* eslint-disable-next-line react/no-array-index-key */}
          <li
            style={{
              display: 'inline-block',
              fontSize: 14,
              width: 'auto',
              zIndex: -100,
              color: entry.payload.color,
              backgroundColor: entry.value === activeChart ? 'lightgrey' : 'transparent',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${index}`}
          >
            <div
              style={{
                display: 'inline-block',
                width: 15,
                height: 13,
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            />
            <span>
              {entry.value}{' '}
              {entry.payload.percent === 0
                ? `${(0.0).toFixed(2)}%`
                : !entry.payload.percent
                ? ''
                : `${(entry.payload.percent * 100).toFixed(2)}%`}
            </span>
          </li>
          <br />
        </>
      ))}
    </ul>
  )

  const onHover = (val: any, index: number) => {
    if (val) {
      const { name } = val
      setActiveChart(name)
      setActiveIndex(index)
    }
  }

  const renderActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: any) => (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fontSize={14}>
        {reportTotal.toFixed(0) ?? 0} tCO2eq
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <PieComponent
          data={datas}
          cx="50%"
          cy="50%"
          labelLine={false}
          innerRadius={65}
          outerRadius={130}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onHover}
          onMouseLeave={() => setActiveChart(null)}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
        >
          {datas.map((entry: any, index: any) => (
            <Cell key={String(`cell-${index}`)} fill={entry.color} />
          ))}
        </PieComponent>
        <Legend content={renderLegend} layout="vertical" align="right" verticalAlign="middle" />
      </PieChart>
    </ResponsiveContainer>
  )
}
