import { formFieldNotification } from 'components/FormFieldInformations/FormFieldInformations'
import { useEffect } from 'react'

export function useFormFieldInformations() {
  function onFocusElement(event: FocusEvent) {
    // @ts-ignore
    const key: any | void = event.target?.getAttribute?.('tooltip-id')
    formFieldNotification(key)
  }

  useEffect(() => {
    window.addEventListener('focusin', onFocusElement)
    return () => {
      window.removeEventListener('focusin', onFocusElement)
    }
  }, [])
}
