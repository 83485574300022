import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { EnergySupplierType } from 'types'
import { EnergySupplierTypeForm } from './EnergySupplierTypeForm'

type ModalAddEnergySupplierTypeProps = ButtonProps

export function ButtonAddEnergySupplierType(props: ModalAddEnergySupplierTypeProps) {
  const { t } = useTranslation()
  const [energySupplierType, setEnergySupplierType] = useState<Partial<EnergySupplierType> | null>(
    null,
  )
  const [modalEnergySupplierTypeIsOpen, setModalEnergySupplierTypeIsOpen] = useState<boolean>(false)
  const [createEnergySupplierType] = api.energySupplierTypes.create()

  async function addEnergySupplierType() {
    if (energySupplierType) await createEnergySupplierType({ data: energySupplierType })
    setModalEnergySupplierTypeIsOpen(false)
    return Promise.resolve()
  }

  function openModal() {
    setModalEnergySupplierTypeIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} {...props}>
        <FontAwesomeIcon icon="lightbulb" className="marginRight10" />{' '}
        {t('energySuppliers.energySuppliers.addButton')}
      </Button>
      <Modal
        open={modalEnergySupplierTypeIsOpen}
        onClose={() => setModalEnergySupplierTypeIsOpen(false)}
        onExited={() => setEnergySupplierType(null)}
        onValidate={addEnergySupplierType}
        onCancel={() => Promise.resolve(setModalEnergySupplierTypeIsOpen(false))}
        title={t('energySuppliers.energySuppliers.addButton')}
      >
        <EnergySupplierTypeForm
          energySupplierType={energySupplierType}
          setEnergySupplierType={setEnergySupplierType}
        />
      </Modal>
    </>
  )
}
