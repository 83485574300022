export function generateRandomPassword(length: number = 12) {
  const characterPool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()'
  const randomNumber = new Uint8Array(1)
  let password = ''

  for (let i = 0; i < length; i += 1) {
    do {
      crypto.getRandomValues(randomNumber)
    } while (randomNumber[0] >= characterPool.length)

    password += characterPool[randomNumber[0]]
  }

  return password
}
