import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { IconType } from 'types'
import classes from './DoubleIcon.module.css'

type DoubleIconProps = Partial<FontAwesomeIconProps> & {
  icons: IconType[]
  style?: Object
  className?: string
}

export function DoubleIcon(props: DoubleIconProps) {
  const { icons, style, className, ...rest } = props
  return (
    <span className={`${classes.container} ${className || ''}`} style={style}>
      <FontAwesomeIcon {...rest} icon={icons[0]} />
      <FontAwesomeIcon className={classes.secondIcon} {...rest} icon={icons[1]} />
    </span>
  )
}
