export function formatDateToYYYYMMDD(val: Date | string | null = new Date()): string {
  if (val) {
    const date = new Date(val)
    const year = date.toLocaleString('default', { year: 'numeric' })
    const month = date.toLocaleString('default', {
      month: '2-digit',
    })
    const day = '01'
    return [year, month, day].join('-')
  }
  return ''
}
