import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import type { EnergySupplierType } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type EnergySupplierTypeFormProps = {
  energySupplierType: Partial<EnergySupplierType> | null
  setEnergySupplierType: (energySupplierType: Partial<EnergySupplierType>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function EnergySupplierTypeForm(props: EnergySupplierTypeFormProps) {
  const { t } = useTranslation()
  const { energySupplierType, setEnergySupplierType, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setEnergySupplierType}
      onCheck={setFormError}
      formValue={energySupplierType || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('energySuppliers.energySuppliers.form.name')}
          <FormTooltip tooltip>
            {t('energySuppliers.energySuppliers.form.name_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="energySuppliers.energySuppliers.form.name_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
