import { Route, Routes } from 'react-router-dom'
import { Login } from 'pages/Authentification/Login/Login'
import { SignUp } from 'pages/Authentification/SignUp/SignUp'
import { ForgotPassword } from 'pages/Authentification/ForgotPassword/ForgotPassword'
import { NewPassword } from 'pages/Authentification/NewPassword/NewPassword'
import { PageNotFound } from 'pages/PageNotFound/PageNotFound'
import { Welcome } from 'pages/Welcome/Welcome'

export function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/new-password/:uid/:token" element={<NewPassword />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  )
}
