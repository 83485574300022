import { createApi as reduxCreateApi } from '@reduxjs/toolkit/query/react'
import { EndpointName, ID } from 'types'
import { baseQueryWithReauth } from './baseQuery'
import { createCRUDEndpoints } from './endpoints/createCRUDEndpoints'

type CreateApiParams = {
  endpoint: `${EndpointName}${'' | `/${string}`}`
  reducerPath?: string
  extend?: Parameters<typeof reduxCreateApi>[0]['endpoints']
  tagName: string
  extraTags?: string[]
}

export function createCRUDApi<Item extends { id: ID }>(params: CreateApiParams) {
  const { endpoint, reducerPath, extend, tagName } = params

  return reduxCreateApi({
    reducerPath: reducerPath || endpoint,
    baseQuery: baseQueryWithReauth,
    tagTypes: [tagName],
    endpoints: (build) => ({
      ...createCRUDEndpoints<typeof build, Item>(build, endpoint, tagName),
      ...extend?.(build),
    }),
  })
}
