import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker } from 'rsuite'
import api from 'services/api'
import type { DietProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type DietProfileFormProps = {
  dietProfile: Partial<DietProfile> | null
  setDietProfile: (dietProfile: Partial<DietProfile>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function DietProfileForm(props: DietProfileFormProps) {
  const { t } = useTranslation()
  const { dietProfile, setDietProfile, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { data: dietTypes = [] } = api.dietTypes.readAll()
  const { data: countryProfiles = [] } = api.countryProfiles.readAll()
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setDietProfile}
      onCheck={setFormError}
      formValue={dietProfile || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('diet.settings.dietProfiles.form.dietType')}
          <FormTooltip tooltip>{t('diet.settings.dietProfiles.form.dietType_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="dietType"
          accepter={SelectPicker}
          tooltip-id="diet.settings.dietProfiles.form.dietType_FieldInformation"
          data={dietTypes.map((ct) => ({ value: ct.id, label: ct.name }))}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('diet.settings.dietProfiles.form.country')}
          <FormTooltip tooltip>{t('diet.settings.dietProfiles.form.country_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="country"
          accepter={SelectPicker}
          tooltip-id="diet.settings.dietProfiles.form.country_FieldInformation"
          data={countryProfiles.map((ct) => ({ value: ct.id, label: ct.name }))}
          style={{ width: '100%', marginBottom: 20 }}
        />
        <Form.Group>
          <Form.ControlLabel>
            {t('diet.settings.dietProfiles.form.carbonEquivalent')}
            <FormTooltip tooltip>
              {t('diet.settings.dietProfiles.form.carbonEquivalent_Tooltip')}
            </FormTooltip>
          </Form.ControlLabel>
          <Form.Control
            name="carbonEquivalent"
            accepter={InputCustom}
            tooltip-id="diet.settings.dietProfiles.form.carbonEquivalent_FieldInformation"
            type="float"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            {t('diet.settings.dietProfiles.form.mixAverage')}
            <FormTooltip tooltip>
              {t('diet.settings.dietProfiles.form.mixAverage_Tooltip')}
            </FormTooltip>
          </Form.ControlLabel>
          <Form.Control
            name="mixAverage"
            accepter={InputCustom}
            tooltip-id="diet.settings.dietProfiles.form.mixAverage_FieldInformation"
            type="float"
          />
        </Form.Group>
      </Form.Group>
    </Form>
  )
}
