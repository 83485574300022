import { Modal } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'rsuite'
import api from 'services/api'
import { Studio, StudioUser, User } from 'types'
import { DoubleIcon } from 'components/Icon/DoubleIcon'
import { StudioUsersTableForm } from './StudioUsersTableForm'
import { ModalNewUserCreated } from './ModalNewUserCreated'

export type FormUser = Partial<StudioUser> & Partial<User>
type ModalAddStudioUserProps = { studioId: Studio['id'] } & ButtonProps

export function ButtonAddStudioUser(props: ModalAddStudioUserProps) {
  const { studioId } = props
  const { t } = useTranslation()
  const [studioUser, setStudioUser] = useState<FormUser | null>(null)
  const [modalStudioUserIsOpen, setModalStudioUserIsOpen] = useState<boolean>(false)
  const [modalNewUserCreatedIsOpen, setModalNewUserCreatedIsOpen] = useState<boolean>(false)
  const [newUser, setNewUser] = useState<(User & { password: string }) | null>(null)
  const [createUser] = api.users.create()
  const [deleteUser] = api.users.delete()
  const [createStudioUser] = api.studioUsers.create()

  async function addStudioUser() {
    if (studioUser) {
      const { password, username, email, role, ...studioUserData } = studioUser
      const user = await createUser({ data: { password, username, email } }).unwrap()

      try {
        await createStudioUser({
          data: { ...studioUserData, company: studioId, user: user.id, role },
        }).unwrap()

        setNewUser({ ...user, password } as User & { password: string })
        setModalStudioUserIsOpen(false)
        setModalNewUserCreatedIsOpen(true)
      } catch (error) {
        await deleteUser(user.id)
      }
    }
  }

  function openModal() {
    setModalStudioUserIsOpen(true)
  }

  return (
    <>
      <Button onClick={openModal} appearance="primary">
        <DoubleIcon icons={['user', 'plus']} className="marginRight10" />{' '}
        {t('studioUser.addButton')}
      </Button>
      <Modal
        open={modalStudioUserIsOpen}
        onClose={() => setModalStudioUserIsOpen(false)}
        onExited={() => setStudioUser(null)}
        onValidate={addStudioUser}
        onCancel={() => Promise.resolve(setModalStudioUserIsOpen(false))}
        title={t('studioUser.addButton')}
      >
        <StudioUsersTableForm studioUser={studioUser} setStudioUser={setStudioUser} />
      </Modal>
      {newUser ? (
        <ModalNewUserCreated
          open={modalNewUserCreatedIsOpen}
          newUser={newUser}
          onClose={() => setModalNewUserCreatedIsOpen(false)}
          onExited={() => setNewUser(null)}
          onValidate={() => Promise.resolve(setModalNewUserCreatedIsOpen(false))}
        />
      ) : null}
    </>
  )
}
