import { InputCustom } from 'components'
import { useComputedValue } from 'hooks/useComputedValue'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup } from 'rsuite'
import { ComputedValues, FacilityData } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type DataConsumptionFormProps = {
  facilityData: Partial<FacilityData> | null
  setFacilityData: (faciltity: Partial<FacilityData>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function DataConsumptionForm(props: DataConsumptionFormProps) {
  const { facilityData, setFacilityData, getFormErrors, setHasBlurred } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { t } = useTranslation()

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  const { computedValue } = useComputedValue<'facilitiesDetails'>({
    type: 'facilitiesDetails',
    skip: !facilityData?.id,
  })

  function formatUnitValue(key: keyof ComputedValues['facilitiesDetails'][number][string]) {
    if (!facilityData?.facility || !facilityData?.year || !computedValue) return null
    let value = computedValue[facilityData.facility]?.[facilityData.year]?.[key]
    if (typeof value !== 'number') return null
    if (value > 10) value = Math.round(value)
    return (
      <span className="salmon">
        {t('misc.units.valueKgEqCo2', {
          value: new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value),
        })}
      </span>
    )
  }

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setFacilityData}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={facilityData || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.dataConsumption.videoStreaming')} {formatUnitValue('videoStreaming')}
          <FormTooltip tooltip>
            {t('facilityDatas.dataConsumption.videoStreaming_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="videoStreamingHoursPerPerson"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.dataConsumption.videoStreaming_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.numberHoursPersDay')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.dataConsumption.audioStreaming')} {formatUnitValue('audioStreaming')}
          <FormTooltip tooltip>
            {t('facilityDatas.dataConsumption.audioStreaming_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="audioStreamingHoursPerPerson"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.dataConsumption.audioStreaming_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.numberHoursPersDay')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.dataConsumption.annualPaperConsumption')} {formatUnitValue('paper')}
          <FormTooltip tooltip>
            {t('facilityDatas.dataConsumption.annualPaperConsumption_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="annualPaperReamConsumption"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.dataConsumption.annualPaperConsumption_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.paperReams')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}
