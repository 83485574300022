import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker, DatePicker } from 'rsuite'
import api from 'services/api'
import type { FacilityHardware } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'
import { formatDateToYYYYMMDD } from 'utils/formatDate'

type FacilityHardwareFormProps = {
  facilityHardware: Partial<FacilityHardware> | null
  setFacilityHardware: (facilityHardware: Partial<FacilityHardware>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function FacilityHardwareForm(props: FacilityHardwareFormProps) {
  const { t } = useTranslation()
  const { facilityHardware, setFacilityHardware, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })

  const { data: hardwareProfiles = [] } = api.hardwareProfiles.readAll()
  const { data: facilities = [] } = api.facilities.readAll()

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setFacilityHardware}
      onCheck={setFormError}
      formValue={facilityHardware || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.facility')}
          <FormTooltip tooltip>{t('facilityHardware.form.facility_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="facility"
          accepter={SelectPicker}
          data={facilities.map((facility) => ({
            value: facility.id,
            label: facility.name,
          }))}
          style={{ width: '100%' }}
          tooltip-id="facilityHardware.form.facility_FieldInformation"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.date')}
          <FormTooltip tooltip>{t('facilityHardware.form.date_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        {/* eslint-disable func-call-spacing */}
        <DatePicker
          format="yyyy-MM"
          style={{ width: '100%' }}
          ranges={[]}
          onChange={(val) => {
            const date = formatDateToYYYYMMDD(val)
            setFacilityHardware({
              ...facilityHardware,
              depreciationStart: date,
            })
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.number')}
          <FormTooltip tooltip>{t('facilityHardware.form.number_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="number"
          accepter={InputCustom}
          tooltip-id="facilityHardware.form.number_FieldInformation"
          type="number"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.depreciationTime')}
          <FormTooltip tooltip>{t('facilityHardware.form.depreciationTime_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="depreciationTime"
          accepter={InputCustom}
          tooltip-id="facilityHardware.form.depreciationTime_FieldInformation"
          type="number"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.pastDepreciationTime')}
          <FormTooltip tooltip>
            {t('facilityHardware.form.pastDepreciationTime_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="pastDepreciationTime"
          accepter={InputCustom}
          tooltip-id="facilityHardware.form.pastDepreciationTime_FieldInformation"
          type="number"
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityHardware.form.hardwareProfile')}
          <FormTooltip tooltip>{t('facilityHardware.form.hardwareProfile_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="hardwareProfile"
          accepter={SelectPicker}
          data={
            hardwareProfiles
            .map((hp) => ({ value: hp.id, label: hp.name }))
            .sort((a, b) => a.label.localeCompare(b.label))
          }
          style={{ width: '100%', marginBottom: 20 }}
          tooltip-id="facilityHardware.form.hardwareProfile_FieldInformation"
        />
      </Form.Group>
    </Form>
  )
}
