import { useState } from 'react'
import { Modal, ModalProps } from 'components/Modal/Modal'
import api from 'services/api'
import { ID, Production } from 'types'
import { Form, Loader, SelectPicker } from 'rsuite'
import { InputCustom } from 'components'
import { useTranslation } from 'react-i18next'
import { productionFormats } from 'services/constants/productionFormats'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type ModalAddProductionProps = Partial<ModalProps> & {
  setSelectedProduction: (productionId: ID) => void
}

export function ModalAddProduction(props: ModalAddProductionProps) {
  const {
    setSelectedProduction,
    open,
    onExited: propsOnExited,
    onClose: propsOnClose,
    ...modalProps
  } = props
  const { t } = useTranslation()

  const { data: studios = [], isLoading: isStudiosLoading } = api.studios.readAll()
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [createProduction] = api.productions.create()
  const [updateProduction] = api.productions.update()

  const [production, setProduction] = useState<Partial<Production>>({})

  function onExited(node: HTMLElement) {
    propsOnExited?.(node)
    setProduction({})
  }

  function onClose() {
    propsOnClose?.()
  }

  function onCancel() {
    onClose()
    return Promise.resolve()
  }

  async function onValidateProduction() {
    if (production.id) return updateProduction({ data: production })
    return createProduction({ data: { ...production, company: studios[0]?.id } })
      .unwrap()
      .then((production) => {
        setSelectedProduction(production.id)
        onClose()
      })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onValidate={onValidateProduction}
      onCancel={onCancel}
      onExited={onExited}
      title={t('productions.createModal.title')}
      {...modalProps}
    >
      {isStudiosLoading ? (
        <Loader />
      ) : (
        <Form
          fluid
          plaintext={!authorized}
          onChange={(values) => {
            setProduction(values)
          }}
          formValue={production}
        >
          <Form.Group>
            <Form.ControlLabel>
              {t('productions.createModal.name')}
              <FormTooltip tooltip>{t('productions.createModal.name_Tooltip')}</FormTooltip>
            </Form.ControlLabel>
            <Form.Control
              name="name"
              accepter={InputCustom}
              tooltip-id="productions.createModal.name_FieldInformation"
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              {t('productions.createModal.productionFormat')}
              <FormTooltip tooltip>
                {t('productions.createModal.productionFormat_Tooltip')}
              </FormTooltip>
            </Form.ControlLabel>
            <Form.Control
              name="productionFormat"
              accepter={SelectPicker}
              data={Object.keys(productionFormats).map((value) => ({
                value,
                label: t(`constants.productionFormats.${value as keyof typeof productionFormats}`),
              }))}
              style={{ width: '100%' }}
              tooltip-id="productions.createModal.productionFormat_FieldInformation"
            />
          </Form.Group>
        </Form>
      )}
    </Modal>
  )
}
