/* eslint-disable react/prop-types */
import {
  ComposedChart,
  Line,
  Bar as ChartBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useComputedValue } from 'hooks/useComputedValue'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Production } from 'types'
import './Recharts-custom.scss'

export type ProductionsReportProps = {
  width?: number
  height?: number
  productions: Production[]
}

export function ProductionsReport(props: ProductionsReportProps) {
  const { width = 200, height = 350, productions } = props

  const { t } = useTranslation()

  const { computedValue: productionsComputedValue } = useComputedValue<'productions'>({
    type: 'productions',
  })

  function subtractYears(date: Date, years: number) {
    date.setFullYear(date.getFullYear() - years)
    return date
  }

  const minusFiveYears = subtractYears(new Date(), 5).toISOString().split('T')[0]

  const filteredProductions = useMemo(
    () => productions.filter((production) => production.startDate > minusFiveYears),
    [productions],
  )

  const chartDatas = filteredProductions.map((production) => {
    if (productionsComputedValue !== null && productionsComputedValue[production.id]?.departments) {
      const { previz: carbonCostPreviz, real: carbonCostReel } =
        productionsComputedValue && productionsComputedValue[production.id].departments
      return {
        name: production.name,
        Previz: carbonCostPreviz / 1000,
        Reel: carbonCostReel / 1000,
        CostPerMinutePreviz:
          production?.nbEpisodes && production?.episodeLength
            ? carbonCostPreviz / 1000 / production.nbEpisodes / production.episodeLength
            : 0,
        CostPerMinuteReel:
          production?.nbEpisodes && production?.episodeLength
            ? carbonCostReel / 1000 / production.nbEpisodes / production.episodeLength
            : 0,
      }
    }
    return {
      name: production.name,
      Previz: 0,
      Reel: 0,
      CostPerMinutePreviz: 0,
      CostPerMinuteReel: 0,
    }
  })

  const customTick = (props: {
    width: number
    height: number
    x: number
    y: number
    payload: { value: string }
  }) => {
    const { width, height, x, y, payload } = props
    return (
      <foreignObject
        style={{
          fontSize: 14,
          width: width / chartDatas.length,
          height,
          marginLeft: 150,
        }}
        x={x}
        y={y}
        offset={15}
      >
        <div style={{ marginLeft: '50%', width: 'fit-content', transform: 'translateX(-50%)' }}>
          {payload.value}
        </div>
      </foreignObject>
    )
  }

  const customDot = (props: any) => {
    const { cx, cy, dataKey, key } = props
    if (dataKey === 'CostPerMinutePreviz') {
      return (
        <svg key={key} x={cx - 25} y={cy} fill="#F5B041" width="10" height="10" viewBox="0 0 24 24">
          <path d="M21.5,10.8L13.2,2.5C12.5,1.8 11.5,1.8 10.8,2.5L2.5,10.8C1.8,11.5 1.8,12.5 2.5,13.2L10.8,21.5C11.5,22.2 12.5,22.2 13.2,21.5L21.5,13.2C22.1,12.5 22.1,11.5 21.5,10.8Z" />
        </svg>
      )
    }
    return (
      <svg key={key} x={cx + 20} y={cy} fill="#CD6155" width="10" height="10" viewBox="0 0 24 24">
        <circle cx="10" cy="10" r="10" />
      </svg>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={width}
        height={height}
        data={chartDatas}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 40,
        }}
        className="composed-chart"
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" tick={customTick} />
        <YAxis
          yAxisId="left"
          orientation="left"
          label={{ value: 't CO2eq', angle: -90, position: 'insideLeft', offset: -35 }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{ value: 't CO2eq / min', angle: -90, position: 'right', offset: 35 }}
          stroke="#ff7300"
        />
        <ChartBar yAxisId="left" dataKey="Previz" barSize={40} fill="#3498ff" />
        <ChartBar yAxisId="left" dataKey="Reel" barSize={40} fill="#1675e0" />
        <Line
          yAxisId="right"
          dataKey="CostPerMinutePreviz"
          type="basisOpen"
          stroke="#F5B041"
          legendType="diamond"
          dot={customDot}
        />
        <Line
          yAxisId="right"
          dataKey="CostPerMinuteReel"
          type="basisOpen"
          stroke="#CD6155"
          legendType="circle"
          fill="#CD6155"
          dot={customDot}
        />
        <Tooltip
          formatter={(
            value,
            name: 'Previz' | 'Reel' | 'CostPerMinutePreviz' | 'CostPerMinuteReel',
          ) => [
            `${Math.round(Number(value) * 100) / 100} ${t(`studio.graph.unit${name}`)}`,
            `${t(`studio.graph.carbonCost${name}`)}`,
          ]}
          position={{ x: 10, y: 0 }}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <Legend
          formatter={(value: 'Previz' | 'Reel' | 'CostPerMinutePreviz' | 'CostPerMinuteReel') =>
            `${t(`studio.graph.carbonCost${value}`)}`
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
