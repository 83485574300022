import { useTranslation } from 'react-i18next'

export function PageNotFound() {
  const { t } = useTranslation()
  return (
    <div className="fullWidth fullHeight flex center alignCenter lightgrey fontSize24 bold">
      {t('pageNotFound.pageNotFound')}
    </div>
  )
}
