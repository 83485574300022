import { useRef, useState } from 'react'
import { Button, Form, FormInstance, Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { InputCustom } from 'components'
import api from 'services/api'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import classes from './Login.module.css'
import { loginValidationForm } from './loginValidationForm'

type LoginType = {
  username: string
  password: string
}

export function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [login, setLogin] = useState<Partial<LoginType>>({
    username: '',
    password: '',
  })
  const [errors, setErrors] = useState<string[] | void>()
  const formRef = useRef<FormInstance>()

  const [getToken, { isLoading }] = api.token.getToken()

  function handleLogin() {
    if (formRef.current?.check()) {
      getToken(login as LoginType)
        .unwrap()
        .then(() => navigate('/'))
        .catch((error) => {
          if (error.data) {
            setErrors(map(error.data, (value) => value))
          }
        })
    }
  }

  return (
    <div className="fullWidth fullHeight flex center alignCenter">
      <div className={classes.container}>
        <Panel header={t('login.title')} className="rs-panel-large-header" shaded>
          <Form
            fluid
            ref={(ref: FormInstance) => {
              formRef.current = ref
            }}
            onChange={setLogin}
            onSubmit={handleLogin}
            formValue={login}
            model={loginValidationForm}
            onCheck={() => setErrors()}
          >
            <Form.Group>
              <Form.ControlLabel>{t('login.username')}</Form.ControlLabel>
              <Form.Control name="username" type="string" accepter={InputCustom} />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>{t('login.password')}</Form.ControlLabel>
              <Form.Control name="password" type="password" accepter={InputCustom} />
            </Form.Group>

            {errors ? errors.map((error) => <div className={classes.error}>{error}</div>) : null}

            <div className="flex end marginTop20">
              <Button appearance="primary" type="submit" disabled={isLoading}>
                {t('login.loginButton')}
              </Button>
            </div>
          </Form>
          <div className="flex fullWidth end paddingTop20">
            <a href="/forgot">{t('login.forgotPassword')}</a>
          </div>
        </Panel>
      </div>
    </div>
  )
}
