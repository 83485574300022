import { InputCustom } from 'components'
import { countries } from 'constants/countries'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, SelectPicker } from 'rsuite'
import type { CountryProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type CountryProfileFormProps = {
  countryProfile: Partial<CountryProfile> | null
  setCountryProfile: (countryProfile: Partial<CountryProfile>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function CountryProfileForm(props: CountryProfileFormProps) {
  const { t } = useTranslation()
  const { countryProfile, setCountryProfile, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setCountryProfile}
      onCheck={setFormError}
      formValue={countryProfile || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('countryProfile.form.name')}
          <FormTooltip tooltip>{t('countryProfile.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={SelectPicker}
          tooltip-id="countryProfile.form.name_FieldInformation"
          data={countries}
          style={{ width: '100%' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('countryProfile.form.energyMix')}
          <FormTooltip tooltip>{t('countryProfile.form.energyMix_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="energyMix"
          accepter={InputCustom}
          tooltip-id="countryProfile.form.energyMix_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('countryProfile.form.greenEnergyMix')}
          <FormTooltip tooltip>{t('countryProfile.form.greenEnergyMix_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="greenEnergyMix"
          accepter={InputCustom}
          tooltip-id="countryProfile.form.greenEnergyMix_FieldInformation"
          type="float"
        />
      </Form.Group>
    </Form>
  )
}
