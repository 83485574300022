import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'rsuite'
import type { CloudSupplierProfile } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type CloudSupplierFormProps = {
  cloudSupplierProfile: Partial<CloudSupplierProfile> | null
  setCloudSupplierProfile: (cloudSupplierProfile: Partial<CloudSupplierProfile>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
}

export function CloudSupplierProfileForm(props: CloudSupplierFormProps) {
  const { t } = useTranslation()
  const { cloudSupplierProfile, setCloudSupplierProfile, getFormErrors } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      fluid
      plaintext={!authorized}
      onChange={setCloudSupplierProfile}
      onCheck={setFormError}
      formValue={cloudSupplierProfile || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('cloudSupplierProfile.form.name')}
          <FormTooltip tooltip>{t('cloudSupplierProfile.form.name_Tooltip')}</FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="name"
          accepter={InputCustom}
          tooltip-id="cloudSupplierProfile.form.name_FieldInformation"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('cloudSupplierProfile.form.depreciationTime')}
          <FormTooltip tooltip>
            {t('cloudSupplierProfile.form.depreciationTime_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="depreciationTime"
          accepter={InputCustom}
          tooltip-id="cloudSupplierProfile.form.depreciationTime_FieldInformation"
          type="number"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('cloudSupplierProfile.form.carbonEquivalent')}
          <FormTooltip tooltip>
            {t('cloudSupplierProfile.form.carbonEquivalent_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="carbonEquivalent"
          accepter={InputCustom}
          tooltip-id="cloudSupplierProfile.form.carbonEquivalent_FieldInformation"
          type="float"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          {t('cloudSupplierProfile.form.powerUsageEffectiveness')}
          <FormTooltip tooltip>
            {t('cloudSupplierProfile.form.powerUsageEffectiveness_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <Form.Control
          name="powerUsageEffectiveness"
          accepter={InputCustom}
          tooltip-id="cloudSupplierProfile.form.powerUsageEffectiveness_FieldInformation"
          type="float"
        />
      </Form.Group>
    </Form>
  )
}
