import { InputCustom } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputGroup } from 'rsuite'
import { FacilityData } from 'types'
import { useRole } from 'hooks/useRole'
import { FormTooltip } from 'components/FormTooltip/FormTooltip'

type FacilityFTEProps = {
  facilityData: Partial<FacilityData> | null
  setFacilityData: (faciltity: Partial<FacilityData>) => void
  getFormErrors?: (formErrors: Record<string, string>) => void
  setHasBlurred: (value: boolean) => void
}

export function FTEForm(props: FacilityFTEProps) {
  const { facilityData, setFacilityData, getFormErrors, setHasBlurred } = props
  const { authorized } = useRole({ requiredRole: 'editor' })
  const { t } = useTranslation()

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (getFormErrors) getFormErrors(formError)
  }, [formError])

  return (
    <Form
      plaintext={!authorized}
      fluid
      onChange={setFacilityData}
      onCheck={setFormError}
      onBlur={() => setHasBlurred(true)}
      formValue={facilityData || {}}
    >
      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.dataConsumption.annualOverallFteInFacilities')}
          <FormTooltip tooltip>
            {t('facilityDatas.dataConsumption.annualOverallFteInFacilities_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="overallFte"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.dataConsumption.annualOverallFteInFacilities_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.fte')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>
          {t('facilityDatas.dataConsumption.annualNumberOfTransverseFte')}
          <FormTooltip tooltip>
            {t('facilityDatas.dataConsumption.annualNumberOfTransverseFte_Tooltip')}
          </FormTooltip>
        </Form.ControlLabel>
        <InputGroup style={{ width: '100%' }}>
          <Form.Control
            name="numberOfTransverseFte"
            type="number"
            accepter={InputCustom}
            tooltip-id="facilityDatas.dataConsumption.annualNumberOfTransverseFte_FieldInformation"
          />
          <InputGroup.Addon>{t('misc.units.fte')}</InputGroup.Addon>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}
